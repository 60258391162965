<template>
    <md-card class="md-Bsk-stats-card">

        <md-card-header :data-background-color="dataBackgroundColor">
            <b>{{ namesMobsComputed }}</b>
        </md-card-header>
        <md-card-content v-for="(value, index) in dropsComputed" :key="index">

            <div class="listDropsContainer flex ">
                <div class="item">{{ value.name }}</div>
                <div class="item">{{ value.id_itens }}</div>
                <div class="item">{{ value.date_hour }} - {{ value.DATE }}</div>
            </div>
        </md-card-content>

        <!-- <md-card-content>
            <slot name="content"></slot>
        </md-card-content>

        <md-card-actions md-alignment="left">
            <slot name="footer"></slot>
        </md-card-actions> -->



    </md-card>
</template>
<script>
export default {
    name: 'bsk-stats-card-logs',
    props: {
        dataBackgroundColor: {
            type: String,
            default: '',
        },
        drops: {
            type: [Array, Object],
            required: true,
        },
        namesMobs: {
            type: [Number, String],
            required: true,
        }
    },
    computed: {
        dropsComputed() {
            return this.drops
        },
        namesMobsComputed() {
            return parseInt(this.namesMobs)
        }
    }
}
</script>

<style lang="scss" scoped>
.item {
    margin: 5px;
    color: #ad8437;
    // background: antiquewhite;
    text-align: center;
    font-size: 1.5em;
}

.flex {
    display: flex;
}

.flex-wrap {
    flex-wrap: wrap;
}

.listDropsContainer {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
}

.card-center {
    text-align: center
}

.card-left {
    text-align: left;
}
</style>

