<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>

    <side-bar :sidebar-item-color="sidebarBackground" :sidebar-background-image="sidebarBackgroundImage">
      <sidebar-link to="/painel/user">
        <div class="ornament-bskNav"></div>
        <i class="fas fa-home-lg-alt"></i>
        <p>{{ $t('message.SideBar.txtDashboard') }}</p>
      </sidebar-link>
      <sidebar-link to="/painel/account">
        <div class="ornament-bskNav"></div>
        <i class="fas fa-user-cog"></i>
        <p>{{ $t('message.SideBar.txtAccount') }}</p>
      </sidebar-link>
      <sidebar-link to="/painel/transfer">
        <div class="ornament-bskNav"></div>
        <i class="fas fa-exchange-alt"></i>
        <p>{{ $t('message.SideBar.txtTransfer') }}</p>
      </sidebar-link>
      <sidebar-link to="/painel/shop">
        <div class="ornament-bskNav"></div>
        <i class="fas fa-bags-shopping"></i>
        <p>{{ $t('message.SideBar.txtShop') }}</p>
      </sidebar-link>
      <sidebar-link to="/painel/donate">
        <div class="ornament-bskNav"></div>
        <i class="fas fa-donate"></i>
        <p>{{ $t('message.SideBar.txtDonate') }}</p>
      </sidebar-link>
      <sidebar-link to="/painel/rank">
        <div class="ornament-bskNav"></div>
        <i class="fas fa-crown"></i>
        <p>{{ $t('message.SideBar.txtRanking') }}</p>
      </sidebar-link>
      <sidebar-link to="/painel/news">
        <div class="ornament-bskNav"></div>
        <i class="fas fa-scroll-old"></i>
        <p>{{ $t('message.SideBar.txtNotes') }}</p>
      </sidebar-link>
      <!-- <sidebar-link to="/painel/systemLogs">
        <div class="ornament-bskNav"></div>
        <i class="fas fa-scroll-old"></i>
        <p>Sistema Log</p>
      </sidebar-link> -->

      <!-- <sidebar-link to="/painel/cassino">
        <div class="ornament-bskNav"></div>
        <i class="fas fa-crown"></i>
        <p>Cassino Cabal</p>
      </sidebar-link> -->

    </side-bar>

    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content> </dashboard-content>

      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>

<script>
import TopNavbar from './TopNavbar.vue'
import ContentFooter from './ContentFooter.vue'
import DashboardContent from './Content.vue'
//import FixedPlugin from "./Extra/FixedPlugin.vue";

export default {
  components: {
    TopNavbar,
    DashboardContent,
    ContentFooter,
    //    FixedPlugin
  },
  data() {
    return {
      sidebarBackground: 'bsk',
      sidebarBackgroundImage: require('@/assets/img/sidebar/bg_padrao.png'),
    }
  },
}
</script>

<style scoped>
.ornament-bskNav {
  display: none;
}
</style>
