<template>
  <div class="content">
    <div class="md-layout">
      <div class="
          md-layout-item md-medium-size-100 md-xsmall-size-100
          sm-size-100
          md-size-30
        ">
        <bsk-stats-card data-background-color="green">
          <template slot="header">
            <i class="fas fa-street-view"></i>
          </template>

          <template slot="content-title">
            <p class="category">
              {{ $t('message.Account.managementAccount') }}
            </p>
            <h3 class="title">{{ this.userAccount.ID }}</h3>
          </template>

          <template slot="content">
            <div class="divTable">
              <div class="divTableCell-Left">
                {{ $t('message.Account.lastLogin[0]') }}
              </div>
              <div class="divTableCell-Right">
                {{ this.calcDate }} {{ $t('message.Account.lastLogin[1]') }}
              </div>

              <div class="divTableCell-Left">
                {{ $t('message.Account.lastIpLogin') }}
              </div>
              <div class="divTableCell-Right">
                {{ this.lasIp }}
              </div>

              <div class="divTableCell-Left">Cabal Private</div>
              <div class="divTableCell-Right">
                {{ $t('message.Account.dados') }}
                {{ this.convertData }}
              </div>

              <div class="divTableCell-Left">
                {{ $t('message.Account.AccountStatus[0]') }}
              </div>
              <div class="divTableCell-Right" v-if="this.userAccount.actived === 1">
                {{ $t('message.Account.AccountStatus[1]') }}
              </div>
              <div class="divTableCell-Right" v-else>
                {{ $t('message.Account.AccountStatus[2]') }}
              </div>

              <div class="divTableCell-Left">
                {{ $t('message.Account.timePlaying[0]') }}
              </div>
              <div class="divTableCell-Right">
                {{ this.hoursGame.hours }}
                {{ $t('message.Account.timePlaying[1]') }} -
                {{ this.hoursGame.minutes }}
                {{ $t('message.Account.timePlaying[2]') }} -
                {{ this.hoursGame.seconds }}
                {{ $t('message.Account.timePlaying[3]') }}
              </div>
            </div>
          </template>

          <template slot="footer">
            <footers classCss="stats" mdIcon="sync" :textProp="$t('message.Account.txtProps2')" :check="false">
            </footers>
          </template>
        </bsk-stats-card>
        <!-- - -->
        <bsk-stats-card data-background-color="gray">
          <template slot="header">
            <i class="fas fa-toolbox"></i>
          </template>

          <template slot="content-title">
            <p class="category">{{ $t('message.Account.toolsBox[0]') }}</p>
            <h3 class="title">{{ $t('message.Account.toolsBox[1]') }}</h3>
          </template>

          <template slot="content">
            <div class="divTable">
              <div class="divToolboxCell-Left">
                <router-link :to="{ path: '/painel/account/accinfo' }" :key="$router.path">
                  {{ $router.path }}<i class="fas fa-edit"></i>
                  {{ $t('message.Account.dataAccount') }}
                </router-link>
              </div>
              <div class="divToolboxCell-Left">
                <router-link :to="{ path: '/painel/account/characters' }" :key="$router.path">
                  {{ $router.path }}<i class="fas fa-user-friends"></i>
                  {{ $t('message.Account.mycharacters') }}
                </router-link>
              </div>
              <div class="divToolboxCell-Left">
                <router-link :to="{ path: '/painel/account/distpts' }" :key="$router.path">
                  {{ $router.path }}<i class="fas fa-calculator"></i>
                  {{ $t('message.Account.points') }}
                </router-link>
              </div>
              <!-- <div class="divToolboxCell-Left">
                <router-link
                  :to="{ path: '/painel/account/accsec' }"
                  :key="$router.path"
                >
                  {{ $router.path }}<i class="fas fa-shield-alt"></i>
                  {{ $t('message.Account.mySecurity') }}
                </router-link>
              </div> -->
              <div class="divToolboxCell-Left">
                <router-link :to="{ path: '/painel/account/pk' }" :key="$router.path">
                  {{ $router.path }}<i class="fas fa-fire"></i>
                  {{ $t('message.Account.clearPk') }}
                </router-link>
              </div>
            </div>
          </template>

          <template slot="footer">
            <footers classCss="stats" mdIcon="info" :textProp="$t('message.Account.chooseTools')" :check="false">
            </footers>
          </template>
        </bsk-stats-card>
        <!-- - -->
        <stats-card data-background-color="orange">
          <template slot="header">
            <i class="fal fa-badge-dollar"></i>
          </template>

          <template slot="content">
            <p class="category">{{ $t('message.Dashboard.txtAlzs') }}</p>
            <h3 class="title"
              :class="colorHousesAlzs({ warehouse: this.alzsLocalWarehouse, alzsAccount: this.alzsTotal }).color">
              {{ colorHousesAlzs({ warehouse: this.alzsLocalWarehouse, alzsAccount: this.alzsTotal }).alzs }}
            </h3>
          </template>

          <template slot="footer">
            <footers classCss="stats" mdIcon="update" :textProp="$t('message.Dashboard.penultimateAlzs')" :check="false">
            </footers>
          </template>
        </stats-card>
      </div>
      <!-- -->
      <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-70">
        <bsk-stats-card data-background-color="gray">
          <template slot="header">
            <i class="fas fa-tools"></i>
          </template>

          <template slot="content-title">
            <p class="category">{{ $t('message.Account.toolsPlan') }}</p>
            <h3 class="title">{{ $route.name }}</h3>
          </template>

          <template slot="content">
            <router-view />
          </template>

          <template slot="footer">
            <footers classCss="stats" mdIcon="copyright" :textProp="$t('message.Account.txtProp')" v-bind:check="true">
            </footers>
          </template>
        </bsk-stats-card>
      </div>
    </div>
  </div>
</template>

<script>
import { StatsCard, BskStatsCard } from '@/components'
import { mapGetters } from 'vuex'
import { format } from 'date-fns-tz'
import { parseISO, addHours } from 'date-fns'
import footers from '../components/Footers/Footer.vue'
import { colorHousesAlzs } from "../util/ColorHousesAlzs";

export default {
  components: {
    StatsCard,
    BskStatsCard,
    footers,
  },

  data() {
    return {
      alzsTotal: 0,
      convertData: '',
      lasIp: '',
      alzsLocalWarehouse: 0,
    }
  },
  computed: {
    ...mapGetters([
      'userAccount',
      'hoursGame',
      'AlzsTotalChar',
      'AlzsWarehouse',
    ]),
    calcDate() {
      let msg = ''

      if (this.userAccount.timeLogged === null || this.userAccount.timeLogged == undefined) {
        msg += 'Conta ainda não logada'
        return msg
      }
      let message1 = {
        years: this.userAccount.timeLogged.years,
        day: this.userAccount.timeLogged.days,
        months: this.userAccount.timeLogged.months,
        hours: this.userAccount.timeLogged.hours,
        minutes: this.userAccount.timeLogged.minutes,
        // seconds: Seconds,
        // Mili: Milliseconds,
      }
      msg += ` ${this.$t('message.Account.dadosHours[0]')} `
      if (message1.years > 0)
        msg += message1.years + ` ${this.$t('message.Account.dadosHours[1]')} `
      if (message1.day > 0)
        msg += message1.day + ` ${this.$t('message.Account.dadosHours[2]')}, `
      if (message1.months > 0)
        msg += message1.months + ` ${this.$t('message.Account.dadosHours[3]')} `
      if (message1.hours > 0) {
        msg += message1.hours + ` ${this.$t('message.Account.dadosHours[4]')} `
      } else {
        msg += 'visto online recentimente'
      }

      // if (message1.minutes > 0 && message1.minutes > 60){
      //   msg += 'visto online recentimente'
      // }
      //   msg +=
      //     message1.minutes + ` ${this.$t('message.Account.dadosHours[5]')} `

      return msg
    },
  },
  beforeUpdate() {
    document
      .querySelector('.router-link-active')
      .classList.add('nav-item', 'active')
    document.body.scrollTop = document.documentElement.scrollTop = 0
  },
  methods: {
    colorHousesAlzs() {
      return colorHousesAlzs({ warehouse: this.alzsLocalWarehouse, alzsAccount: this.alzsTotal })
    }
  },
  async mounted() {
    for (let i = 0; i < this.AlzsTotalChar.length; i++) {
      let alz = parseInt(this.AlzsTotalChar[i].Alz)
      this.alzsTotal = parseInt(this.alzsTotal) + parseInt(alz)
    }
    //Set Valor Null or Undefined Variable;
    this.AlzsWarehouse === null || this.AlzsWarehouse === undefined
      ? (this.alzsLocalWarehouse = 0)
      : (this.alzsLocalWarehouse = this.AlzsWarehouse)
    //
    const parseIso = parseISO(this.userAccount.CreateDate)
    const addHoursUTC = addHours(parseIso, 3)
    this.convertData = format(addHoursUTC, 'dd/MM/yyyy', {
      timeZone: 'America/Sao_Paulo',
    })
    this.userAccount.LastIp === null ||
      this.userAccount.LastIp === 0 ||
      this.userAccount.LastIp === undefined
      ? (this.lasIp = this.$t('message.Account.ip'))
      : (this.lasIp = this.userAccount.LastIp)
  },
}
</script>
<style scoped lang="scss">
@import '@/assets/scss/color.scss';

.divTable {
  display: table;
  word-wrap: break-word;
  width: 100%;

  .divTableCell-Left {
    width: 40%;
    max-width: 40%;
    height: 40px;
    padding: 5px;
    line-height: 30px;
    color: rgb(117, 117, 117);
    border-top: 1px solid #ededed;
    text-align: left;
    float: left;
    box-sizing: border-box;
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .divTableCell-Right {
    width: 60%;
    max-width: 60%;
    height: 40px;
    padding: 5px;
    line-height: 30px;
    color: #1976d2;
    border-top: 1px solid #f6f6f6;
    text-align: right;
    float: right;
    box-sizing: border-box;
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .divToolboxCell-Left {
    width: 100%;
    max-width: 100%;
    height: 40px;
    padding: 5px !important;
    line-height: 30px;
    font-size: 14px;
    color: #ad8437;
    border-top: 1px solid #f6f6f6;
    text-align: left;
    float: left;
    box-sizing: border-box;
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    :hover {
      background-color: #f9f9f9;
      color: purple;
      cursor: pointer;
    }
  }
}

.fas {
  width: 20px;
  text-align: center;
}
</style>
