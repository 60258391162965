<template>
  <md-card class="md-shop-bsk">
    <md-card-content>
      <slot name="content-Shop"></slot>
    </md-card-content>

    <md-card-content>
      <slot name="thumb"></slot>
    </md-card-content>

    <md-card-actions md-alignment="center">
      <slot name="footer"></slot>
    </md-card-actions>
  </md-card>
</template>
<script>
export default {
  name: 'bsk-shop-card',
  props: {
    dataBackgroundColor: {
      type: String,
      default: '',
    },
  },
}
</script>
