import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'
//import router from "../routes/routes";
import axios from '../services/servicesAxios'
import initialPage from './InitialPage'
import StoreShop from './Store'
import { swal } from 'vue-swal'
import i18n from '../locale/'
import VueRouter from 'vue-router'
let router = new VueRouter()
const ls = new SecureLS({
  isCompression: false,
})

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    donation: {},
    userAccount: {},
    actived: '',
    hoursGamming: {},
    alzsAcc: {},
    emailState: '',
    cashInAccountPers: {},
    cashInAccountCassino: { cash: null, typeName: '' },
    history: {},
  },
  modules: {
    initialPage,
    StoreShop,
  },
  actions: {
    logout() {
      localStorage.removeItem('user-data')
      localStorage.removeItem('vuex')
      localStorage.removeItem('_secure__ls__metadata')
      localStorage.removeItem('rank')
      localStorage.removeItem('Guild')
      router.go(0)
    },
    login({ commit }, userDados) {
      return axios
        .authLogin({
          id: userDados.user,
          pass: userDados.pass,
        })
        .then((response) => {
          if (response.status === 200) {
            const actived = response.data.ID[0].AuthType
            actived !== 2
              ? commit('activationAccount', true)
              : commit('activationAccount', false)
            //Commita os dados para a state dos dados do Usuario, para que possa ser usado em outros locais
            commit('userAccountDados', { account: response.data.ID })

            return 1
          }
        })
        .catch((err) => {
          return "Can't connect to API: " + err, 0
          //throw new Error("Can't connect to API: " + err);
        })
    },
    cashInAccountAction({ commit }, accountIdx) {
      return axios
        .cashInAccount(accountIdx)
        .then((response) => {
          if (response.status === 200) {
            commit('cashInAccountMut', response.data.cashAccount[0])
            commit('cashCassionInAccountMut', {
              cash: response.data.cashAccount[0]?.CashCassino,
            })
            return 1
          } else {
            commit('cashInAccountMut', { Cash: 0 })
            return 0
          }
        })
        .catch((err) => {
          console.log(err)
          if (err.response !== undefined) {
            if (err.response.status === 403 || err.response.status === 401)
              swal(
                i18n.t('swal.typeMessage[0]'),
                err.response.data.Mensagem,
                'warning'
              ).then(() => {
                setTimeout(async () => await this.dispatch(`logout`), 1000)
              })
            else
              swal(
                i18n.t('swal.typeMessage[1]'),
                err.response.data.msg,
                'error'
              ).then(() => {
                setTimeout(async () => await this.dispatch(`logout`), 1000)
              })
          } else
            swal(`Error`, i18n.t('swal.error'), 'error').then(() => {
              setTimeout(async () => await this.dispatch(`logout`), 1000)
            })
        })
    },
    historyDonateAction({ commit }, history) {
      return axios
        .historyDonate(history)
        .then((response) => {
          if (response.status === 200) {
            commit('historyDonateLog', response.data.history)
            return 1
          } else {
            commit('historyDonateLog', { history: 0 })
            return 0
          }
        })
        .catch((err) => {
          throw new Error('Error in Connect Api', err)
        })
    },
  },
  mutations: {
    historyDonateLog(state, history) {
      state.history = history
    },
    cashInAccountMut(state, cash) {
      state.cashInAccountPers = { cash: cash.Cash, typeName: 'cash' }
    },

    cashCassionInAccountMut(state, { cash }) {
      state.cashInAccountCassino = {
        cash: cash,
        typeName: 'cassino',
      }

      // state.cashInAccountCassino = {
      //   cash: cash.CashCassino,
      //   typeName: 'cassino',
      // }
    },
    userAccountDados(state, { account }) {
      state.userAccount = account
      state.actived = account[0].AuthType
    },
    activationAccount(state, { active }) {
      state.actived = active
    },
    hoursGamming(state, payload) {
      state.hoursGamming = payload
    },
    alzsAccount(state, payload) {
      state.alzsAcc = payload
    },
    userAccountChangeEmail(state, mail) {
      state.userAccount[0].Email = mail
    },
  },
  getters: {
    userAccount: (state) => state.userAccount[0],
    searchEmailPlayer: (state) => state.userAccount[0],
    accountActived: (state) => state.actived,
    hoursGame: (state) => state.hoursGamming,
    cashInAccountGetter: (state) => state.cashInAccountPers,
    historyDonateGetter: (state) => state.history,
    gettersCashCassino: (state) => state.cashInAccountCassino,
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
})

export default store
