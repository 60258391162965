import { render, staticRenderFns } from "./Developers.vue?vue&type=template&id=7215a68e&scoped=true&"
import script from "./Developers.vue?vue&type=script&lang=js&"
export * from "./Developers.vue?vue&type=script&lang=js&"
import style0 from "./Developers.vue?vue&type=style&index=0&id=7215a68e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7215a68e",
  null
  
)

export default component.exports