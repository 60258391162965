<template>
  <div v-bind:class="classCss">
    <md-icon>{{ mdIcon }}</md-icon>
    {{ textProp }}
    <font v-if="check == true" color="green">{{
      $t('message.Footer.footer')
    }}</font
    >.
  </div>
</template>

<script>
export default {
  name: 'footerPainel',
  props: {
    classCss: {
      type: String,
      required: true,
    },
    mdIcon: {
      type: String,
      required: true,
    },
    textProp: {
      type: String,
      required: true,
    },
    check: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {}
  },
}
</script>
<style scoped></style>
