import axios from 'axios'
/* eslint-disable */
//Endereço da API
const requestIP = axios.create({
  baseURL: process.env.VUE_APP_ADDRESS,
  headers: { 'Accept-Language': localStorage.getItem('i18n') ? localStorage.getItem('i18n') : 'en' }
});
const createPayment = async creds => {
  let pay = await requestIP.post("/paypalPayment2", creds);
  if (pay.httpStatusCode == 200) return pay;
  return pay;
};

const executePayment = async dados => {
  let pay = await requestIP.post("/paypalPaymentFinal", dados);
  if (pay.status == 200) return pay;
  return pay;
};
export default {
  createPayment,
  executePayment
};
