<template>
  <div class="i18n">
    <label>Select Language</label>
    <select name="language" v-model="language" @change.prevent="selectLanguage">
      <option selected value="pt">PT-BR</option>
      <option value="eng">EN</option>

    </select>
  </div>
</template>

<script>
export default {
  name: 'i18n',
  data() {
    return {
      language: null,
    }
  },
  methods: {
    selectLanguage() {
      if (this.language !== null) localStorage.setItem('i18n', this.language)
      window.location.reload()
    },
  },
  created() {
    if (localStorage.getItem('i18n') === null) {
      localStorage.setItem('i18n', 'pt')
    }
  },
}
</script>

<style scoped lang="scss">
.i18n {
  position: relative;
  margin: 0 auto;
  float: right;

  label {
    padding: 5px;
    width: 100%;
    text-align: center;
    font-weight: bold;
    color: #7e633b;
  }
}
</style>
