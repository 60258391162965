/* eslint-disable */
import webShop from './servicesAxios.js'
import { swal } from 'vue-swal'

let message = null
var num = null
let buyItem = (
  itemBuy,
  cashInAccount,
  totalValueItems,
  userNum,
  loading,
  $t,
  totalStock
) => {
  itemBuy.cashInAccount = cashInAccount
  itemBuy.totalPriceItem = totalValueItems
  itemBuy.userNum = userNum
  itemBuy.CatName = itemBuy.CatName.toLowerCase()
  return webShop
    .buyItem({ item: itemBuy, totalStock }, null)
    .then((value) => {
      if (value.status === 200) {
        setTimeout(() => {
          swal(value.data.mensagem, {
            icon: 'success',
          })
          loading.hide()
        }, 800)
        return 1
      } else {
        setTimeout(() => {
          swal(value.data.mensagem, {
            icon: 'error',
          })
          loading.hide()
        }, 800)
        return 0
      }
    })
    .catch((err) => {
      message = $t
      swal(message, {
        icon: 'error',
      })
      setTimeout(() => {
        loading.hide()
        //router.go(0)
      }, 800)
      return -1
    })
}

function typeBuy(
  isBuy,
  totalPriceItem,
  reqMsg,
  buyItem,
  listPremium,
  typeCash,
  loading
) {
  let check = ''
  if (isBuy) {
    typeCash = typeCash - totalPriceItem
    //aparece a Barra de Loading
    let loader = loading
    buyItem = true

    setTimeout(() => {
      loader.hide()
      $('#modal-default').modal('show') // initializes and invokes show immediately
    }, 800)

    message = reqMsg
    listPremium.map(function (infoArray) {
      if (infoArray.ListID == list) return (infoArray.TotalStock = 1)
    })

    return {
      buy: buyItem,
      num: 1 /* quando o valor do NPOINTS ou cash é menor que o valor do Item*/,
      message: message,
      typeCash: typeCash,
    }
  } else {
    //Verifica o erro e retorna a Modal Vermelha

    message = reqMsg

    let loader = loading

    buyItem = false
    //Zera os dados de compra
    listPremium.map(function (infoArray) {
      if (infoArray.ListID == list) return (infoArray.TotalStock = 1)
    })
    setTimeout(() => {
      loader.hide()
      $('#modal-default').modal('show') // initializes and invokes show immediately
      //router.go(0)
    }, 800)
    return {
      buy: buyItem,
      num: 2 /* quando o valor do NPOINTS ou cash é menor que o valor do Item*/,
      message: message,
      typeCash: typeCash,
    }
  }
  //essa parte faz o Loading quando compra ou carrega alguma coisa
}

export default {
  buyItem,
}
