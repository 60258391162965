export default {
  donate: {
    'Mercado Pago': { pay: 'bMpago', button: 'gMpago', name: 'Mercado Pago' },
    'NuBank': { pay: 'bNuB', button: 'gNuB', name: 'NuBank' },
    'PicPay': { pay: 'bPicPay', button: 'gPicPay', name: 'PicPay' },
    'PayPal': { pay: 'bPayPal', button: 'gPayPal', name: 'PayPal' },
    'Paypal': { pay: 'bPayPal', button: 'gPayPal', name: 'PayPal' },
    'Itau': { pay: 'bItau', button: 'gItau', name: 'Itau' },
    'Bradesco': { pay: 'bBradesco', button: 'gBradesco', name: 'Bradesco' },
    'Caixa': { pay: 'bCaixa', button: 'gCaixa', name: 'Caixa' },
    'Banco do Brasil': { pay: 'bBB', button: 'gBB', name: 'Banco do Brasil' },
  },
}
