<template>
  <div>
    <loading :active.sync="visible" :can-cancel="false"></loading>
    <div class="group-BskInput">
      <input
        type="text"
        id="NomeComp"
        class="BskInput"
        :placeholder="$t('message.DataPeople.name')"
        disabled
        v-model.lazy="nome"
      />
      <div class="iconBsk"><i class="fas fa-user-tag"></i></div>
      <input
        type="text"
        id="novoNomeComp"
        class="BskInput"
        :placeholder="$t('message.DataPeople.changeName')"
        v-model.lazy="nome"
      />
      <div class="iconBsk"><i class="far fa-edit"></i></div>
    </div>
    <!-- /Nome --><br />
    <div class="group-BskInput">
      <input
        type="password"
        id="Senha"
        class="BskInput"
        :placeholder="$t('message.DataPeople.password')"
        v-model.lazy="senha"
      />
      <div class="iconBsk"><i class="far fa-key"></i></div>
      <input
        type="password"
        id="novaSenha"
        class="BskInput"
        :placeholder="$t('message.DataPeople.changedPassword')"
        v-model.lazy="newpass"
      />
      <div class="iconBsk"><i class="far fa-edit"></i></div>
      <br />
      <p>{{ $t('message.DataPeople.reworkPassword') }}</p>
    </div>
    <!-- /Senha --><br />
    <div class="group-BskInput">
      <input
        type="password"
        id="Senha"
        class="BskInput"
        :placeholder="$t('message.DataPeople.subpass')"
        v-model.lazy="subpass"
      />
      <div class="iconBsk"><i class="far fa-lock"></i></div>
      <input
        type="password"
        id="novaSenha"
        class="BskInput"
        :placeholder="$t('message.DataPeople.changeSubPass')"
        v-model.lazy="newSubPass"
      />
      <div class="iconBsk"><i class="far fa-edit"></i></div>
    </div>
    <!-- /Senha --><br />
    <div class="group-BskBestAction">
      <button type="submit" @click.prevent="saveTheChange">
        {{ $t('message.DataPeople.btn') }}
      </button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import 'vue-loading-overlay/dist/vue-loading.css'
import Loading from 'vue-loading-overlay'
export default {
  name: 'DadosPessoas',
  components: { Loading },
  data() {
    return {
      nome: '',
      senha: '',
      newpass: '',
      subpass: '',
      newSubPass: '',
      visible: false,
    }
  },
  computed: {
    ...mapGetters(['userAccount']),
  },
  methods: {
    ...mapActions(['DataPeople']),
    async saveTheChange() {
      this.visible = true
      try {
        if (
          this.nome != '' &&
          this.senha != '' &&
          this.newpass != '' &&
          this.subpass != '' &&
          this.newSubPass != ''
        ) {
          const dataPeople = await this.DataPeople({
            nome: this.nome,
            oldPass: this.senha,
            newpass: this.newpass,
            oldSubPass: this.subpass,
            newSubPass: this.newSubPass,
            ID: this.userAccount.UserNum,
          })
          if (dataPeople === 1) {
            this.$swal(
              this.$t('swal.typeMessage[2]'),
              this.$t('swal.dadosSave'),
              'success'
            ).then(() => {
              this.$store.dispatch('logout')
            })
            setTimeout(() => {
              this.visible = false
              this.nome = ''
              this.senha = ''
              this.newpass = ''
              this.subpass = ''
              this.newSubPass = ''
            }, 200)
          } else {
            this.visible = false
            this.$swal(
              this.$t('swal.typeMessage[1]'),
              this.$swal('swal.errorEmail[0]'),
              'error'
            )
          }
        } else {
          this.visible = false
          this.$swal(
            this.$t('swal.typeMessage[0]'),
            this.$t('swal.noEmpty'),
            'warning'
          )
        }
      } catch (e) {
        console.log(e)
        this.$swal(
          'Error',
          e.response.data.msg || e.response.data.mensagem,
          'error'
        )
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import '../assets/scss/md/_AccInfo.scss';
</style>
