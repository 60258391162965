import { render, staticRenderFns } from "./Transfer.vue?vue&type=template&id=95a54c2e&scoped=true&"
import script from "./Transfer.vue?vue&type=script&lang=js&"
export * from "./Transfer.vue?vue&type=script&lang=js&"
import style0 from "./Transfer.vue?vue&type=style&index=0&id=95a54c2e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95a54c2e",
  null
  
)

export default component.exports