import DashboardLayout from '@/pages/Layout/DashboardLayout.vue'
import Dashboard from '@/pages/Dashboard.vue'
import Account from '@/pages/Account.vue'
import Shop from '@/pages/Shop.vue'
import Donate from '@/pages/Donate.vue'
import Transfer from '@/pages/Transfer.vue'
import News from '@/pages/News.vue'
// import Notifications from '@/pages/Notifications.vue'
import Login from '@/pages/Login.vue'
import Register from '@/pages/Register'
import RecID from '@/pages/RecoveryID.vue'
import RequestPass from '@/pages/RequestPasswordChange.vue'
import Faqs from '@/pages/Faqs.vue'
import AccInfo from '@/components/AccInfo.vue'
// import AccSec from '@/components/AccSec.vue'
import Community from '@/components/Community.vue'
import Characters from '@/components/Characters.vue'
import DistPts from '@/components/DistPts.vue'
import NovoDonate from '@/components/NovoDonate.vue'
import HistDonate from '@/components/HistDonate.vue'
import ItemShop from '@/components/Shop/ItemShop.vue'
import ItemShopPremium from '@/components/Shop/ItemShopPremium.vue'
//import Team from "@/pages/Warzone/Team.vue";
import MetaDonate from '@/components/MetaDonate.vue'
import EnvChar from '@/components/EnvChar.vue'
import RecChar from '@/components/RecChar.vue'
import FaqsCat1 from '@/components/Faqs/Faqs.vue'
import FaqsCatSelect from '@/components/Faqs/cpCategoria.vue'
import paypal from '@/components/payment/payment.vue'
import Politics from '@/pages/Politics.vue'
import Rules from '@/pages/Rules.vue'
import Developers from '@/pages/Developers.vue'
//Others Imports
const ActiveAccount = () => import('@/pages/Activation.vue')
const PkReset = () => import('@/components/Reset/Pk.vue')
const Rank = () => import('@/pages/Rank.vue')
const resend = () => import('@/pages/Resend/ResendCode.vue')
import i18n from '../locale/'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
  },
  {
    path: '/activation',
    name: 'Activation',
    component: ActiveAccount,
  },
  {
    path: '/resend',
    name: 'Resend',
    component: resend,
  },
  {
    path: '/recid',
    name: 'Recovery ID',
    component: RecID,
  },
  {
    //path: '/recpass',
    path: '/reqpass',
    name: 'Recovery Password',
    component: RequestPass,
  },
  {
    path: '/recpass',
    name: 'Recovery Password',
    component: () =>
      import(
        /* webpackChunkName: "recoveryPassword" */ '@/pages/Recovery/RecoveryPassword.vue'
      ),
  },
  {
    path: '/painel',
    component: DashboardLayout,
    name: 'painel',
    redirect: '/painel/user',
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'systemLogs',
        name: 'Sistema Logs',
        component: () =>
          import(
            /* webpackChunkName: "SystemLogs" */ '@/pages/SystemLogs/System_Log.vue'
          ),
        children: [
          {
            path: '/painel/systemLogs/WarLogs',
            component: () =>
              import(
                /* webpackChunkName: "WarLogs" */ '@/components/SystemLogs/WarLogs.vue'
              ),
            name: 'Missão de Guerra',
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: '/painel/systemLogs/PVP',
            component: () =>
              import(
                /* webpackChunkName: "PVP" */ '@/components/SystemLogs/PVP.vue'
              ),
            name: 'Batalha entre Personagems',
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: '/painel/systemLogs/PK',
            component: () =>
              import(
                /* webpackChunkName: "PK" */ '@/components/SystemLogs/PK.vue'
              ),
            name: 'Pvp Irrestrito',
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: '/painel/systemLogs/MobsDrops',
            component: () =>
              import(
                /* webpackChunkName: "mobsDrops" */ '@/components/SystemLogs/MobsDrops.vue'
              ),
            name: `Lista de Drop's Mobs`,
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: 'cassino',
        name: 'Cassino Cabal',
        component: () =>
          import(
            /* webpackChunkName: "SystemCassinoCabal" */ '@/pages/Cassino/CassinoPrivate.vue'
          ),
      },
      {
        path: 'user',
        name: 'Dashboard',
        component: Dashboard,
      },
      {
        path: 'account',
        name: i18n.t('message.SideBar.txtAccount'),
        component: Account,
        children: [
          {
            path: '/painel/account/accinfo',
            component: AccInfo,
            name: i18n.t('router.routerName'),
            meta: {
              requiresAuth: true,
            },
          },
          // {
          //   path: '/painel/account/accsec',
          //   component: AccSec,
          //   name: i18n.t("message.AccInfo.routerSecurity"),
          //   meta: {
          //     requiresAuth: true,
          //   },
          // },
          {
            path: '/painel/account/characters',
            component: Characters,
            name: i18n.t('router.routerChar'),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: '/painel/account/distpts',
            component: DistPts,
            name: i18n.t('router.routerDist'),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: '/painel/account/accinfo',
            component: AccInfo,
            name: i18n.t('router.routerData'),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: '/painel/account/pk',
            component: PkReset,
            name: i18n.t('router.routerPk'),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'payment/:pay/:paymentId/:token/:PayerID',
            name: 'paypal',
            component: paypal,
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: 'shop',
        name: 'Loja Cabal',
        component: Shop,
        children: [
          {
            path: ':id',
            component: ItemShop,
            name: 'shop',
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'premium',
            component: ItemShopPremium,
            name: 'Premium',
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: 'donate',
        name: i18n.t('router.routerDonate'),
        component: Donate,
        children: [
          {
            path: '/painel/donate/novo',
            component: NovoDonate,
            name: i18n.t('router.routerNewDonate'),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: '/painel/donate/historico',
            component: HistDonate,
            name: i18n.t('router.routerHistDonate'),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: '/painel/donate/meta',
            component: MetaDonate,
            name: i18n.t('router.routerMetas'),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: 'transfer',
        name: `${i18n.t('router.routerTransfer')} P2P (Player to Player)`,
        component: Transfer,
        children: [
          {
            path: '/painel/transfer/envperso',
            component: EnvChar,
            name: i18n.t('router.routerSend'),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: '/painel/transfer/recperso',
            component: RecChar,
            name: i18n.t('router.routerReceive'),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: 'community',
        name: i18n.t('router.routerCommunity'),
        component: Community,
      },
      {
        path: 'news',
        name: i18n.t('router.routerNotas'),
        component: News,
      },
      // {
      //   path: 'notifications',
      //   name: 'Notifications',
      //   component: Notifications,
      // },
      {
        path: 'Faqs',
        name: i18n.t('router.routerSupport'),
        component: Faqs,
        children: [
          {
            path: '/painel/faqs/cat/1',
            component: FaqsCat1,
            name: i18n.t('router.routerReport'),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: '/painel/faqs/cat/1/1',
            component: FaqsCatSelect,
            name: i18n.t('router.routerRead'),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      /*{
                                  path: "warzone",
                                  name: "warzone",
                                  component: Warzone
                                },*/
      {
        path: '/painel/rank',
        name: 'Rank',
        component: Rank,
        meta: {
          requiresAuth: true,
        },
      },
      // {
      //   path: "team",
      //   component: Team,
      //   name: "Sala de espera da Equipe"
      // },
      {
        path: 'logout',
        redirect: '/',
        name: i18n.t('router.routerExit'),
      },
      {
        path: '/politics',
        component: Politics,
        name: i18n.t('router.routerPolitics'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/rules',
        component: Rules,
        name: i18n.t('router.routerTerm'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/developers',
        component: Developers,
        name: i18n.t('router.routerDev'),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
]

export default routes
