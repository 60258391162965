import Vue from "vue";
//Translation
import VueI18n from 'vue-i18n'
import en from "./en/en.js"
import pt from "./pt/pt-br.js"

Vue.use(VueI18n)


const messages = {
    pt: pt,
    eng: en,
}

// Crie uma instância do VueI18n com opções
export default new VueI18n({
    locale: localStorage.getItem('i18n'), // Defina uma localidade
    messages, // Defina as mensagens
    fallbackLocale: 'pt',
    silentFallbackWarn: true
})
