<template>
  <div class="group-BskInput">
    <compoInput
      :proInput1="'mail'"
      :propIdInput1="'Mail'"
      :propInput1PlaceHolder="`E-Mail@hotmail.com`"
      :boole="true"
      :valor="email"
      :checkInput="checkInput"
    ></compoInput>
    <compoInput
      @send-value-Input="getEmail"
      :proInput1="'mail'"
      :propIdInput1="'novoMail'"
      :propInput1PlaceHolder="$t('message.ChangedToEmail.propTxt')"
      :valor="''"
      :boole="false"
      :checkInput="checkInput"
    ></compoInput>
  </div>
</template>

<script>
import compoInput from './tags/input'
import { mapGetters } from 'vuex'
export default {
  components: { compoInput },
  props: {
    checkInput: {
      type: Boolean,
      required: true,
    },
  },
  name: 'ChangeToEmail',
  data() {
    return {
      email: '',
      newEmail: '',
    }
  },
  computed: {
    ...mapGetters(['userAccount']),
  },
  methods: {
    getEmail(newmail) {
      if (newmail != '') this.$store.state.emailState = newmail
      else
        this.$swal(
          this.$t('swal.typeMessage[0]'),
          this.$t('swal.noEmpty'),
          'warning'
        )
    },
  },
  mounted() {
    this.email = this.userAccount.Email
  },
}
</script>

<style></style>
