/* eslint-disable */
import webShop from "./servicesAxios.js";
//import Loading from 'vue-loading-overlay';
let message = null;
var num = null;
let buyItem = (
  list,
  listPremium,
  typeCash,
  category,
  userAccount,
  isExpired,
  buyItem,
  loading
) => {
  console.log("entrou");
  let check = "";
  //pega a linha correta do item que foi comprada ao clicar no botão.
  let valObj = listPremium.filter(function (infoArray) {
    if (infoArray.ListID == list) return infoArray.ListID;
  });

  console.log(typeCash);

  if (valObj[0].ItemPrice < typeCash) {
    //console.log('Pode Comprar')
    //this.message = 'Successful Purchase!';

    //Passa os dados de um Array de Objeto Para Outro
    Object.assign(valObj[0], {
      TypeCash: typeCash,
      userNum: userAccount
    });
    valObj[0].totalPriceItem = valObj[0].TotalStock * valObj[0].ItemPrice;

    return webShop
      .buyItem(valObj[0], category)
      .then(value => {
        check = typeBuy(
          value.buyItem[0].result,
          valObj[0].totalPriceItem,
          value.mensagem,
          buyItem,
          listPremium,
          typeCash,
          loading
        );
        return check;
      })
      .catch(err => {
        //console.log(err)
        message = "Erro na compra do item, Contate o administrador.";
        let loader = loading;
        buyItem = false;

        setTimeout(() => {
          loader.hide();
          $("#modal-default").modal("show"); // initializes and invokes show immediately
          //router.go(0)
        }, 800);
        num = -1;
        check = num;
        return {
          buy: buyItem,
          num: check /* erro de Requisição*/,
          message: message,
          typeCash: typeCash
        };
      });
    //tempo limite para desaparecer o Loading, // E aparece a Modal dizendo se foi feita com sucesso ou nao
    //return { buy: buyItem, num: num /* erro de Requisição*/ , message: message };
  } else {

    message = "Erro na compra do item, Saldo insuficiente.";
    let loader = loading;
    buyItem = false;

    setTimeout(() => {
      loader.hide();
      $("#modal-default").modal("show"); // initializes and invokes show immediately
      //router.go(0)
    }, 800);
    num = 0;
    check = num;
    return { buy: buyItem, num: check, message: message, typeCash: typeCash };
  }
  /* quando o valor do NPOINTS ou cash é menor que o valor do Item*/
  //return { buy: buyItem, num: num, message: message };
};

function typeBuy(
  isBuy,
  totalPriceItem,
  reqMsg,
  buyItem,
  listPremium,
  typeCash,
  loading
) {
  let check = "";
  if (isBuy) {
    //console.log("antes: " + typeCash)

    typeCash = typeCash - totalPriceItem;
    //console.log("Depois: " + typeCash)
    //aparece a Barra de Loading
    let loader = loading;
    buyItem = true;

    setTimeout(() => {
      loader.hide();
      $("#modal-default").modal("show"); // initializes and invokes show immediately
    }, 800);

    message = reqMsg;
    listPremium.map(function (infoArray) {
      if (infoArray.ListID == list) return (infoArray.TotalStock = 1);
    });

    return {
      buy: buyItem,
      num: 1 /* quando o valor do NPOINTS ou cash é menor que o valor do Item*/,
      message: message,
      typeCash: typeCash
    };
  } else {
    //Verifica o erro e retorna a Modal Vermelha
    console.log("entrou 4");

    message = reqMsg;

    let loader = loading;

    buyItem = false;
    //Zera os dados de compra
    listPremium.map(function (infoArray) {
      if (infoArray.ListID == list) return (infoArray.TotalStock = 1);
    });
    setTimeout(() => {
      loader.hide();
      $("#modal-default").modal("show"); // initializes and invokes show immediately
      //router.go(0)
    }, 800);
    return {
      buy: buyItem,
      num: 2 /* quando o valor do NPOINTS ou cash é menor que o valor do Item*/,
      message: message,
      typeCash: typeCash
    };
  }
  //essa parte faz o Loading quando compra ou carrega alguma coisa
}

const checkInputValue = index => {
  if (index === "-" || index === "") {
    return false;
  } else if (index < 0) {
    return false;
  }
  return true;
};

const changeInVirtualDomDisabledButtonAndActived = (index, check) => {
  if (check) {
    document.querySelector(index).setAttribute("disabled", "disabled");
    return check;
  } else {
    document.querySelector(index).removeAttribute("disabled");
    return check;
  }
};

export default {
  buyItem,
  checkInputValue,
  changeInVirtualDomDisabledButtonAndActived
};
