export default {
  message: {
    Login: {
      btnEnter: 'Entrar',
      idAlertLenght: 'ID deve conter no mínimo ',
      idAlertLenghtFinal: ' caracteres.',
      passAlertLenght: 'A senha deve conter no mínimo',
      passAlertLenghtFinal: 'caracteres.',
      passLast1: 'Problemas com a ',
      passLast2: 'senha',
      txtNewAccount: 'Ainda não possui uma conta?',
      txtNewAccountLink: 'Registrar-se',
      txtTitle: 'INCRÍVEL',
      txtDesc: 'É ter você aqui!',
      direitos: 'Todos os direitos reservados - Apoena',
    },
    RecoveryPass: {
      txtRecPass: 'Vamos recuperar sua senha',
      txtReqPass: 'Solicitação para recuperar senha!',
      txtRecId: 'Esqueceu a sua ',
      txtRecId2: 'ID Login',
      btnReqPass: 'RECUPERAR SENHA',
      btnRecPass: 'Solicitiar Troca de senha!',
      txtLabelOr: 'OU',
      txtGoBack: 'Apenas quero voltar e fazer',
      txtGoBackLink: 'Login',
      inputTxtId: 'ID Login',
      inputTxtMail: 'E-MAIL Cadastrado',
      inputTxtPassword: 'Senha',
    },
    RecoveryID: {
      txtRecId: 'Vamos recuperar sua ID Login',
      txtGoBack: 'Apenas quero voltar e fazer o',
      txtGoBackLink: 'Login',
      btnRecId: 'RECUPERAR ID',
      txtLabelOr: 'OU',
      inputTxtMail: 'E-Mail cadastrado',
    },
    Register: {
      txtTitle: 'SUA JORNADA COMEÇARA',
      txtAlertIDlenght: 'O usuário deve ter pelo menos',
      txtAlertIDlenght2: 'dígitos.',
      txtAlertPasslenght: 'A senha deve ter pelo menos',
      txtAlertPasslenght2: 'dígitos.',
      txtAlertMailExists: 'E-Mail já existente em nosso banco de dados!',
      txtAlertMailInvalid: 'E-Mail inválido!',
      labelID: 'ID Login',
      inputPlaceholderID: 'Nome de Usuário',
      labelPass: 'SENHA',
      inputPlaceholderPass: 'Crie uma senha nova',
      labelMail: 'E-MAIL',
      inputPlaceholderMail: 'Insira um E-Mail válido',
      btnRegister: 'COMEÇAR',
      txtLabelOr: 'OU',
      txtBackToLogin: 'Já possui uma conta?',
      txtBackToLoginLink: 'Faça o Login',
    },
    Activation: {
      txtTitle: 'Vamos Ativar Seu ID Login',
      txtAlertRequestCode: 'Código é Requerido!',
      txtAlertCodeLenght: 'O Código deve conter pelo menos',
      txtAlertCodeLenght2: 'dígitos.',
      txtAlertRequestID: 'ID Login é Requerido!',
      txtAlertIDlenght: 'ID Login deve conter pelo menos',
      txtAlertIDlenght2: 'dígitos.',
      inputPlaceholderRequestCode: 'Codigo de Ativação',
      inputPlaceholderRequestID: 'Digite o Seu Login',
      btnActivation: 'Ativar Conta',
      txtLabelOr: 'OU',
      txtBackToLogin: 'Apenas quero voltar e fazer',
      txtBackToLoginLink: 'Login',
    },
    SideBar: {
      txtDashboard: 'Dashboard',
      txtAccount: 'Gerenciar Conta',
      txtTransfer: 'Transferências',
      txtShop: 'Loja Cabal',
      txtDonate: 'Doações',
      txtRanking: 'Rank',
      txtNotes: 'Notas Oficiais',
      activePremium: 'PREMIUM ATIVO',
    },
    TopNavbar: {
      labelSearch: 'Pesquisar',
      txtNotifications: 'Notificações',
    },
    ContentFooter: {
      LinkRules: 'Regras de Conduta',
      LinkPolitics: 'Política Monetária',
      LinkSupp: 'Suporte',
      LinkDevs: 'Desenvolvedores',
    },
    Dashboard: {
      txtPlayerOnTitle: 'PESSOAS CONECTADAS',
      txtPropPlayerOn: 'Jogadores conectados ao servidor',
      txtCountPlayerOn: 'JOGANDO',
      txtPeriodoPremium: 'PERÍODO PREMIUM',
      txtCountPremiumDays: 'Dias Restantes',
      txtPropPremium: 'Acompanhe seu período premium',
      txtEventsTitle: 'EVENTOS ATIVOS',
      txtCountEvents: 'Eventos ativos',
      txtPropEvents: 'Detalhes dos eventos ativos',
      txtAlzs: "ALZ's NA CONTA",
      penultimateAlzs: "Último registro de Alz's da sua conta",
      reportPlayer: 'Reporte um Jogador',
      system: 'Sistema',
      character: 'Personagem',
      chatAndOthers: 'Chat, Amigos, Grupo e Guilda',
      account: 'Conta',
      chargeAndOther: ['Cobrança', 'Loja de Itens'],
      topicsRecent: 'TÓPICOS RECENTES',
      mostViewFaqs: "FAQ's mais vistos",
      temporaryBlock: [
        'Fim do Bloqueio Temporário',
        `Caso seu bloqueio temporário tenha se expirado, por favor,
                  envie um ticket..`,
      ],
      deleteCharacter: [
        'Recuperar personagem excluído',
        `Há inúmeras medidas de segurança que previnem jogadores de
                  simplesment..`,
      ],
      items: [
        'Quebra de item por engano ou aprimoramento',
        `Nós temos por norma não efetuar restaurações de items
                  quebrados por en..`,
      ],
      purchaseErrorItems: [
        'Compra errada na Loja de itens',
        `Gostaríamos de lembrar a todos os jogadores de que nós não
                  realizamos..`,
      ],
      passwordInventory: [
        'Sub-senha do inventário',
        `Informamos que para redefinir a sua sub-senha do inventário,
                  basta cli..`,
      ],
      missionDelete: [
        'Recuperar missões excluídas',
        `Para recuperar missões excluídas, siga os seguintes passos:
                  1.) Press..`,
      ],
      questionsGames: [
        'Perguntas sobre o jogo',
        `Infelizmente, não temos como fornecer informações desta
                  natureza atrav..`,
      ],
      subPasswords: [
        'Sub-Senha',
        `Para desbloquear ou redefinir sua sub-senha, assim como mudar
                  sua Perg..`,
      ],
      expired:
        'sessao expirada, pois nao conseguiu encontrar o cash do personagem',
    },
    Developers: {
      credits: [
        'CRÉDITOS SÃO SEMPRE BEM RECEBIDOS POR TODA A COMUNIDADE',
        'Créditos & Direitos de Código',
      ],
      txtCredits: [
        `Estudamos muito ao longo de anos para concretizar a ideia que se
                deu Apoena, horas de sono perdidas resultaram o excelente
                trabalho em que estás a prestigiar nesse momento.`,
        `Certamente não somos pioneiros na lógica da plataforma, portanto
                nós iremos destacar nick-names de desenvolvedores que tornaram
                possível desde a suma inspiração quanto primeiros códigos
                públicos que fizeram nossos sistemas servirem seus propósitos.`,
      ],
    },
    Donate: {
      financial: ['NAVEGUE PELO FINANCEIRO', 'DOAÇÃO'],
      donate: 'Fazer uma nova doação',
      historyDonate: 'Histórico de doações',
      metaDonate: 'Meta de doações',
      support: 'Suporte',
      trackUDonate: 'Acompanhe a sua doação!',
      toolsDonate: 'FERRAMENTAS DE ACOMPANHAMENTO DAS DOAÇÕES',
      filterDonate: 'Além de filtradas essas ações são registradas.',
    },
    Transfers: {
      transf: ['QUER TRANSFERIR O QUE?', 'TRANSFERIR'],
      sendCharacter: 'Enviar um Personagem',
      receiveCharacter: 'Receber um Personagem',
      txtTransf: 'Escolha, Defina, Transfira e Receba!',
      supportAndOthers:
        'COM SEGURANÇA NÓS OFERECEMOS SUPORTE SOBRE SUA TRANSAÇÃO, RÁPIDA E FÁCIL',
      taxaServer: 'Essa ferramenta é Taxada pelo Servidor.',
    },
    Shop: {
      reportCommercial: 'RELATÓRIO COMERCIAL',
      txtDados: 'Alguns dados não são atualizados em tempo real',
      ChooseCategoryAndOther: ['ESCOLHA UMA CATEGORIA', 'LOJA'],
      buyItem: 'Suas compras são efetuadas em tempo real',
      security: 'USE DE TODA SEGURANÇA PARA REALIZAR SUAS COMPRAS',
      footer: [
        'Nossa ',
        'loja',
        'possui sistemas de log e backup, para sua segurança.',
      ],
      tpoint: 'Não Implementado ainda.',
    },
    Rules: {
      rules: [
        'SEJA DISPOSTO À SEGUIR AS REGRAS DE CONDUTA E TERMOS DO SERVIDOR',
        'REGRAS & TERMOS DE USO',
        'VOCÊ PODE',
        'O servidor poderá definir tópicos de permissão!',
        'VOCÊ NÃO PODE',
        'TERMOS DE USO',
        'O servidor poderá criar um longo texto definindo os Termos de Uso e Especificação das regras de conduta!',
      ],
      txtRules:
        'Reservamos o direito de alteração de qualquer linha sem o aviso prévio!',
    },
    Rank: {
      memoPlayers: 'MEMORANDO DOS MAIORES DE NEVARETH ESTÃO LISTADOS AQUI',
      character: 'PERSONAGENS',
      tableListPlayers: [
        'Nome do Jogador',
        'Classe',
        'Level',
        'Honra',
        'Nação',
      ],
      guilds: 'GUILDS',
      tableListGuilds: ['Nome Guilda', 'Level', 'Lider', 'Pontos'],
      security: 'Não iremos afetar a posição dos membros aqui listados!',
      internalError: 'Erro Interno do Sistema',
    },
    Politics: {
      politics: [
        'NOSSO SERVIDOR É TRANSPARENTE, LEIA NOSSA POLÍTICA MONETÁRIA E ENTENDA',
        'POLÍTICA MONETÁRIA',
        `O servidor poderá criar um longo texto definindo as
                especificações das Políticas Monetárias a que se dará o
                servidor!`,
      ],
      txtProps:
        'Reservamos o direito de alteração de qualquer linha sem o aviso prévio!',
    },
    News: {
      txtProp: 'Em breve ofereceremos suporte para Fã Sites!',
      news: ['ACOMPANHE NOSSO SERVIDOR', 'NOTÍCIAS & ATUALIZAÇÕES'],
    },
    Account: {
      ip: 'Nenhum no momento',
      dadosHours: ['Há', 'anos', 'dias', 'meses', 'horas', 'minutos'],
      txtProp: 'Ferramenta',
      managementAccount: 'GERENCIE DE FORMA EFICIENTE',
      lastLogin: ['Último Login', 'atrás'],
      lastIpLogin: 'IP do Último Login',
      dados: 'desde',
      AccountStatus: ['Status da Conta', 'Verificada', 'Não Verificada'],
      timePlaying: ['Tempo Jogado', 'Horas', 'Minutos', 'Segundos'],
      txtProps2: 'Alguns dados não são atualizados em tempo real',
      toolsBox: ['CAIXA DE FERRAMENTAS', 'FERRAMENTAS'],
      dataAccount: 'Dados da Conta',
      mycharacters: 'Meus Personagens',
      points: 'Distribuir Pontos',
      mySecurity: 'Minha Segurança',
      clearPk: 'Limpar o PK',
      chooseTools: 'Escolha qual ferramenta usar.',
      toolsPlan: 'FERRAMENTA EM PRIMEIRO PLANO',
    },
    Footer: {
      footer: 'Gratuita',
    },
    Payment: {
      //["Attention we are Processing Your Payment!","Donate processing done successfully!.","Error Processing Payment! Contact your administrator."]
      payment: [
        'Atenção, estamos processando seu pagamento!',
        'Processamento de doações concluído com sucesso!',
        'Erro ao processar o pagamento! Contate seu administrador.',
      ],
    },
    Pk: {
      chooseCharacter: ['Selecione um Personagem', 'Escolher Personagem'],
      charPk: 'Você está Penalizado',
      clearPk: 'Limpar meu PK por 250 de T-Point',
      cashTpoint: 'Saldo disponível em T-Point',
    },
    ItemShop: {
      imgItem: 'Imagem do Item',
      buyItem: [
        'Você tem certeza de comprar esses Itens?',
        'Realmente deseja comprar esses itens?',
      ],
      errorBuy: 'Erro na compra do item, Contate o administrador.',
      quitItem: 'Você está desistindo da Compra ao fazer isso!',
    },
    AccInfo: {
      rootEmail: 'Meu e-mail raiz',
      changedEmail: 'ALTERAR E-MAIL',
      securityTwo: 'Segurança em Duas Etapas',
      datePeople: 'Dados Pessoais',
      txtBigger: [
        'Leitura Recomendada',
        'Prezamos pela sua segurança!',
        `Não adianta que coloquemos CAPTCHA em todos os lugares, pessoas má
                intencionadas continuarão cadastrando contas e estressando nossos
                servidores.`,
        `É importante que você forneça DADOS REAIS, pois a condição da sua
                conta deve ser respeitada por nós.`,
        `Contas não verificadas e com segurança reforçada desativada serão
                banidas com um período de tempo não publicado!`,
        'SOBRE E-MAIL',
        'Não iremos aceitar e-mails temporários ou com domínios estranhos!',
        `Caso utilize e-mail temporário possui um prazo de 30 Dias após a
                criação da conta para que faça a alteração para um e - mail seguro.`,
      ],
    },
    AccSec: {
      security: [
        'Segurança em Duas Etapas',
        'Está desativada a segurança em duas etapas!',
      ],
      accountSec: [
        'Qual a precisão de ativar meu número pessoal?',
        'Se faz importante, pois além de reforçar a sua segurança poderemos informá - lo sobre qualquer alteração e validações.',
        `Para nós, faz-se importante para ajudar nosso algorismo a identificar
          com reforço de fórmula a eficiência das interrupções de contas falsas
          geridas por pessoas que vão além da má intenção.`,
        `Com a Segurança em Duas Etapas ATIVA você poderá criar Conta-Mula que
          poderão ser gerenciadas pelo seu painel!`,
      ],
    },
    CategoryShop: {
      txt: 'Serviços Premium',
    },
    ChangedToEmail: {
      propTxt: 'Alterar E-Mail',
    },
    Characters: {
      delete: 'Deletar Personagem',
    },
    DataPeople: {
      name: 'SEU NOME COMPLETO',
      changeName: 'Alterar Nome',
      password: 'SENHA ATUAL',
      changedPassword: 'Alterar Senha',
      reworkPassword:
        'Caso tenha perdido ou esquecido sua senha, faça o Logout e Recupere-a.',
      subpass: 'SUB-SENHA ATUAL',
      changeSubPass: 'Alterar Sub-Senha',
      btn: 'SALVAR ALTERAÇÕES',
    },
    DistPoints: {
      chooseCharacter: ['Selecione um Personagem', 'Escolher Personagem'],
      dist: 'Pontos p/ Distribuir',
      points: 'Pontos Atuais em',
    },
    EnvChar: {
      chooseChar: [
        'SELECIONE UM PERSONAGEM',
        'Selecione o personagem que você optou por enviar na sua lista de personagens criados.',
      ],
      gerarBtn: 'GERAR COÓDIGO',
    },
    HistDonate: {
      apoio: [
        'parece que você ainda não nos apoiou.',
        'Apoie-nos, doe algum valor para o servidor se manter ativo e atualizado!',
      ],
      tableList: [
        'indice',
        'Nome do Doador',
        'Data',
        'Valor',
        'Status',
        'Metodo de Pagamento',
      ],
      typeMoeda: 'R$',
    },
    SecurityTwo: {
      cell: 'Alterar NºCell',
      btn: 'ATIVAR REFORÇO',
      changeCell: 'telefone inserido/trocado Com Sucesso',
    },
    ReportCommercial: {
      balance: 'Saldo',
    },
    RecChar: {
      inform: [
        'INFORME O CÓDIGO',
        'Digite o Código do Personagem ao qual você quer receber, certifique-se de que não tenha expirado!',
        'CÓDIGO',
      ],
      search: 'PESQUISAR',
      notGuild: 'Sem Guild',
      expireCode: 'O código expira em',
      receiveChar: [
        'Você receberá: Personagem e todo o Inventário incluindo os Alzs.',
        "Não serão mantidos as posições em Rank's.",
        'Não receberá os títulos de Portador/Guardião/Guerra conquistados pelo personagem.',
        'Não receberá os pontos de guerra semanais feitos até a transferência.',
        'É obrigatório que ambos os personagens estejam Sem Guilda.',
        'É obrigatório que se tenha 1 Slot de Personagem LIVRE!',
        'valor',
      ],
      chooseCharReplace: [
        'Selecione o personagem que será substituido ao receber o novo personagem.',
        'Escolha um Personagem',
      ],
      btnFinished: 'FINALIZAR TRANSFERÊNCIA',
    },
    NovoDonate: {
      approvalPayment:
        'Pagamento Criado Com Sucesso, em poucos minutos irá abrir outra Aba para realização do pagamento',
    },
    MetaDonate: {
      meta: [
        'Acompanhe nossas Metas & Objetivos',
        `Essa ferramenta foi desenvolvida para que você possa com
            transparência acompanhar o Financeiro do servidor e entender em que
            será destinado seu dinheiro doado!`,
        'METAS',
      ],
      definedMetas: [
        'As metas são definidas pela Equipe Gestora, acompanhe.',
        'META DO MÊS',
        'A meta é definida para suprir os custos do servidor, esse mês a meta de doações é de',
        'Meta definida fora concluída.',
        'META DO MÊS',
        'Meta definida está em progresso.',
        'Meta definida ainda não obteve progresso.',
        'OBJETIVOS',
        'Metas são definidas para que possamos trazer ATUALIZAÇÕES e também manter o servidor operando com suporte e manutenção.',
        'É de suma importância que cumpramos com as metas definidas mensalmente para que sua estadia em nosso ambiente seja sempre repleta de novidades e atrações.',
        'Aqui você tem acesso aos nossos objetivos e faz parte de toda a nossa progressão!',
        'SISTEMA QUE CUSTA DINHEIRO',
      ],
      progress: 'PROGRESSÃO',
      detailsPayment: ['Arrecadados', 'Meta Mensal', 'Recreativos'],
    },
    InfoTransfer: {
      transfer: [
        'O código gerado tem a duração de 24 horas.',
        'Seu personagem, alz e todo o inventário serão transferidos.',
        'Os ranks de DX serão removidos.',
        'Caso o personagem esteja Portador ou Guardião, perderá o título.',
        'A pontuação semanal de Guerra (TG) será zerada.',
        'É obrigatório que ambos os personagens estejam Sem Guilda.',
      ],
    },
  },
  swal: {
    error: 'Erro Interno no sistema',
    not_found_cash: 'Nao foi Encontrado o cash em sua conta!',
    typeMessage: ['Atenção', 'Error', 'Sucesso'],
    not_found_char: 'você nao possui char registrados na sua conta',
    error_character_in_account:
      'Erro ao pesquisar por personagens em sua conta. Por favor recarregue a pagina e tente novamente.',
    not_found_dados:
      'Erro, você deixou de informar algum dado para o registro, verifique novamente',
    successAcount:
      'Conta criada com sucesso! Verifique seu e-mail para ativação da conta',
    alreadyAccount: 'Usuario já Cadastrado!',
    alreadyEmail: 'Esse E-mail já está Cadastrado, use outro.',
    error_register_account:
      'Erro no Processo de Cadastro do usuario contate o Administrador.',
    checkDados:
      'Por favor verifique seus dados de criação e preencha-os corretamente!',
    not_found_news: 'Nao Existe noticia Cadastrada no momento!',
    titleLogin: 'Login realizado com sucesso',
    txtLogin: "Confira as novidades no menu 'Notas Oficiais' ",
    btnLogin: 'Ir para o painel',
    errorLogin: 'Algo deu errado...',
    errorCredentials: 'Por favor, verifique suas credenciais e tente novamente',
    tryAgain: 'Tentar novamente',
    errorActiveAccount: 'Erro ao ativar a conta',
    activeAccount: 'Sucesso ao ativar a conta!',
    processingDonate:
      'Sua doação está sendo processada! Por favor espere um momento.',
    errorBD: 'Error em Conectar ao banco de dados',
    notChooseChar: "'Não foi selecionado nenhum char",
    errorCash: 'Atenção Total de Cash insuficiente para comprar o item',
    errorQntItems:
      'Atenção você não colocou a quantidade de Itens você gostaria de comprar!',
    not_foundItems: 'Nao tem nenhum Item cadastrado nessa Categoria!',
    changedEmail: 'Email trocado Com Sucesso',
    errorEmail: [
      'Erro ao Atualizar seu Email!',
      'atualize a pagina e tente novamente!',
    ],
    noEmpty: 'preencha o campo corretamente',
    dadosSave: 'Dados Salvos Com Sucesso',
    dist: 'você digitou valores invalidos no campo de distribuição',
    selectChar: 'Erro ao selecionar Char!',
    insufficientPoint: 'Pontos insuficientes.',
    errorDistPoint: 'Erro ao Distribuir Pontos!',
    zerorPoints: 'Coloque algum valor para ser Distribuido Diferente de 0',
    erroTransferChar: 'Erro ao Transferir Char!',
    errorTransChar: 'Erro na Transferencia e criação de codigo do personagem',
    errorChar: [
      'Erro na busca do Personagem',
      'Nao foi encontrado nenhum Personagem',
    ],
    graveError: 'Erro Grave,Relogue no Painel para acessar essa função',
    not_foundDonate: 'Você ainda nao fez uma doação ao servidor!',
    erroCell: 'Erro ao Atualizar seu telefone!',
    errorReceiveChar:
      'Ocorreu um erro grave, entre em contato com o administrador.',
    codeExpire:
      'O tempo do código expirou, você não pode continuar com esta ação',
    cashChar:
      'Para concluir esta ação, você deve ter pelo menos 5.000 em dinheiro.',
    confirmedChange: [
      'Você deseja Trocar de personagem?',
      'A troca de personagem será feito em cima do Char Escolhido',
    ],
    notFoundCode: 'Código não encontrado no banco de dados!',
    AttentionCode:
      'Para concluir esta ação você precisa inserir o código que foi enviado a você pelo usuário que deseja trocar o envio do char.',
    notFoundUser:
      'Erro Não Foi possivel identificar o seu usuario! Por favor faço o login novamente',
    apiConnectError: 'Erro ao conectar com API do sistema!',
    emptyDonate: 'A lista de doações está vazia',
  },
  char: {
    nation: 'Sem Nação',
    class: ['GU/GL', 'DU', 'MA/DM', 'AA/FG', 'GA', 'EA'],
  },
  router: {
    routerName: 'Dados da Minha Conta',
    routerSecurity: 'Segurança da Conta',
    routerChar: 'Meus Personagens',
    routerDist: 'Distribuir Pontos',
    routerData: 'Dados da Conta',
    routerPk: 'Resetar PK',
    routerDonate: 'Doações',
    routerNewDonate: 'Realizar uma nova Doação',
    routerHistDonate: 'Histórico Pessoal de Doações',
    routerMetas: 'Metas de Doações Mensais',
    routerTransfer: 'Transferências',
    routerSend: 'Transferir - Enviar um Personagem',
    routerReceive: 'Transferir - Receber um Personagem',
    routerCommunity: 'Acompanhamento da Comunidade',
    routerNotas: 'Notas Oficiais',
    routerSupport: 'Suporte',
    routerReport: 'Reportar um Jogador',
    routerRead: 'Aprecie e disponha de uma boa leitura',
    routerExit: 'Sair',
    routerPolitics: 'Política Monetária',
    routerTerm: 'Termos de Conduta',
    routerDev: 'Desenvolvedores Apoena',
  },
}
