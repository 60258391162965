<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 sm-size-100 md-size-100"
      >
        <!-- - -->
        <bsk-stats-card data-background-color="blue">
          <template slot="header">
            <i class="far fa-newspaper"></i>
          </template>

          <template slot="content-title">
            <p class="category">{{ $t('message.News.news[0]') }}</p>
            <h3 class="title">{{ $t('message.News.news[1]') }}</h3>
          </template>

          <template slot="content">
            <div class="container-fluid">
              <div class="row example-basic">
                <div class="col-xs-10 col-xs-offset-1 col-sm-8 col-sm-offset-2">
                  <ul class="timeline">
                    <li
                      class="timeline-item"
                      v-for="(news, index) in newsList"
                      :key="index"
                    >
                      <div class="timeline-info">
                        <span
                          >{{ month(news.date) }} {{ day(news.date) }},
                          {{ year(news.date) }}</span
                        >
                      </div>
                      <div class="timeline-marker"></div>
                      <div class="timeline-content">
                        <h3 class="timeline-title">
                          {{ news.newsTitle }} - {{ news.title }}
                        </h3>
                        <p>{{ news.message }}</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </template>

          <template slot="footer">
            <footers
              classCss="stats"
              mdIcon="info"
              :textProp="$t('message.News.txtProp')"
              :check="true"
            ></footers>
          </template>
        </bsk-stats-card>
      </div>
    </div>
  </div>
</template>
<script>
import { BskStatsCard } from '@/components'
import footers from '../components/Footers/Footer'
import axios from '../services/servicesAxios'
import { parseISO, getDate, getYear, getMonth } from 'date-fns'
import month from '../services/static/month'
export default {
  components: {
    BskStatsCard,
    footers,
  },
  data() {
    return {
      newsList: {},
      vec: [],
      testando: null,
      sameVal: [],
    }
  },
  computed: {
    month: () => index => {
      return month.month[getMonth(parseISO(index))]
    },
    day: () => index => {
      return getDate(parseISO(index))
    },
    year: () => index => {
      return getYear(parseISO(index))
    },
  },
  methods: {
    getLastDay(index, i) {
      if (this.test === null) {
        this.test = getMonth(parseISO(index[i].date))
        this.sameVal[i] = index[i].date
      } else if (this.test != getMonth(parseISO(index[i].date))) {
        this.test = null
        this.sameVal[i] = index[i].date
      }
      if (this.sameVal[i] != undefined) {
        return this.sameVal.indexOf(index[i].date) === 0
          ? 'timeline-item'
          : 'timeline-item period'
      } else {
        return 'timeline-item'
      }
    },
  },
  mounted() {
    axios
      .getNews()
      .then(value => {
        if (value.status === 200 && value.data.code === 1)
          this.newsList = value.data.news
        else
          this.$swal(
            this.$t('swal.typeMessage[0]'),
            this.$t('swal.not_found_news'),
            'warning'
          )
      })
      .catch(err => {
        if (err.response.status === 404)
          this.$swal('error', err.response.data.msg, 'error')
      })
  },
  beforeUpdate() {
    document
      .querySelector('.router-link-active')
      .classList.add('nav-item', 'active')
    document.body.scrollTop = document.documentElement.scrollTop = 0
  },
}
</script>
<style scoped lang="scss">
@import '../assets/scss/md/_News.scss';
</style>
