<template>
  <!-- - -->
  <div class="full-page-Recovery">
    <loading :active.sync="visible" :can-cancel="false"></loading>

    <selectI18n />
    <div class="grid-cardRecovery-bsk">
      <!-- <div class="row">
        <div class="logo-BskRecovery"></div>
      </div> -->
      <div class="row">
        <h1>{{ $t('message.RecoveryPass.txtReqPass') }}</h1>
        <div class="group-BskInput">
          <div style="">
            <span class="" style="color: red" v-if="!$v.user.required"
              >Por favor preencha o campo de usuario!</span
            >
            <span class="" style="color: red" v-if="!$v.user.minLength"
              >Preencha o campo com no minino
              {{ $v.user.$params.minLength.min }} caracteres ou mais</span
            >
          </div>
          <input
            type="text"
            id="User"
            v-model.lazy="user"
            class="BskInput"
            placeholder="ID Login"
          />
          <div class="iconBsk"><i class="far fa-user"></i></div>
        </div>
        <!-- - -->
        <div class="group-BskInput">
          <div style="">
            <span class="" style="color: red" v-if="!$v.email.required"
              >Por favor preencha o campo de email!</span
            >
            <span class="" style="color: red" v-if="!$v.email.minLength"
              >Preencha o campo com no minino
              {{ $v.email.$params.minLength.min }} caracteres ou mais</span
            >
          </div>
          <input
            type="mail"
            id="Mail"
            v-model.lazy="email"
            class="BskInput"
            :placeholder="$t('message.RecoveryPass.inputTxtMail')"
          />
          <div class="iconBsk"><i class="far fa-at"></i></div>
        </div>
        <!-- - -->
        <div class="group-BskBestAction">
          <p>
            {{ $t('message.RecoveryPass.txtRecId') }}
            <router-link to="/recid">{{
              $t('message.RecoveryPass.txtRecId2')
            }}</router-link
            >?
          </p>
          <button :disabled="disabled" @click.prevent="recoveryPassword">
            {{ $t('message.RecoveryPass.btnReqPass') }}
          </button>
        </div>
        <!-- - -->
        <div class="group-BskInput">
          <hr
            class="bsk-hr"
            :data-content="$t('message.RecoveryPass.txtLabelOr')"
          />
        </div>
        <!-- - -->
        <div class="group-BskGoodAction">
          {{ $t('message.RecoveryPass.txtGoBack') }}
          <router-link to="/">{{
            $t('message.RecoveryPass.txtGoBackLink')
          }}</router-link
          >.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import selectI18n from '@/components/localeTranslation/Select-I18n.vue'
import axios from '../services/servicesAxios.js'
import { required, minLength } from 'vuelidate/lib/validators/'
import 'vue-loading-overlay/dist/vue-loading.css'
import Loading from 'vue-loading-overlay'
export default {
  name: 'Recoverypass',
  components: { selectI18n, Loading },
  validations: {
    user: {
      required,
      minLength: minLength(5),
    },
    email: {
      required,
      minLength: minLength(8),
    },
  },
  data() {
    return {
      user: '',
      email: '',
      disabled: false,
      visible: false,

      // password: '',
    }
  },
  methods: {
    async recoveryPassword() {
      this.disabled = true
      this.visible = true

      if (this.user === '' || this.email === '' /*|| this.newPass === ''*/) {
        this.$swal(
          'Error',
          'Please change your password to fill in the data correctly',
          'error'
        )
        this.visible = false
      } else {
        try {
          const response = await axios.requestRecoveryPasswordAccount({
            user: this.user,
            email: this.email,
          })
          setTimeout(() => {
            this.visible = false
            if (response.status == 200) {
              this.$swal('success', response.data.msg, 'success').then(
                () => (this.visible = false)
              )
              this.disabled = false
            }
          }, 1200)
        } catch (err) {
          //console.log(err)
          if (err.response !== undefined) {
            if (err.response.status === 400)
              this.$swal('Warning', err.data.msg, 'warning')
            if (err.response.status === 404)
              this.$swal('Error', err.data.msg, 'error')
          }
        }
      }
    },
  },
}
</script>
<style scoped lang="scss">
.full-page-Recovery {
  width: 100vw;
  height: 100vh;
  margin: auto;
  padding: 40px;
  background: url('../assets/img/Auth/bg-BskRpt.png') repeat;
  overflow: hidden;
  p {
    width: 100%;
    text-align: center;
    font-weight: bold;
    color: #7e633b;
  }
  .grid-cardRecovery-bsk {
    width: 80vw;
    height: 600px;
    padding: 50px;
    margin: calc(50vh - 350px) auto;
    border: 1px solid #e1dccb;
    background-color: rgb(250, 250, 250, 0.9);
    animation: pulse-Bskz 5s infinite;
    .logo-BskRecovery {
      width: 187px;
      height: 104px;
      margin: auto;
      background: url('../assets/img/Auth/Logo-Bsk-lib.png');
      background-position-x: -499px;
      background-position-y: 0;
    }
    h1 {
      font-family: 'BSKTxt';
      font-size: 4vw;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 6px;
      line-height: 1.16;
      color: #000;
      text-align: center;
    }
    .group-BskInput {
      width: 100%;
      padding: 20px 0 0 0;
      .BskInput {
        width: 100%;
        height: 40px;
        color: #c4b998;
        border: 2px solid #f3f1ea;
        border-radius: 100px;
        padding-left: 40px;
        &:focus {
          color: #c4b998;
          border: 2px solid #7e633b;
        }
      }
      .iconBsk {
        margin: -30px 20px 1px;
      }
      hr.bsk-hr {
        width: 100%;
        line-height: 1em;
        position: relative;
        outline: 0;
        border: 0;
        color: #7e633b;
        text-align: center;
        height: 1.5em;
        opacity: 0.5;
        &:before {
          content: '';
          background: linear-gradient(
            to right,
            transparent,
            #c4b998,
            transparent
          );
          position: absolute;
          left: 0;
          top: 50%;
          width: 100%;
          height: 1px;
        }
        &:after {
          content: attr(data-content);
          position: relative;
          display: inline-block;
          color: #c4b998;

          padding: 0 0.5em;
          line-height: 1.7em;
          color: #7e633b;
          background-color: #fcfcfa;
        }
      }
    }
    .group-BskBestAction {
      width: 100%;
      padding: 20px 0 0 0;
      margin: auto;
      text-align: center;
      button {
        padding: 5px 5px;
        background: #815500;
        font-family: 'BSKTxt';
        font-size: 1rem;
        font-weight: 100;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #937341;
        cursor: pointer;
        line-height: 1;
        border: none;
        position: relative;
        z-index: 1;
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.11);
        transition: color 0.2s;
        min-width: 100px;
        max-width: 100%;
        border-radius: 100px;
        right: 0;
        padding: 10px 20px 10px;
        &:before {
          top: 2px;
          left: 2px;
          right: 2px;
          bottom: 2px;
          content: '';
          display: block;
          position: absolute;
          background: #f3f3f3;
          z-index: -1;
          border-radius: 100px;
        }
      }
      p {
        padding-top: 3px;
        padding-left: 4px;
        font-size: 1em;
        left: 0;
      }
    }
    .group-BskGoodAction {
      width: 100%;
      padding: 10px 30px;
      margin: auto;
      text-align: center;
    }
  }
}
@keyframes pulse-Bskz {
  0% {
    -webkit-box-shadow: 0 10px 20px -18px rgb(126, 99, 59, 1);
    -moz-box-shadow: 0 10px 20px -18px rgb(126, 99, 59, 1); /* Ty Neo s2 */
    box-shadow: 0 10px 20px -18px rgb(126, 99, 59, 1);
    opacity: 0.9;
  }
  50% {
    border: 1px solid #fff;
    -webkit-box-shadow: 0px 10px 30px -10px rgb(208, 168, 92, 0.5);
    -moz-box-shadow: 0px 10px 30px -10px rgb(208, 168, 92, 0.5); /* Ty Neo s2 */
    box-shadow: 0px 10px 30px -10px rgb(208, 168, 92, 0.5);
    opacity: 1;
  }
  100% {
    -webkit-box-shadow: 0 10px 20px -18px rgb(126, 99, 59, 1);
    -moz-box-shadow: 0 10px 20px -18px rgb(126, 99, 59, 1); /* Ty Neo s2 */
    box-shadow: 0 10px 20px -18px rgb(126, 99, 59, 1);
    opacity: 0.9;
  }
}
</style>
