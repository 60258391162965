<template slot="content">
  <div class="divTable">
    <router-link class="divToolboxCell-Left" :to="{ name: 'Premium' }" exact>
      <i class="fas fa-tags"></i>
      {{ $t('message.CategoryShop.txt') }}</router-link>
    <router-link class="divToolboxCell-Left" v-for="shop in menuShop" :key="shop.SubID"
      :to="{ name: 'shop', params: { id: shop.SubID } }" exact>
      <i class="fas fa-tags"></i> {{ shop.SubName }}
    </router-link>
  </div>
</template>
<script>
import axios from '../services/servicesAxios'
export default {
  name: 'CategoryShop',
  data() {
    return {
      menuShop: [],
    }
  },
  async mounted() {
    let vcoins = [];
    let menuItem = [];
    // if (sessionStorage.getItem('data-shop')) {
    //   this.menuShop = JSON.parse(atob(sessionStorage.getItem('data-shop')))
    // } else {

    // }

    const menuResponse = await axios.subMenuShop()
    if (menuResponse.status === 200) {
      if (menuResponse.data.vcoins.length >= 0) {
        vcoins = menuResponse.data.vcoins[0]
      }
      if (menuResponse.data.menu.length >= 0) {
        menuItem = [...menuResponse.data.menu];
      }
      this.menuShop = [...vcoins, ...menuItem]
      this.$store.commit('fixedMenuShop', this.menuShop)
      sessionStorage.setItem('data-shop', btoa(JSON.stringify(this.menuShop)))
    }
  },
}
</script>
<style scoped lang="scss">
@import '../assets/scss/md/_shop.scss';
</style>
