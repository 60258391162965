<template>
  <div class="md-layout Bpad">
    <div class="
        md-layout-item md-medium-size-70 md-xsmall-size-100
        sm-size-100
        md-size-70
      " v-if="validAccountAuth.authType === 1">
      <loading :active.sync="visible" :can-cancel="false"></loading>

      <div class="groupFormBsk">
        <h3>{{ $t('message.AccInfo.rootEmail') }}</h3>
        <!-- - -->
        <changeToEmail :checkInput="check"></changeToEmail>
        <!-- - -->
        <div class="group-BskBestAction">
          <button @click="changeEmail">
            {{ $t('message.AccInfo.changedEmail') }}
          </button>
        </div>
      </div>
      <hr />
      <!-- // -->
      <!-- <div class="groupFormBsk">
        <h3>{{ $t('message.AccInfo.securityTwo') }}</h3>
        <securityTwo />
      </div> -->
      <!-- <hr /> -->
      <!-- // -->
      <div class="groupFormBsk">
        <h3>{{ $t('message.AccInfo.datePeople') }}</h3>
        <!-- - -->
        <data-people></data-people>
      </div>
    </div>
    <div class="
            md-layout-item md-medium-size-70 md-xsmall-size-100
            sm-size-100
            md-size-100
          " v-else>
      <div class="groupFormBsk">
        <div class="group-BskBestAction">
          <h3>É preciso validar sua conta para visualizar essa pagina!</h3>

        </div>
      </div>
    </div>
    <!-- /Left -->
    <div class="
        md-layout-item md-medium-size-30 md-xsmall-size-100
        sm-size-100
        md-size-30
      " v-if="validAccountAuth.authType === 1">
      <div class="groupRulesBsk">
        <h3>{{ $t('message.AccInfo.txtBigger[0]') }}</h3>
        <p>{{ $t('message.AccInfo.txtBigger[1]') }}</p>
        <p>
          {{ $t('message.AccInfo.txtBigger[2]') }}
        </p>
        <p>
          {{ $t('message.AccInfo.txtBigger[3]') }}
        </p>
        <p>
          {{ $t('message.AccInfo.txtBigger[4]') }}
        </p>
        <h3>{{ $t('message.AccInfo.txtBigger[5]') }}</h3>
        <p>{{ $t('message.AccInfo.txtBigger[6]') }}</p>
        <p>
          {{ $t('message.AccInfo.txtBigger[7]') }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import changeToEmail from './ChangeToEmail'
import { mapActions, mapGetters } from 'vuex'
// import securityTwo from './SecurityTwo'
import DataPeople from './DataPeople.vue'
import 'vue-loading-overlay/dist/vue-loading.css'
import Loading from 'vue-loading-overlay'
export default {
  components: { changeToEmail, /*securityTwo,*/ DataPeople, Loading },
  data() {
    return {
      email: '',
      newEmail: '',
      check: false,
      visible: false,
    }
  },
  computed: {
    ...mapGetters(['userAccount']),
    validAccountAuth() {
      return {
        authType: this.userAccount.AuthType
      }
    }
  },
  methods: {
    ...mapActions(['userActionChangeToEmail']),
    async changeEmail() {
      try {
        this.visible = true

        if (this.$store.state.emailState != '') {
          const changeMail = await this.userActionChangeToEmail({
            mail: this.$store.state.emailState,
            oldMail: this.userAccount.Email,
            ID: this.userAccount.ID,
          })
          if (changeMail === 1) {
            //Commit Direto numa Mutations
            this.$store.commit(
              'userAccountChangeEmail',
              this.$store.state.emailState.value
            )
            this.visible = false
            this.check = true
            this.$swal(
              this.$t('swal.typeMessage[2]'),
              this.$t('swal.changedEmail'),
              'success'
            )
          } else {
            this.check = false
            this.visible = false

            this.$swal(
              this.$t('swal.typeMessage[1]'),
              this.$t('swal.errorEmail[0]'),
              'error'
            )
          }
        } else {
          this.visible = false
          this.$swal(
            this.$t('swal.typeMessage[0]'),
            this.$t('swal.noEmpty'),
            'warning'
          )
        }
      } catch (e) {
        console.log(e)
        this.$swal(
          'Error',
          e.response.data.msg || e.response.data.mensagem,
          'error'
        )
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import '../assets/scss/md/_AccInfo.scss';
</style>
