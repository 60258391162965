<template>
  <div class="md-layout Bpad">
    <div
      class="
        md-layout-item md-medium-size-100 md-xsmall-size-100
        sm-size-100
        md-size-100
      "
    >
      <div class="container">
        <div
          v-bind:class="listDonate(don).pay"
          v-for="(don, index) in listDonation"
          :key="index"
        >
          <div class="cutD"></div>
          <div class="rs">
            <i>{{ $t('message.HistDonate.typeMoeda') }}</i>
          </div>
          <div class="value">
            <i>{{ don.value }}</i>
          </div>
          <div class="bank">{{ don.typePayment }}</div>
          <div :class="listDonate(don).button">
            <div class="ContentItem">
              <div class="QtItem">
                <button
                  class="CpItem"
                  type="submit"
                  @click.prevent="createPayment(don)"
                >
                  <span class="cash">{{ don.valueCash }}</span>
                  <i class="fas fa-usd-square fa-lg"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- -->

        <!-- -->
      </div>
      <!-- / Col -->
    </div>
  </div>
</template>
<script>
import axios from '../services/servicesAxios'
import donate from '../services/static/listDonation'
import { mapGetters } from 'vuex'
export default {
  name: 'Donate',
  data() {
    return { listDonation: {}, approval: '', check: false }
  },
  computed: {
    listDonate: () => (obj) => {
      return donate.donate[obj.typePayment]
    },
    ...mapGetters(['userAccount']),
  },
  methods: {
    createPayment(event) {
      document
        .querySelector('button.CpItem')
        .setAttribute('disabled', 'disabled')
      axios
        .createPaymentMp({
          title: event.typePayment + ' ' + event.id,
          valorDonation: event.value,
          NameAccount: this.userAccount.ID,
          email: this.userAccount.Email,
          typePayment: event.typePayment,
        })
        .then((value) => {
          if (value.status === 201) {
            this.approval = value.data
            this.$swal({
              title: this.$t('swal.typeMessage[2]'),
              text: this.$t('message.NovoDonate.approvalPayment'),
              icon: 'success',
              button: {
                cancel: 'cancel',
                ok: {
                  text: 'ok',
                  value: 'catch',
                },
                defeat: true,
              },
            }).then(() => {
              this.check = true
              if (this.check) window.open(this.approval, '_blank')
              setTimeout(() => {
                document
                  .querySelector('button.CpItem')
                  .removeAttribute('disabled')
              }, 500)
            })
          }
        })
        .catch(() => {
          this.$swal(
            this.$t('swal.typeMessage[1]'),
            this.$t('swal.apiConnectError'),
            'error'
          )
        })
    },
  },
  async mounted() {
    if (sessionStorage.getItem('data2')) {
      this.listDonation = JSON.parse(atob(sessionStorage.getItem('data2')))
    } else {
      const list = await axios.listDonation()
      if (list.status === 200) {
        this.listDonation = list.data
        sessionStorage.setItem('data2', btoa(JSON.stringify(this.listDonation)))
      } else
        this.$swal(
          this.$t('swal.typeMessage[0]'),
          this.$t('swal.emptyDonate'),
          'warning'
        )
    }
  },
}
</script>
<style scoped lang="scss">
@import '../assets/scss/md/_Donate.scss';
button.CpItem:hover {
  span.cash {
    display: inline-block;
    color: #ffd700;
  }
}
</style>
