export const colorHousesAlzs = ({ warehouse, alzsAccount }) => {
    const bytes = colorTotalAlzs({ warehouse, alzsAccount })
    return { color: bytes.class, alzs: new Intl.NumberFormat('en-US').format(bytes.alzs) }
}

const colorTotalAlzs = ({ warehouse, alzsAccount }) => {
    const alzs = parseInt(warehouse) + parseInt(alzsAccount)
    if (alzs <= 100000) return { class: 'colorCemMil', alzs }
    else if (alzs >= 100000 && alzs <= 1000000) return { class: 'colorMilhao', alzs }
    else if (alzs >= 1000000 && alzs <= 10000000) return { class: 'ColorDezMilhao', alzs }
    else if (alzs >= 10000000 && alzs <= 100000000) return { class: 'colorCemMilhao', alzs }
    else if (alzs >= 100000000 && alzs <= 1000000000) return { class: 'colorBilhao', alzs }
    else return { class: 'colorAllAlzs', alzs }

}

