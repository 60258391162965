<template>
  <!-- -->
  <div class="full-page">
    <selectI18n />

    <div class="grid-cardAuth-bsk">
      <div class="row">
        <div class="
            md-layout-item
            md-medium-size-35
            md-small-size-100
            md-xsmall-size-100
            md-size-25
            deft-cardAuth-WH
          ">
          <div class="row">
            <div class="logo-BskAuth"></div>
          </div>
          <div class="row">
            <div class="group-BskInput">
              <div class="form-error" v-if="!$v.user.minLength" role="alert">
                {{ $t('message.Login.idAlertLenght') }}
                {{ $v.user.$params.minLength.min }}
                {{ $t('message.Login.idAlertLenghtFinal') }}
              </div>
              <label for="User" class="hidden"></label>
              <input type="text" id="User" class="BskInput" :class="{
                'input-error': $v.user.$invalid && $v.user.$model !== '',
              }" placeholder="ID Login" v-model.lazy="$v.user.$model" @change="$v.user.$touch()" />
              <div class="iconBsk"><i class="far fa-user"></i></div>
            </div>
            <!-- - -->
            <div class="group-BskInput">
              <div class="form-error" v-if="!$v.password.minLength" role="alert">
                {{ $t('message.Login.passAlertLenght') }}
                {{ $v.password.$params.minLength.min }}
                {{ $t('message.Login.passAlertLenghtFinal') }}
              </div>
              <label for="password" class="hidden"></label>
              <input type="password" id="password" class="BskInput" :class="{
                'input-error':
                  $v.password.$invalid && $v.password.$model !== '',
              }" :placeholder="$t('message.Login.passLast2')" v-model="$v.password.$model"
                @change="$v.password.$touch()" />
              <div class="iconBsk"><i class="far fa-key"></i></div>
            </div>
            <!-- - -->
            <div class="group-BskBestAction">
              <p>
                {{ $t('message.Login.passLast1') }}
                <router-link to="/reqpass">{{
                  $t('message.Login.passLast2')
                }}</router-link>?
              </p>
              <button @click="loginAccount" :disabled="$v.$invalid">
                {{ $t('message.Login.btnEnter') }}
              </button>
            </div>
            <!-- - -->
            <div class="group-BskInput">
              <hr class="bsk-hr" data-content="OU" />
            </div>
            <!-- - -->
            <div class="group-BskGoodAction">
              {{ $t('message.Login.txtNewAccount') }}
              <router-link to="/register">{{
                $t('message.Login.txtNewAccountLink')
              }}</router-link>.
            </div>
          </div>
        </div>
        <div class="
            md-layout-item
            md-medium-size-65
            md-small-size-0
            md-xsmall-size-100
            md-size-75
            deft-cardAuth-WH
            card-BskPrest
          ">
          <div class="txt-BskTitle">{{ $t('message.Login.txtTitle') }}</div>
          <div class="pos-BskDesc">
            <div class="txt-BskDesc">{{ $t('message.Login.txtDesc') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-login">
      <p>{{ $t('message.Login.direitos') }}</p>
    </div>
  </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import selectI18n from '@/components/localeTranslation/Select-I18n.vue'

export default {
  name: 'Login',
  components: { selectI18n },
  data() {
    return {
      user: '',
      password: '',
      response: '',
    }
  },
  validations: {
    user: {
      required,
      minLength: minLength(5),
    },
    password: {
      required,
      minLength: minLength(4),
    },
  },
  methods: {
    ...mapActions(['login']),
    async loginAccount(e) {
      e.preventDefault()
      if (!this.$v.$invalid) {
        this.response = await this.login({
          user: this.user,
          pass: this.password,
        })

        if (this.response === 1) {
          this.$swal({
            title: this.$t('swal.titleLogin'),
            text: this.$t('swal.txtLogin'),
            icon: 'success',
            button: this.$t('swal.btnLogin'),
          }).then(() => {
            setTimeout(() => {
              this.$router.push({
                name: 'painel',
              })
            }, 200)
          })
        } else {
          this.$swal({
            title: this.$t('swal.errorLogin'),
            text: this.$t('swal.errorCredentials'),
            icon: 'error',
            button: this.$t('swal.tryAgain'),
          })
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$error-color: #a94442;

.full-page {
  width: 100vw;
  height: 100vh;
  margin: auto;
  padding: 40px;
  background: url('../assets/img/Auth/bg-BskRpt.png') repeat;
  overflow: hidden;

  p {
    width: 100%;
    text-align: center;
    font-weight: bold;
    color: #7e633b;
  }

  .grid-cardAuth-bsk {
    width: 80vw;
    height: 500px;
    margin: calc(50vh - 300px) auto;
    border: 1px solid #e1dccb;
    animation: pulse-Bskz 5s infinite;

    .card-BskPrest {
      background: #fff url('../assets/img/Auth/card-BskPrest.png') left center;
      background-size: cover;

      .txt-BskTitle {
        font-family: 'BSKTxt', sans-serif;
        font-size: 5vw;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 6px;
        line-height: 1.16;
        color: #fff;
        text-align: left;
        float: left;
        margin-top: 12vh;
        padding-left: 5vw;
      }

      .txt-BskDesc {
        width: 100%;
        font-family: 'BSKTxt', sans-serif;
        font-size: 2vw;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 6px;
        line-height: 1.16;
        color: #fff;
        text-align: left;
        float: left;
        margin-top: 1vh;
        padding-left: 5vw;
      }
    }

    .deft-cardAuth-WH {
      height: 500px;
      background-color: rgb(250, 250, 250, 0.9);
      float: left;
      padding: 50px;

      .logo-BskAuth {
        width: 187px;
        height: 119px;
        margin: auto;
        //background: url('../assets/img2/Auth/Logo-Fury-lib.png');
        background-position-x: -498px;
        background-position-y: 0;
        // background-size: 11em;
        // background-repeat: no-repeat;
      }

      .group-BskInput {
        width: 100%;
        padding: 20px 0 0 0;

        .BskInput {
          width: 100%;
          height: 40px;
          color: #c4b998;
          border: 2px solid #f3f1ea;
          border-radius: 100px;
          padding-left: 40px;

          &:focus {
            color: #c4b998;
            border: 2px solid #7e633b;
          }
        }

        .iconBsk {
          margin: -30px 20px 1px;
        }

        hr.bsk-hr {
          width: 100%;
          line-height: 1em;
          position: relative;
          outline: 0;
          border: 0;
          color: #7e633b;
          text-align: center;
          height: 1.5em;
          opacity: 0.5;

          &:before {
            content: '';
            background: linear-gradient(to right,
                transparent,
                #c4b998,
                transparent);
            position: absolute;
            left: 0;
            top: 50%;
            width: 100%;
            height: 1px;
          }

          &:after {
            content: attr(data-content);
            position: relative;
            display: inline-block;

            padding: 0 0.5em;
            line-height: 1.7em;
            color: #7e633b;
            background-color: #fcfcfa;
          }
        }
      }

      .group-BskBestAction {
        width: 100%;
        padding: 20px 0 0 0;
        margin: auto;
        text-align: center;

        button {
          padding: 5px 5px;
          background: #815500;
          font-family: 'BSKTxt', sans-serif;
          font-size: 1rem;
          font-weight: 100;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: #937341;
          cursor: pointer;
          line-height: 1;
          border: none;
          position: relative;
          z-index: 1;
          box-shadow: 0 0 25px rgba(0, 0, 0, 0.11);
          transition: color 0.2s;
          min-width: 100px;
          max-width: 100%;
          border-radius: 100px;
          right: 0;

          &:before {
            top: 2px;
            left: 2px;
            right: 2px;
            bottom: 2px;
            content: '';
            display: block;
            position: absolute;
            background: #f3f3f3;
            z-index: -1;
            border-radius: 100px;
          }
        }

        p {
          padding-top: 3px;
          padding-left: 4px;
          font-size: 1em;
          left: 0;
        }
      }

      .group-BskGoodAction {
        width: 100%;
        padding: 10px 30px;
        margin: auto;
        text-align: center;
      }
    }
  }
}

.hidden {
  display: none;
}

.input-error {
  border: 1px solid $error-color !important;
}

.form-error {
  font-size: 14px;
  color: $error-color;
}

button:disabled {
  background: #c7c5c4 !important;
  color: #c4bdae !important;
  cursor: unset !important;
}

.footer-login {
  width: 100%;
  display: flex;
}

@keyframes pulse-Bskz {
  0% {
    -webkit-box-shadow: 0 10px 20px -18px rgb(126, 99, 59, 1);
    -moz-box-shadow: 0 10px 20px -18px rgb(126, 99, 59, 1);
    /* Ty Neo s2 */
    box-shadow: 0 10px 20px -18px rgb(126, 99, 59, 1);
    opacity: 0.9;
  }

  50% {
    border: 1px solid #fff;
    -webkit-box-shadow: 0 10px 30px -10px rgb(208, 168, 92, 0.5);
    -moz-box-shadow: 0 10px 30px -10px rgb(208, 168, 92, 0.5);
    /* Ty Neo s2 */
    box-shadow: 0 10px 30px -10px rgb(208, 168, 92, 0.5);
    opacity: 1;
  }

  100% {
    -webkit-box-shadow: 0 10px 20px -18px rgb(126, 99, 59, 1);
    -moz-box-shadow: 0 10px 20px -18px rgb(126, 99, 59, 1);
    /* Ty Neo s2 */
    box-shadow: 0 10px 20px -18px rgb(126, 99, 59, 1);
    opacity: 0.9;
  }
}

@media screen and (max-width: 960px) {
  div.card-BskPrest {
    display: none;
  }
}
</style>
