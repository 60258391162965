import { parseISO, /*getDate getYear,*/ getMonth } from 'date-fns'

export default {
  month: {
    0: 'Janeiro',
    1: 'Fevereiro',
    2: 'Março',
    3: 'Abril',
    4: 'Maio',
    5: 'Junho',
    6: 'Julho',
    7: 'Agosto',
    8: 'Setembro',
    9: 'Outubro',
    10: 'Novembro',
    11: 'Dezembro',
  },
  getMes(index) {
    let sameVal = []
    let t = null
    for (let i = 0; i < index.length; i++) {
      if (t == null) {
        t = getMonth(parseISO(index[i].date))
        sameVal[i] = index[i].date
      } else if (t != getMonth(parseISO(index[i].date))) {
        t = null
        sameVal[i] = index[i].date
      }
      if (sameVal[i] != undefined) {
        return 'timeline-item period'
      } else {
        return 'timeline-item '
      }
    }
  },
}
