<template>
  <div class="content">
    <div class="md-layout">
      <div class="
          md-layout-item md-medium-size-100 md-xsmall-size-100
          sm-size-100
          md-size-30
        ">
        <bsk-stats-card data-background-color="orange">
          <template slot="header">
            <i class="fad fa-money-check-alt"></i>
          </template>

          <template slot="content-title">
            <p class="category">{{ $t('message.Shop.reportCommercial') }}</p>
            <h3 class="title">{{ this.userAccount.ID }}</h3>
          </template>

          <template slot="content">
            <report-commercial></report-commercial>
          </template>

          <template slot="footer">
            <footer-shop classCss="stats" mdIcon="sync" :textProp="$t('message.Shop.txtDados')" :check="true" />
          </template>
        </bsk-stats-card>
        <!-- - -->
        <bsk-stats-card data-background-color="orange">
          <template slot="header">
            <i class="fad fa-tags"></i>
          </template>

          <template slot="content-title">
            <p class="category">
              {{ $t('message.Shop.ChooseCategoryAndOther[0]') }}
            </p>
            <h3 class="title">
              {{ $t('message.Shop.ChooseCategoryAndOther[1]') }} {{ appName }}
            </h3>
          </template>

          <template slot="content">
            <category-shop></category-shop>
          </template>

          <template slot="footer">
            <footer-shop classCss="stats" mdIcon="info" :textProp="$t('message.Shop.buyItem')" :check="true" />
          </template>
        </bsk-stats-card>
      </div>
      <!-- -->
      <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-70">
        <bsk-stats-card data-background-color="orange">
          <template slot="header">
            <i class="fad fa-bags-shopping"></i>
          </template>

          <template slot="content-title">
            <p class="category">
              {{ $t('message.Shop.security') }}
            </p>
            <!-- <h3 class="title">{{ $route.name }}</h3> -->
            <h3 class="title">
              {{ $t('message.Shop.ChooseCategoryAndOther[1]') }} {{ appName }}
            </h3>
          </template>

          <template slot="content">
            <router-view :key="$route.path"></router-view>
          </template>

          <template slot="footer">
            <div class="stats">
              <md-icon>copyright</md-icon>
              {{ $t('message.Shop.footer[0]')
              }}<font color="green">{{ $t('message.Shop.footer[1]') }}</font>
              {{ $t('message.Shop.footer[2]') }}
            </div>
          </template>
        </bsk-stats-card>
      </div>
    </div>
  </div>
</template>

<script>
import { BskStatsCard } from '@/components'
import ReportCommercial from '@/components/ReportCommercial'
import CategoryShop from '@/components/CategoryShop.vue'
import FooterShop from '@/components/Footers/Footer'
import { mapGetters, mapActions } from 'vuex'
import { CabalName } from '../cabalName'

export default {
  data() {
    return { appName: CabalName.name }
  },
  components: {
    BskStatsCard,
    ReportCommercial,
    CategoryShop,
    FooterShop,
  },
  computed: {
    ...mapGetters(['userAccount']),
  },
  methods: {
    ...mapActions(['actionMoneyType'])
  },
  async created() {
    await this.actionMoneyType();
    // try {
    //   const response = await axios.getMoney()
    //   this.getMoneyType = response.data.getMoney
    // } catch (error) {
    //   console.log(error.response)
    //   this.$swal(
    //     this.$t('swal.typeMessage[0]'),
    //     error.response.msg,
    //     'error'
    //   )
    // }
  },
  beforeUpdate() {
    document
      .querySelector('.router-link-active')
      .classList.add('nav-item', 'active')
    document.body.scrollTop = document.documentElement.scrollTop = 0
  },
}
</script>
<style scoped lang="scss">
@import '../assets/scss/md/_shop.scss';
</style>
