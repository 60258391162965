/* eslint-disable no-unused-vars */
import axios from '../services/servicesAxios'
import { swal } from 'vue-swal'
import i18n from '../locale/'

const state = {
  menuShop: [],
  moneyType: [],
}

const getters = {
  gettersMoneyType: (state) => state.moneyType,
}

const actions = {
  async actionMoneyType({ commit }) {
    try {
      const response = await axios.getMoney()
      const item = response.data.getMoney.map((value) => {
        if (value.CatName.toLowerCase() === 'Cash'.toLowerCase()) {
          return { ...value, typeCash: this.getters.cashInAccountGetter }
        }
        if (value.CatName.toLowerCase() === 'Cassino'.toLowerCase())
          return { ...value, typeCash: this.getters.gettersCashCassino }
      })
      commit('moneyTypeMut', { item: item })
    } catch (error) {
      console.error(error)
      swal(i18n.t('swal.typeMessage[1]'), error.response.msg, 'error')
    }
  },

  // async iteratorHandlerTypeMoney(commit, total) {

  //   state.moneyType.forEach((value) => {
  //     value.CatName === 'Cash'.toLowerCase()
  //       ? commit('this.mutation.cashInAccountMut', { cash: total })
  //       : commit('this.mutation.cashCassionInAccountMut', { cash: total })
  //   })
  // },
}

const mutations = {
  fixedMenuShop(state, { menuShop }) {
    state.menuShop = menuShop
  },
  moneyTypeMut(state, { item }) {
    state.moneyType = [...item]
  },
  mutationsUpdateCashAndCassino(state, { cash, typeCash }) {
    state.moneyType = state.moneyType.map((value, index) => {
      return value.typeCash.typeName === typeCash
        ? {
            CatID: value.CatID,
            CatName: value.CatName,
            typeCash: { cash: cash, typeName: typeCash },
          }
        : {
            CatID: value.CatID,
            CatName: value.CatName,
            typeCash: {
              cash: value.typeCash.cash,
              typeName: value.typeCash.typeName,
            },
          }
    })
    // state.moneyType.foreach((value, index) => {
    //   if (value.typeCash.typeName === typeCash) {
    //     let { CatID, CatName } = value
    //     state.moneyType[index].typeCash.cash = cash
    //     //return { CatID, CatName, typeCash: { cash: cash, typeName: typeCash } }
    //   }
    // })
  },
}

export default {
  //namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
