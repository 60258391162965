/* eslint-disable */
import i18n from "../locale/"
const style = style => {
  var newStyle;
  return (newStyle = (style / Math.pow(2, 0)) & (Math.pow(2, 3) - 1));
  //console.log(newStyle);
};

let nomeClasse = sty => {
  style(sty);
  return style(sty) === 1
    ? "Warrior/Gladiator"
    : style(sty) === 2
      ? "Blade/"
      : style(sty) === 3
        ? "Wizard/Dark Mage"
        : style(sty) === 4
          ? "Forcer Archer/Force Gunner"
          : style(sty) === 5
            ? "Force Shielder"
            : "Force Blader";
};

const imgClass = Style => {
  return style(Style) === 1
    ? i18n.t('char.class[0]')
    : style(Style) === 2
      ? i18n.t('char.class[1]')
      : style(Style) === 3
        ? i18n.t('char.class[2]')
        : style(Style) === 4
          ? i18n.t('char.class[3]')
          : style(Style) === 5
            ? i18n.t('char.class[4]')
            : i18n.t('char.class[5]');
};

const nation = nation => {
  return nation === 1 ? "Capella" : nation === 2 ? "Procyon" : i18n.t('char.nation');
};
const selectedChar = (event, charList) => {
  return charList.filter(char => {
    if (char.CharIdxCharacter == event.target.value) {
      return char;
    } else {
      if (event.target.value < 0) {
        this.$swal("Oops", "Erro ao selecionar personagem!", "error");
      }
    }
  });
};

const selectCharInTransf = (event, charList) => {
  return charList.filter(char => {
    if (char.CharacterIdx == event.target.value) {
      return char;
    } else {
      if (event.target.value < 0) {
        this.$swal("Oops", "Erro ao selecionar personagem!", "error");
      }
    }
  });
};

function genNumAndLetter(cont) {
  let randomNum = Math.round(Math.random() * cont);
  return randomNum;
}

let geraCodigo = () => {
  let letra = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let seq = "";
  for (var i = 0; i < 5; i++) {
    seq += letra.charAt(Math.floor(Math.random() * letra.length));
  }
  return seq;
};

export default {
  style,
  selectedChar,
  nomeClasse,
  geraCodigo,
  imgClass,
  selectCharInTransf,
  nation
};
