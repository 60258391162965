/* eslint-disable prettier/prettier */
<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
        <stats-card data-background-color="blue">
          <template slot="header">
            <i class="fad fa-calendar-star fa-lg"></i>
          </template>

          <template slot="content">
            <p class="category">
              {{ $t('message.Dashboard.txtPeriodoPremium') }}
            </p>
            <h3 class="title">
              {{ this.userAccount.ExpireDate }}
              {{ $t('message.Dashboard.txtCountPremiumDays') }}
            </h3>
          </template>

          <template slot="footer">
            <footers classCss="stats" mdIcon="date_range" :textProp="$t('message.Dashboard.txtPropPremium')"
              :check="false"></footers>
          </template>
        </stats-card>
      </div>
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
        <stats-card data-background-color="purple">
          <template slot="header">
            <i class="fas fa-chess"></i>
          </template>

          <template slot="content">
            <p class="category">{{ $t('message.Dashboard.txtEventsTitle') }}</p>
            <h3 class="title">
              <font class="purple">{{ this.packUserAndEvents.events }}</font>
              {{ $t('message.Dashboard.txtCountEvents') }}
            </h3>
          </template>

          <template slot="footer">
            <footers classCss="stats" mdIcon="extension" :textProp="$t('message.Dashboard.txtPropEvents')" :check="false">
            </footers>
          </template>
        </stats-card>
      </div>
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
        <stats-card data-background-color="green">
          <template slot="header">
            <md-icon>groups</md-icon>
          </template>

          <template slot="content">
            <p class="category">
              {{ $t('message.Dashboard.txtPlayerOnTitle') }}
            </p>
            <h3 class="title">
              {{ this.packUserAndEvents.user_online }}
              {{ $t('message.Dashboard.txtCountPlayerOn') }}
            </h3>
          </template>

          <template slot="footer">
            <footers classCss="stats" mdIcon="language" :textProp="$t('message.Dashboard.txtPropPlayerOn')"
              :check="false"></footers>
          </template>
        </stats-card>
      </div>
      <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
        <stats-card data-background-color="orange">
          <template slot="header">
            <i class="fal fa-badge-dollar"></i>
          </template>

          <template slot="content">
            <p class="category">{{ $t('message.Dashboard.txtAlzs') }}</p>
            <h3 class="title" :class="colorHousesAlzs({ warehouse: alzsLocalWarehouse, alzsAccount: alzsAccount }).color">
              {{
                colorHousesAlzs({ warehouse: alzsLocalWarehouse, alzsAccount: alzsAccount }).alzs
              }}
            </h3>
          </template>

          <template slot="footer">
            <footers classCss="stats" mdIcon="update" :textProp="$t('message.Dashboard.penultimateAlzs')" :check="false">
            </footers>
          </template>
        </stats-card>
      </div>
      <!-- <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-60"
      >
        <div class="support_nav">
          <ul class="group" id="category">
            <li>
              <a
                href="/#/painel/Faqs/cat/1"
                data-category="1"
                style="margin-right:10px;"
              >
                <div class="thumb">
                  <img
                    src="https://naimg.playthisgame.com/br/playthisgame_new/images/support/ico_player_h.png"
                  />
                </div>
                <div class="title" data-category="1">
                  {{ $t('message.Dashboard.reportPlayer') }}
                </div>
              </a>
            </li>
            <li>
              <a
                href="#"
                data-category="2"
                style="margin-left:10px;margin-right:10px;"
              >
                <div class="thumb">
                  <img
                    src="https://naimg.playthisgame.com/br/playthisgame_new/images/support/ico_system_h.png"
                  />
                </div>
                <div class="title" data-category="2">
                  {{ $t('message.Dashboard.system') }}
                </div>
              </a>
            </li>
            <li>
              <a href="#" data-category="3" style="margin-left:10px;">
                <div class="thumb">
                  <img
                    src="https://naimg.playthisgame.com/br/playthisgame_new/images/support/ico_character_h.png"
                  />
                </div>
                <div class="title" data-category="3">
                  {{ $t('message.Dashboard.character') }}
                </div>
              </a>
            </li>
            <li>
              <a href="#" data-category="4" style="margin-right:10px;">
                <div class="thumb">
                  <img
                    src="https://naimg.playthisgame.com/br/playthisgame_new/images/support/ico_chat_h.png"
                  />
                </div>
                <div class="title" data-category="4">
                  {{ $t('message.Dashboard.chatAndOthers') }}
                </div>
              </a>
            </li>
            <li>
              <a
                href="#"
                data-category="5"
                style="margin-left:10px;margin-right:10px;"
              >
                <div class="thumb">
                  <img
                    src="https://naimg.playthisgame.com/br/playthisgame_new/images/support/ico_account_h.png"
                  />
                </div>
                <div class="title" data-category="5">
                  {{ $t('message.Dashboard.account') }}
                </div>
              </a>
            </li>
            <li>
              <a href="#" data-category="6" style="margin-left:10px;">
                <div class="thumb">
                  <img
                    src="https://naimg.playthisgame.com/br/playthisgame_new/images/support/ico_billing_h.png"
                  />
                </div>
                <div class="title" data-category="6">
                  {{ $t('message.Dashboard.chargeAndOther[0]') }} &amp;
                  {{ $t('message.Dashboard.chargeAndOther[1]') }}
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div> -->
      <!-- <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-40"
      >
        <stats-card data-background-color="warning">
          <template slot="header">
            <i class="fad fa-fire-extinguisher"></i>
          </template>

          <template slot="content">
            <p class="category">{{ $t('message.Dashboard.topicsRecent') }}</p>
            <h3 class="title">{{ $t('message.Dashboard.mostViewFaqs') }}</h3>
          </template>

          <template slot="footer">
            <ul id="trending">
              <li>
                <a href="#" class="title" data-idx="290" data-cat="5">{{
                  $t('message.Dashboard.temporaryBlock[0]')
                }}</a>
                <p>
                  {{ $t('message.Dashboard.temporaryBlock[1]') }}
                </p>
              </li>
              <li>
                <a href="#" class="title" data-idx="289" data-cat="2">{{
                  $t('message.Dashboard.deleteCharacter[0]')
                }}</a>
                <p>
                  {{ $t('message.Dashboard.deleteCharacter[1]') }}
                </p>
              </li>
              <li>
                <a href="#" class="title" data-idx="283" data-cat="2">{{
                  $t('message.Dashboard.items[0]')
                }}</a>
                <p>
                  {{ $t('message.Dashboard.items[1]') }}
                </p>
              </li>
              <li>
                <a href="#" class="title" data-idx="281" data-cat="6">{{
                  $t('message.Dashboard.purchaseErrorItems[0]')
                }}</a>
                <p>
                  {{ $t('message.Dashboard.purchaseErrorItems[1]') }}
                </p>
              </li>
              <li>
                <a href="#" class="title" data-idx="280" data-cat="2">{{
                  $t('message.Dashboard.passwordInventory[0]')
                }}</a>
                <p>
                  {{ $t('message.Dashboard.passwordInventory[1]') }}
                </p>
              </li>
              <li>
                <a href="#" class="title" data-idx="279" data-cat="2">{{
                  $t('message.Dashboard.missionDelete[0]')
                }}</a>
                <p>
                  {{ $t('message.Dashboard.missionDelete[1]') }}
                </p>
              </li>
              <li>
                <a href="#" class="title" data-idx="278" data-cat="2">{{
                  $t('message.Dashboard.questionsGames[0]')
                }}</a>
                <p>
                  {{ $t('message.Dashboard.questionsGames[1]') }}
                </p>
              </li>
              <li>
                <a href="#" class="title" data-idx="59" data-cat="5">{{
                  $t('message.Dashboard.subPasswords[0]')
                }}</a>
                <p>
                  {{ $t('message.Dashboard.subPasswords[1]') }}
                </p>
              </li>
            </ul>
          </template>
        </stats-card>
      </div> -->
    </div>
  </div>
</template>

<script>
import { StatsCard } from '@/components'
import axios from '../services/servicesAxios'
import { mapGetters, mapActions } from 'vuex'
import footers from '../components/Footers/Footer'
import { colorHousesAlzs } from "../util/ColorHousesAlzs";
export default {
  name: 'dashboard',
  components: {
    StatsCard,
    footers,
  },
  data() {
    return {
      packUserAndEvents: [],
      check: '',
      objectPay: {},
      alzsLocalWarehouse: 0,
    }
  },
  computed: {
    ...mapGetters(['userAccount', 'AlzsTotalChar', 'AlzsWarehouse']),
    alzsAccount() {
      let alzsAcc = 0
      for (let i = 0; i < this.AlzsTotalChar.length; i++) {
        let alz = parseInt(this.AlzsTotalChar[i].Alz)
        alzsAcc = parseInt(alzsAcc) + parseInt(alz)
      }
      return alzsAcc
    },


  },
  methods: {
    ...mapActions(['charactersActions', 'cashInAccountAction', 'actionMoneyType']),
    logout() {
      localStorage.removeItem('user-data')
      this.$router.push({
        name: 'Login',
      })
    },
    colorHousesAlzs() {
      return colorHousesAlzs({ warehouse: this.alzsLocalWarehouse, alzsAccount: this.alzsAccount })
    }
  },
  async mounted() {
    this.AlzsWarehouse === null || this.AlzsWarehouse === undefined
      ? (this.alzsLocalWarehouse = 0)
      : (this.alzsLocalWarehouse = this.AlzsWarehouse)
    axios
      .searchEventsAndAlzs(this.userAccount.UserNum)
      .then(value => {
        if (value.status === 200) {
          this.packUserAndEvents = value.data.packUserEvents[0]

          //parseInt((this.packUserAndEvents.alzs_account = 90000000000));

          //envia para Vuex
          this.$store.commit('eventActAndAlzs', this.packUserAndEvents)
        }
      })
      .catch(err => {
        console.log(err)
        if (err.response !== undefined) {
          if (err.response.status === 403 || err.response.status === 401)
            this.$swal(
              this.$t('swal.typeMessage[1]'),
              err.response.data?.Mensagem,
              'warning'
            )
          else this.$swal(`Error`, err.response.data.msg, 'error')
          //
        } else this.$swal(`Error`, this.$t('swal.error'), 'error')
      })

    try {
      await this.cashInAccountAction(this.userAccount.UserNum)
      await this.actionMoneyType()
    } catch (err) {
      console.log(err)
      if (err?.code !== undefined) {
        this.$swal(this.$t(`swal.typeMessage[0]`), err?.msg, 'warning').then(
          () => {
            axios.logout()
          }
        )
      } else if (err.response !== undefined) {
        this.$swal(`Error`, err.response.data.msg, 'error')
      } else {
        this.$swal(`Error`, this.$t('swal.error'), 'error')
      }
    }
  },

  created() {
    if (
      this.$route.query.PayerID != undefined &&
      this.$route.query.paymentId != undefined &&
      this.$route.query.token != undefined
    ) {
      this.objectPay = this.$route.query

      if (this.objectPay.length != 0) {
        this.routerDomValid = true
        if (this.routerDomValid) {
          setTimeout(() => {
            this.$router.push({
              name: 'paypal',
              params: {
                pay: 1,
                token: this.objectPay.token,
                PayerID: this.objectPay.PayerID,
                paymentId: this.objectPay.paymentId,
              },
            })
          }, 500)
        }
      }
    }

    try {
      let init = this.userAccount.PlayTime * 60
      let hours = Math.floor(init / 3600)
      let minutes = Math.floor((init / 60) % 60)
      let seconds = init % 60
      this.$store.commit('hoursGamming', { hours, minutes, seconds })

      //Busca Os Personagens
      if (this.userAccount.UserNum > 0) {
        this.check = this.charactersActions(this.userAccount.UserNum)
        if (this.check === 0)
          this.$swal(
            this.$t('swal.typeMessage[0]'),
            this.$t('swal.not_found_char'),
            'warning'
          )
        // else if (this.check === -1)
        //   this.$swal(
        //     this.$t('swal.typeMessage[1]'),
        //     this.$t('swal.error_character_in_account'),
        //     'error'
        //   )
      } else {
        this.$swal(
          this.$t('swal.typeMessage[1]'),
          this.$t('swal.error_character_in_account'),
          'error'
        ).then(() => {
          // setTimeout(() => {
          //   this.logout()
          // }, 200)
        })
      }
    } catch (err) {
      console.log(err)
      if (err.response !== undefined) {
        if (err.response.status === 403 || err.response.status === 401)
          this.$swal(
            this.$t('swal.typeMessage[1]'),
            err.response.data?.Mensagem,
            'warning'
          )
        else this.$swal(`Error`, err.response.data.msg, 'error')
        //
      } else this.$swal(`Error`, this.$t('swal.error'), 'error')
    }
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/color.scss';

.group {
  zoom: 1;
}

.support_nav_top_title>header {
  padding: 20px 0;
  font-size: 24px;
  font-family: 'Roboto', serif;
  color: #000;
}

.support_nav {
  max-width: 992px;
  position: relative;
  margin: 0 auto 35px;

  ul {
    position: relative;
    padding: 0;
    list-style: none;

    li {
      width: 33%;
      float: left;
      display: inline;
      position: relative;
      margin-bottom: 20px;

      a {
        min-height: 200px;
        display: block;
        margin-right: 0px;
        padding: 21px 20px 0px 20px;
        position: relative;
        background: #e6e6e6;
        text-align: center;
        text-decoration: none;
        border-bottom: 1px solid #cdcdcd;
        border-radius: 5px;
      }
    }
  }

  .title {
    top: 8px;
    margin-bottom: 25px;
    font-weight: 600;
    font-size: 14px;
    font-family: 'Roboto', serif;
    color: #000;
  }

  .thumb {
    width: 88px;
    height: 88px;
    margin: 0 auto;
    margin-bottom: 11px;
    margin-top: 11px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #000;

    img {
      display: block;
      max-width: 100%;
      padding: 15px;
      -webkit-filter: brightness(0%);
      filter: brightness(0%);

      :hover {
        background: #000;
        -webkit-filter: brightness(100%);
        filter: brightness(100%);
      }
    }
  }
}

.support_nav>ul>li>a:hover .thumb>img {
  background: #000;
  -webkit-filter: brightness(100%);
  filter: brightness(100%);
}

/* Koreanus rives, Its Brazil! */
#LeftNavIco li.on {
  background-color: #000;
}

#LeftNavIco li.on>a {
  color: #fff;
}

@media screen and (max-width: 900px) {
  .support_nav>ul>li {
    width: 50%;
  }

  .support_nav>ul>li>a {
    margin: 10px !important;
  }
}
</style>
