import api from '../services/servicesAxios'
import { swal } from 'vue-swal'
import i18n from '../locale/'
import VueRouter from 'vue-router'
let router = new VueRouter()

const state = () => ({
  periodPremium: [],
  eventActAndAlzsAcc: [],
  peopleConnected: [],
  characters: [],
  //distpts: [],
  alzsTotal: '',
  userChangeToEmail: [],
  checkInputEmpty: '',
  cellphone: '',
  dataPeople: [],
  warzone: [],
})
const getters = {
  AlzsTotalChar: (state) => state.characters,
  AlzsWarehouse: (state) => state.eventActAndAlzsAcc.alzs_account,
  characters: (state) => state.characters,
  charactersByIdx: (state) => (idx) =>
    state.characters.find((value) => value.CharacterIdx === parseInt(idx)),
  distpts: (state) => state.characters,
  checkValueInput: (state) => state.checkInputEmpty,
  warzone: (state) => state.warzone,

  // gettersListProdsByIndex: state => id =>
  //   state.listProds.find(value => value.ListID === parseInt(id)),
}

const actions = {
  userActionChangeToEmail({ commit }, dadaUser) {
    return api
      .changeToEmail({
        ID: dadaUser.ID,
        newEmail: dadaUser.mail.value,
        email: dadaUser.oldMail,
      })
      .then((response) => {
        if (response.status === 200) {
          commit('mutChangeToEmail', { mail: response.data, check: true })
          return 1
        } else return 0
      })
      .catch((err) => {
        throw new Error(`error in connect Api: ${err}`)
      })
  },
  insertAndChangeCell({ commit }, cell) {
    return api
      .insertAndChangeCell(cell)
      .then((response) => {
        if (response.status === 200) {
          commit('mutInsertAndChangeCell', cell)
          return 1
        } else return 0
      })
      .catch((err) => {
        throw new Error(`error in connect Api: ${err}`)
      })
  },
  DataPeople({ commit }, dataPeole) {
    return api
      .changeDataPeople(dataPeole)
      .then((response) => {
        if (response.status === 200) {
          commit('mutDataPeople', dataPeole)
          return 1
        } else return 0
      })
      .catch((err) => {
        throw new Error(`error in connect Api: ${err}`)
      })
  },
  charactersActions({ commit }, char) {
    return api
      .searchAllCharacter(char)
      .then((response) => {
        if (response.status === 200 && response.data.code === 1) {
          commit('characters', response.data)
          return 1
        } else {
          return 0
        }
      })
      .catch((err) => {
        if (err.response !== undefined) {
          if (err.response.status === 403 || err.response.status === 401)
            swal(
              i18n.t('swal.typeMessage[0]'),
              err.response.data.Mensagem,
              'warning'
            ).then(() => {
              setTimeout(async () => await this.dispatch(`logout`), 1000)
            })
          else
            swal(
              i18n.t('swal.typeMessage[1]'),
              err.response.data.msg,
              'error'
            ).then(() => {
              setTimeout(async () => await this.dispatch(`logout`), 1000)
            })
        } else
          swal(`Error`, i18n.t('swal.error'), 'error').then(() => {
            setTimeout(async () => await this.dispatch(`logout`), 1000)
          })
      })
  },
  distptsCharactersActions({ commit }, char) {
    return api
      .charDistribute(char)
      .then((response) => {
        if (response.status === 200 && response.data.code === 1) {
          commit('distpts', char)
          return { code: 1, msg: response.data.mensagem }
        } else if (response.status === 200 && response.data.code === -1) {
          return { code: -1, msg: response.data.mensagem }
        } else {
          return { code: 0 }
        }
      })
      .catch(() => {
        return -1
      })
  },
  deleteCharacterIdx({ commit }, characterIdx) {
    return api
      .deleteCharacter(characterIdx)
      .then((response) => {
        if (response.status === 200 && response.data.code === 1) {
          commit('deleteCharacter', characterIdx)
          return { code: 1, msg: response.data.msg }
        }
      })
      .catch((err) => {
        if (err.response.status === 404 && err.response.data.code === -1)
          return { code: -1, msg: err.response.data.msg }
        else if (err.response.status === 404 && err.response.data.code === 0)
          return { code: 0, msg: err.response.data.msg }
        else if (err.response.status === 404 && err.response.data.code === 2526)
          return { code: err.response.data.code, msg: err.response.data.msg }
        else return { code: -2, msg: err.response.data.msg }
      })
  },
  warzoneSystem({ commit }, payload) {
    return api
      .warzone(payload)
      .then((response) => {
        if (response.status === 200) {
          commit('warzoneSystemMut', response.data)
          return { code: 1, msg: response.data.msg }
        } else return { code: -1, msg: response.data.msg }
      })
      .catch(() => {
        return -1
      })
  },
  logout() {
    localStorage.removeItem('user-data')
    localStorage.removeItem('vuex')
    localStorage.removeItem('_secure__ls__metadata')
    router.go(0)
  },
}

const mutations = {
  warzoneSystemMut(state, { warzone }) {
    state.warzone = warzone
  },
  periodPremium(state, { periodPremium }) {
    state.periodPremium = periodPremium
  },
  eventActAndAlzs(state, payload) {
    state.eventActAndAlzsAcc = payload
  },
  peopleConnected() {},
  characters(state, { characters }) {
    state.characters = characters
  },
  distpts(state, distpts) {
    state.characters = state.characters.filter((x) => {
      if (x.CharacterIdx == distpts.CharacterIdx) {
        x.PNT = x.PNT - (distpts.DEX + distpts.INT + distpts.STR)
        x.DEX += distpts.DEX
        x.INT += distpts.INT
        x.STR += distpts.STR
      }
      return x
    })
  },
  deleteCharacter(state, charIdx) {
    state.characters = state.characters.filter((x) => {
      return x.CharacterIdx != charIdx.CharacterIdx
    })
  },
  mutChangeToEmail(state, { mail, check }) {
    state.checkInputEmpty = check
    state.userChangeToEmail = mail
  },
  checkInputEmpty(state, check) {
    state.checkInputEmpty = check
  },
  mutInsertAndChangeCell(state, cell) {
    state.cellphone = cell
  },
  mutDataPeople(state, dataPeople) {
    state.dataPeople = dataPeople
  },
}

export default {
  //namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
