<template>
  <div class="md-layout">
    <div class="gTransf">
      <div class="bTransf-Title HdrAzEsv">
        {{ $t('message.EnvChar.chooseChar[0]') }}
      </div>
      <div class="cTransf">
        <loading :active.sync="visible" :can-cancel="true"></loading>

        <p>
          {{ $t('message.EnvChar.chooseChar[1]') }}
        </p>
        <select
          name="personagem"
          id="personagem"
          :placeholder="$t('message.EnvChar.chooseChar[0]')"
          v-on:change="selected($event)"
          required
        >
          <option value="" selected disabled>{{
            $t('message.EnvChar.chooseChar[0]')
          }}</option>
          <option
            v-for="char in this.charList"
            :key="char.CharIdxCharacter"
            :value="char.CharIdxCharacter"
          >
            {{ char.Name }} - {{ char.LEV }}
          </option>
        </select>

        <!-- Joel ~ Daqui pra baixo é da Condicional -->
        <hr />
        <div class="gPersSelect">
          <div class="md-layout">
            <div
              class="md-layout-item md-medium-size-100 md-xsmall-size-100 sm-size-100 md-size-40"
            >
              <div class="gCharacterSelected">
                <div v-if="this.selectedChar" class="classIcon">
                  <div :class="this.divClass"></div>
                </div>
                <div v-if="this.selectedChar" class="className">
                  {{ this.nomeClasse }}
                </div>
                <div v-if="this.selectedChar" class="PersoName">
                  {{ this.arrayChar[0].Name }}
                </div>
                <div v-if="this.selectedChar" class="PersoLv">
                  {{ this.arrayChar[0].LEV }}
                </div>
              </div>
            </div>
            <info-personagem />
          </div>
        </div>
        <button
          v-if="this.selectedChar"
          type="submit"
          @click.prevent="chooseChar"
        >
          <i class="fas fa-cogs"></i> {{ $t('message.EnvChar.gerarBtn') }}
        </button>
        <!-- Abaixo é a box do Token com Temporizador. -->
        <div v-if="generatorCode" class="gTokenPers">
          <div class="md-layout">
            <div
              class="md-layout-item md-medium-size-100 md-xsmall-size-100 sm-size-100 md-size-100"
            >
              <p class="Token" id="TokenPERSO">{{ codigo }}</p>
              <!-- JS que copia Texto da ID -->
              <p>
                <countdown :time="24 * 60 * 60 * 1000">
                  <template :class="this.chooseClass[1]" slot-scope="props" 
                    >{{ props.hours }}:{{ props.minutes }}:{{
                      props.seconds
                    }}</template
                  >
                </countdown>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import infoPersonagem from '../components/InfoTransferPers'
import { mapGetters } from 'vuex'
import axios from '../services/servicesAxios'
import char from '../services/char'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
export default {
  name: 'Enviar',
  components: {
    infoPersonagem,
    Loading,
  },
  data() {
    return {
      charList: {},
      arrayChar: {},
      generatorCode: false,
      selectedChar: false,
      codigo: '',
      visible: false,
    }
  },
  computed: {
    ...mapGetters(['userAccount']),
    divClass() {
      return char.imgClass(char.style(this.arrayChar[0].Style))
    },
    nomeClasse() {
      return char.nomeClasse(this.arrayChar[0].Style)
    },
  },
  methods: {
    selected(event) {
      this.arrayChar = char.selectedChar(event, this.charList)
      if (this.arrayChar[0].length != 0) {
        this.generatorCode = false
        this.selectedChar = true
      }
    },
    chooseChar() {
      this.codigo = char.geraCodigo()
      if (this.arrayChar.length != 0) {
        this.visible = true
        axios
          .transferChar({
            CharacterIdx: this.arrayChar[0].CharIdxCharacter,
            Code: this.codigo,
          })
          .then(value => {
            if (value.status == 200 && value.data.code == 1) {
              this.generatorCode = true
              setTimeout(() => {
                this.$swal(
                  this.$t('swal.typeMessage[2]'),
                  value.data.msg,
                  'success'
                )
              }, 500)
              this.visible = false
            } else if (value.status == undefined) {
              setTimeout(() => {
                this.$swal(
                  this.$t('swal.typeMessage[1]'),
                  this.$t('swal.erroTransferChar'),
                  'error'
                )
              }, 500)
              this.visible = false
            } else if (value.status == 200 && value.data.code == 0) {
              setTimeout(() => {
                this.$swal(
                  this.$t('swal.typeMessage[1]'),
                  value.data.msg,
                  'error'
                )
              }, 500)
              this.visible = false
            } else {
              setTimeout(() => {
                this.$swal(
                  this.$t('swal.typeMessage[1]'),
                  this.$t('swal.erroTransferChar'),
                  'error'
                )
              }, 500)
              this.visible = false
            }
          })
          .catch(() => {
            this.$swal(
              this.$t('swal.typeMessage[1]'),
              this.$t('swal.errorTransChar'),
              'error'
            )
          })
      }
    },
  },
  mounted() {
    if (this.userAccount.UserNum > 0 || this.userAccount.UserNum != undefined) {
      axios
        .charNotGuildAndAlreadyTransfer(this.userAccount.UserNum)
        .then(value => {
          if (value.status == 200 && value.data.code == 1) {
            this.charList = value.data.charGuildTransfer
          } else if (value.status == undefined) {
            this.$swal(
              this.$t('swal.typeMessage[1]'),
              this.$t('swal.errorChar[0]'),
              'error'
            )
          } else {
            this.$swal(
              this.$t('swal.typeMessage[1]'),
              this.$t('swal.errorChar[1]'),
              'error'
            )
          }
        })
        .catch(() => {
          this.$swal(
            this.$t('swal.typeMessage[1]'),
            this.$t('swal.errorChar[0]'),
            'error'
          )
          //throw new Error("error Connect in Api:", err);
        })
    } else {
      this.$swal(
        this.$t('swal.typeMessage[1]'),
        this.$t('swal.graveError'),
        'warning'
      ).then(() => {
        axios.logout()
      })
    }
  },
}
</script>
<style scoped lang="scss">
@import '@/assets/scss/md/_EnvChar.scss';
</style>
