<template>
  <footer class="footer">
    <div class="container text-center">
      <nav>
        <ul>
          <li>
            <a href="#/rules">{{ $t('message.ContentFooter.LinkRules') }}</a>
          </li>
          <li>
            <a href="#/politics">{{
              $t('message.ContentFooter.LinkPolitics')
            }}</a>
          </li>
          <li>
            <a>{{ $t('message.ContentFooter.LinkSupp') }}</a>
          </li>
          <li>
            <a href="#/developers">{{
              $t('message.ContentFooter.LinkDevs')
            }}</a>
          </li>
        </ul>
      </nav>
      <div class="copyright text-center">
        <p class="bskNode"><i class="fab fa-html5 fa-2x"></i></p>
        <p class="bskNode"><i class="fab fa-css3-alt fa-2x"></i></p>
        <p class="bskNode"><i class="fab fa-node-js fa-2x"></i></p>
        <a href="#" target="_blank">APOENA</a> -
        {{ new Date().getFullYear() }}
      </div>
    </div>
  </footer>
</template>
<script>
import { CabalName } from '../../cabalName'
export default {
  data() {
    return { appName: CabalName.name }
  },
}
</script>
<style scoped>
.bskNode {
  float: left;
  margin-top: -3px;
  margin-right: 10px;
}
</style>
