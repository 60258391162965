<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 sm-size-100 md-size-100"
      >
        <!-- - -->
        <bsk-stats-card data-background-color="purple">
          <template slot="header">
            <i class="fad fa-laptop-code"></i>
          </template>

          <template slot="content-title">
            <p class="category">
              {{ $t('message.Developers.credits[0]') }}
            </p>
            <h3 class="title">{{ $t('message.Developers.credits[1]') }}</h3>
          </template>

          <template slot="content">
            <div class="container">
              <pre>
                <code class="language-javascript">
                ============================================================================================
                         _                   _          _            _            _             _
                        / /\                /\ \       /\ \         /\ \         /\ \     _    / /\
                       / /  \              /  \ \     /  \ \       /  \ \       /  \ \   /\_\ / /  \
                      / / /\ \            / /\ \ \   / /\ \ \     / /\ \ \     / /\ \ \_/ / // / /\ \
                     / / /\ \ \          / / /\ \_\ / / /\ \ \   / / /\ \_\   / / /\ \___/ // / /\ \ \
                    / / /  \ \ \        / / /_/ / // / /  \ \_\ / /_/_ \/_/  / / /  \/____// / /  \ \ \
                   / / /___/ /\ \      / / /__\/ // / /   / / // /____/\    / / /    / / // / /___/ /\ \
                  / / /_____/ /\ \    / / /_____// / /   / / // /\____\/   / / /    / / // / /_____/ /\ \
                 / /_________/\ \ \  / / /      / / /___/ / // / /______  / / /    / / // /_________/\ \ \
                / / /_       __\ \_\/ / /      / / /____\/ // / /_______\/ / /    / / // / /_       __\ \_\
                \_\___\     /____/_/\/_/       \/_________/ \/__________/\/_/     \/_/ \_\___\     /____/_/

                                                        ________
                =======================================//APOENA\\===========================================

                * Apoena is not an abbreviation but a name.
                * Originally from the Tupi-Guarani Indians, which means 'recover', 'rebuild', 'redo', 'see in front'.

                ============================================================================================

                * APOENA White Hat >_ HueheuBRzZ

                * Infinite thanks to Creative Tim for the beautiful work!
                * Licensed under MIT (https://github.com/creativetimofficial/vue-material-dashboard/blob/master/LICENSE.md)

                * Pablo, Joel, Tiago, Rebonatto, Léo Antunes {2021}

                </code>
              </pre>
            </div>
            <div class="gContent">
              <p>
                {{ $t('message.Developers.txtCredits[0]') }}
                <br /><br />
                {{ $t('message.Developers.txtCredits[1]') }}

                <br />
                <br />
              </p>

              <ul>
                <li>Shion <span>//XP Zone</span></li>
                <li>iOps <span>//XP Zone</span></li>
                <li>Iron <span>//WebDev</span></li>
                <li>Nike <span>//WebDev</span></li>
                <li>Kadu <span>//CentOS</span></li>
                <li>Yamachi <span>//Developer</span></li>
                <li>GodSkin <span>//Modder</span></li>
                <li>SpeedDevil <span>//Decoder</span></li>
                <li>Chumpywumpy <span>//Developer</span></li>
                <li>Hellspider <span>//Decoder</span></li>
              </ul>
            </div>
          </template>

          <template slot="footer">
            <footers
              classCss="stats"
              mdIcon="info"
              textProp="APOENA Dev.BR"
            ></footers>
          </template>
        </bsk-stats-card>
      </div>
    </div>
  </div>
</template>
<script>
import { BskStatsCard } from '@/components'
import footers from '../components/Footers/Footer'
export default {
  components: {
    BskStatsCard,
    footers,
  },
}
</script>
<style scoped lang="scss">
@import '../assets/scss/md/_Dev.scss';
</style>
