<template>
  <div class>
    <loading :active.sync="load" :can-cancel="true"></loading>

    <div v-if="check == ''" class="alert alert-warning" role="alert">
      {{ $t('message.Payment.payment[0]') }}
    </div>
    <div v-else-if="check == true" class="alert alert-success" role="alert">
      {{ $t('message.Payment.payment[1]') }}
    </div>
    <div v-else class="alert alert-danger" role="alert">
      {{ $t('message.Payment.payment[1]') }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from '../../services/paypal'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'modal',
  props: ['payerId', 'paymentId'],
  components: {
    Loading,
  },
  data() {
    return {
      paymentForThisPaypal: {},
      check: false,
      load: true,
    }
  },
  computed: {
    ...mapGetters(['userAccount']),
  },
  async mounted() {
    this.nameAccount = this.userAccount.ID
    this.paymentForThisPaypal = this.$route.params
    if (
      this.paymentForThisPaypal.PayerID != undefined &&
      this.paymentForThisPaypal.paymentId != undefined
    ) {
      this.$swal({
        title: this.$t('swal.typeMessage[0]'),
        text: this.$t('swal.processingDonate'),
        icon: 'warning',
        timer: 1000,
      })
      axios
        .executePayment({
          pay: this.paymentForThisPaypal,
          accountName: this.nameAccount,
        })
        .then(value => {
          if (value.status == 200 && value.data.code == 0) {
            this.check = false
            this.load = false
            setTimeout(() => {
              this.$swal(
                this.$t('swal.typeMessage[1]'),
                value.data.msg,
                'error'
              ).then(() => {
                this.$router.push({
                  name: 'Dashboard',
                })
              }, 1250)
            })
          } else {
            this.check = true
            setTimeout(() => {
              this.$swal(
                this.$t('swal.typeMessage[2]'),
                value.data.msg,
                'success'
              ).then(() => {
                this.load = false
                this.$router.push({
                  name: 'Dashboard',
                })
              })
            }, 1250)
          }
        })
        .catch(() => {
          this.$swal(
            this.$t('swal.typeMessage[1]'),
            this.$t('swal.errorBD'),
            'error'
          )
        })
    }
  },
}
</script>

<style></style>
