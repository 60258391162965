<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 sm-size-100 md-size-100"
      >
        <!-- - -->
        <bsk-stats-card data-background-color="green">
          <template slot="header">
            <i class="fal fa-bell-exclamation"></i>
          </template>

          <template slot="content-title">
            <p class="category">
              {{ $t('message.Rules.rules[0]') }}
            </p>
            <h3 class="title">{{ $t('message.Rules.rules[1]') }}</h3>
          </template>

          <template slot="content">
            <div class="gContent">
              <h3>.:: {{ $t('message.Rules.rules[2]') }} ::.</h3>
              <ul>
                <li class="yes">
                  {{ $t('message.Rules.rules[3]') }}
                </li>
                <li class="yes">
                  {{ $t('message.Rules.rules[3]') }}
                </li>
                <li class="yes">
                  {{ $t('message.Rules.rules[3]') }}
                </li>
              </ul>

              <h3>.:: {{ $t('message.Rules.rules[4]') }} ::.</h3>
              <ul>
                <li class="no">
                  {{ $t('message.Rules.rules[3]') }}
                </li>
                <li class="no">
                  {{ $t('message.Rules.rules[3]') }}
                </li>
                <li class="no">
                  {{ $t('message.Rules.rules[3]') }}
                </li>
              </ul>

              <h3>.:: {{ $t('message.Rules.rules[5]') }} ::.</h3>
              <p>
                {{ $t('message.Rules.rules[6]') }}
              </p>
            </div>
          </template>

          <template slot="footer">
            <footers
              classCss="stats"
              mdIcon="info"
              :textProp="$t('message.Rules.txtRules')"
            ></footers>
          </template>
        </bsk-stats-card>
      </div>
    </div>
  </div>
</template>
<script>
import { BskStatsCard } from '@/components'
import footers from '../components/Footers/Footer'
export default {
  components: {
    BskStatsCard,
    footers,
  },
}
</script>
<style scoped lang="scss">
@import '../assets/scss/md/_Rules.scss';
</style>
