/* eslint-disable indent */
import axios from 'axios'
import VueJwtDecode from 'vue-jwt-decode'
import { swal } from 'vue-swal'
import VueRouter from 'vue-router'
let router = new VueRouter()
const requestIP = axios.create({
  baseURL: process.env.VUE_APP_ADDRESS,
  headers: {
    'Accept-Language': localStorage.getItem('i18n')
      ? localStorage.getItem('i18n')
      : 'en',
  },
})
//let tokenUserData = localStorage.getItem("user-data") == null ? false : true;
let AuthStr = 'Bearer '.concat(
  (requestIP.defaults.headers.common['Authorization'] =
    localStorage.getItem('user-data') === null
      ? null
      : localStorage.getItem('user-data'))
)
const authLogin = async (dados) => {
  let login = await requestIP.post('account/login', dados)
  if (login.data.token) {
    const tokenAuth = login.data.token

    localStorage.setItem('user-data', tokenAuth)
  }
  return login
}

const shopSpecial = async () => {
  AuthStr = 'Bearer '.concat(localStorage.getItem('user-data'))

  checkIsTokenValid().status
  let shopPremium = await requestIP.get('shopEcoins', {
    headers: { Authorization: AuthStr },
  })

  if (shopPremium.data.PremiumList.length > 0) {
    return JSON.parse(shopPremium.data.PremiumList)
  } else {
    return JSON.stringify({
      mensagem: 'Itens atualmente indisponíveis',
    })
  }
}

const checkIsTokenValid = () => {
  let token =
    VueJwtDecode.decode(localStorage.getItem('user-data')) === null
      ? null
      : VueJwtDecode.decode(localStorage.getItem('user-data'))

  const currentTime = Date.now() / 1000
  if (token?.exp !== undefined && token?.exp < currentTime) {
    return { token: token, status: false }
  }
  return { token: token, status: true }
}
const shopNormal = async (shop) => {
  AuthStr = 'Bearer '.concat(localStorage.getItem('user-data'))

  if (checkIsTokenValid().status == true) {
    let shopCatNormal = await requestIP.get(`/shopEcoins/${shop.id}`, {
      headers: { Authorization: AuthStr },
    })

    if (shopCatNormal.status === 200) return shopCatNormal
    else return 0
  } else {
    swal(
      'Sessão expirou',
      'Você será redirecionado para a página de login',
      'error'
    ).then(() => {
      setTimeout(() => {
        logout()
      }, 800)
    })
  }
}

const buyItem = async ({ item, totalStock }, category) => {
  AuthStr = 'Bearer '.concat(localStorage.getItem('user-data'))
  let buy = null
  if (category === 'NPoints')
    buy = await requestIP.post(
      'buyitemNcoins/',
      { item, totalStock },
      {
        headers: { Authorization: AuthStr },
      }
    )
  else {
    buy = await requestIP.post(
      'buyitem/',
      { item, totalStock },
      {
        headers: { Authorization: AuthStr },
      }
    )
  }
  if (buy?.status == 200) {
    return buy
  }
  return buy
}

const cashInAccount = async (userNum) => {
  AuthStr = 'Bearer '.concat(localStorage.getItem('user-data'))

  if (checkIsTokenValid().status === true) {
    let cash = await requestIP.get(`/cashAccount/` + userNum, {
      headers: { Authorization: AuthStr },
    })

    if (cash.status == 200) {
      return cash
    }
    return cash
  } else {
    swal(
      'Sessão expirou',
      'Você será redirecionado para a página de login',
      'error'
    ).then(() => {
      setTimeout(() => {
        logout()
      }, 800)
    })
  }
}

const searchAllCharacter = async (userNum) => {
  AuthStr = 'Bearer '.concat(localStorage.getItem('user-data'))

  const char = await requestIP.get('/character/' + userNum, {
    headers: { Authorization: AuthStr },
  })
  if (char.status === 200) {
    return char
  } else {
    return 'Erro ao pesquisar pelo Char, "Contate um Administrador"'
  }
}

const charDistribute = async (char) => {
  AuthStr = 'Bearer '.concat(localStorage.getItem('user-data'))
  if (checkIsTokenValid().status == true) {
    const distribute = await requestIP.put('/charDistribute/', char, {
      headers: { Authorization: AuthStr },
    })
    if (distribute.status === 200) return distribute
    else return distribute
  } else {
    swal(
      'Sessão expirou',
      'Você será redirecionado para a página de login',
      'error'
    ).then(() => {
      setTimeout(() => {
        logout()
      }, 800)
    })
  }
}

const subMenuShop = async () => {
  AuthStr = 'Bearer '.concat(localStorage.getItem('user-data'))

  const menu = await requestIP.get('/menuShop/', {
    headers: { Authorization: AuthStr },
  })
  if (menu.status === 200) return menu
  else return menu.data
}

const logout = () => {
  localStorage.removeItem('user-data')
  localStorage.removeItem('vuex')
  localStorage.removeItem('_secure__ls__metadata')
  localStorage.removeItem('rank')
  localStorage.removeItem('Guild')
  router.go(0)
}

const transferChar = async (obj) => {
  if (checkIsTokenValid().status == true) {
    const transf = await requestIP.post('/TransferChar', obj, {
      headers: { Authorization: AuthStr },
    })
    if (transf.status == 200) return transf
    else return 0
  } else {
    swal(
      'Sessão expirou',
      'Você será redirecionado para a página de login',
      'error'
    ).then(() => {
      setTimeout(() => {
        logout()
      }, 800)
    })
  }
}

const searchCharForCode = async (code) => {
  AuthStr = 'Bearer '.concat(localStorage.getItem('user-data'))

  if (checkIsTokenValid().status == true) {
    const charCode = await requestIP.get('/searchCode/' + code, {
      headers: { Authorization: AuthStr },
    })
    if (charCode.status == 200) return charCode
    else return 0
  } else {
    swal(
      'Sessão expirou',
      'Você será redirecionado para a página de login',
      'error'
    ).then(() => {
      setTimeout(() => {
        logout()
      }, 800)
    })
  }
}

const searchCharNotGuild = async (userNum) => {
  AuthStr = 'Bearer '.concat(localStorage.getItem('user-data'))
  if (checkIsTokenValid().status == true) {
    const char = await requestIP.get('/searCharInGuild/' + userNum, {
      headers: { Authorization: AuthStr },
    })
    if (char.status == 200) return char
    else return 0
  } else {
    swal(
      'Sessão expirou',
      'Você será redirecionado para a página de login',
      'error'
    ).then(() => {
      setTimeout(() => {
        logout()
      }, 800)
    })
  }
}

const exchangeCharInAccount = async (dados) => {
  AuthStr = 'Bearer '.concat(localStorage.getItem('user-data'))

  if (checkIsTokenValid().status == true) {
    const exchange = await requestIP.post('/exchangeCharInAccount/', dados, {
      headers: { Authorization: AuthStr },
    })
    if (exchange.status == 200) return exchange
    else return exchange.data.msg
  } else {
    swal(
      'Sessão expirou',
      'Você será redirecionado para a página de login',
      'error'
    ).then(() => {
      setTimeout(() => {
        logout()
      }, 800)
    })
  }
}

const charNotGuildAndAlreadyTransfer = async (userNum) => {
  AuthStr = 'Bearer '.concat(localStorage.getItem('user-data'))

  if (checkIsTokenValid().status == true) {
    const charNotGuild = await requestIP.get(
      '/charNotGuildAndAlreadyTransfer/' + userNum,
      { headers: { Authorization: AuthStr } }
    )
    if (charNotGuild.status == 200) return charNotGuild
    else return charNotGuild.data.msg
  } else {
    swal(
      'Sessão expirou',
      'Você será redirecionado para a página de login',
      'error'
    ).then(() => {
      setTimeout(() => {
        logout()
      }, 800)
    })
  }
}

const searchNPoints = async (userNum) => {
  if (checkIsTokenValid().status == true) {
    const npoints = await requestIP.get('/buyitemNcoin/' + userNum, {
      headers: { Authorization: AuthStr },
    })
    if (npoints.status === 200) return npoints
    else return npoints
  } else {
    swal(
      'Sessão expirou',
      'Você será redirecionado para a página de login',
      'error'
    ).then(() => {
      setTimeout(() => {
        logout()
      }, 800)
    })
  }
}
const NPointsInAccount = async (userNum) => {
  AuthStr = 'Bearer '.concat(localStorage.getItem('user-data'))

  if (checkIsTokenValid().status == true) {
    let cash = await requestIP.get('/buyitemNcoins/' + userNum, {
      headers: { Authorization: AuthStr },
    })

    if (cash.status == 200) {
      return cash
    } else {
      return cash
    }
  } else {
    swal(
      'Sessão expirou',
      'Você será redirecionado para a página de login',
      'error'
    ).then(() => {
      setTimeout(() => {
        logout()
      }, 800)
    })
  }
}

const register = async (dados) => {
  let register = await requestIP.post('account/create', dados)
  if (register.status === 201 && register.data.error === 0) {
    return register
  } else {
    return register
  }
}

const activationAccount = async (activeAccount) => {
  let act = await requestIP.put('/account/activation/', activeAccount)
  if (act.status === 200) return act
  return act
}

const searchEventsAndAlzs = async (userNum) => {
  AuthStr = 'Bearer '.concat(localStorage.getItem('user-data'))
  if (checkIsTokenValid().status == true) {
    let searchEventsAndAlzs = await requestIP.get(`/othersCall/${userNum}`, {
      headers: { Authorization: AuthStr },
    })
    if (searchEventsAndAlzs.status === 200) return searchEventsAndAlzs
    return searchEventsAndAlzs
    //test
  } else {
    swal(
      'Sessão expirou',
      'Você será redirecionado para a página de login',
      'error'
    )
    // setTimeout(() => {
    //     logout()
    // }, 800)
  }
}
const createPaymentMp = async (payment) => {
  AuthStr = 'Bearer '.concat(localStorage.getItem('user-data'))
  if (checkIsTokenValid().status == true) {
    let pay = await requestIP.post('/choosePayment/', payment, {
      headers: { Authorization: AuthStr },
    })
    if (pay.status === 201 || pay.status === 200) return pay
    else return pay
  } else {
    swal(
      'Sessão expirou',
      'Você será redirecionado para a página de login',
      'error'
    ).then(() => {
      setTimeout(() => {
        logout()
      }, 800)
    })
  }
}

const changeToEmail = async (mail) => {
  AuthStr = 'Bearer '.concat(localStorage.getItem('user-data'))
  if (checkIsTokenValid().status == true) {
    const chMail = await requestIP.put('/account/changeToEmail/', mail, {
      headers: { Authorization: AuthStr },
    })
    if (chMail.status === 200) return chMail
    else return chMail
  } else {
    swal(
      'Sessão expirou',
      'Você será redirecionado para a página de login',
      'error'
    ).then(() => {
      setTimeout(() => {
        logout()
      }, 800)
    })
  }
}

const insertAndChangeCell = async (cell) => {
  AuthStr = 'Bearer '.concat(localStorage.getItem('user-data'))
  if (checkIsTokenValid().status == true) {
    const cellPhone = await requestIP.put('/account/insertAndChange/', cell, {
      headers: { Authorization: AuthStr },
    })

    if (cellPhone.status === 200) return cellPhone
    else return cellPhone
  } else {
    swal(
      'Sessão expirou',
      'Você será redirecionado para a página de login',
      'error'
    ).then(() => {
      setTimeout(() => {
        logout()
      }, 800)
    })
  }
}

const changeDataPeople = async (dataPeople) => {
  AuthStr = 'Bearer '.concat(localStorage.getItem('user-data'))
  if (checkIsTokenValid().status == true) {
    const data = await requestIP.post(
      '/account/changeDataPeople/',
      dataPeople,
      {
        headers: { Authorization: AuthStr },
      }
    )
    if (data.status === 200) return data
    else return data
  } else {
    swal(
      'Sessão expirou',
      'Você será redirecionado para a página de login',
      'error'
    ).then(() => {
      setTimeout(() => {
        logout()
      }, 800)
    })
  }
}

const deleteCharacter = async (char) => {
  AuthStr = 'Bearer '.concat(localStorage.getItem('user-data'))
  if (checkIsTokenValid().status == true) {
    const data = await requestIP.post('/deleteCharacter/', char, {
      headers: { Authorization: AuthStr },
    })

    if (data.status === 200) return data
    else return data
  } else {
    swal(
      'Sessão expirou',
      'Você será redirecionado para a página de login',
      'error'
    ).then(() => {
      setTimeout(() => {
        logout()
      }, 800)
    })
  }
}

const warzone = async (obj) => {
  AuthStr = 'Bearer '.concat(localStorage.getItem('user-data'))
  if (checkIsTokenValid().status == true) {
    const war = await requestIP.get(`/warzone/${obj.charIdx}/${obj.nation}`, {
      headers: { Authorization: AuthStr },
    })

    if (war.status === 200) return war
    else return war
  } else {
    swal(
      'Sessão expirou',
      'Você será redirecionado para a página de login',
      'error'
    ).then(() => {
      setTimeout(() => {
        logout()
      }, 800)
    })
  }
}

const createTeamWarzone = async (obj, char) => {
  if (checkIsTokenValid().status == true) {
    AuthStr = 'Bearer '.concat(localStorage.getItem('user-data'))
    const war = await requestIP.post(
      '/createTeamWarzone',
      { obj, char },
      {
        headers: { Authorization: AuthStr },
      }
    )

    if (war.status === 200) return war
    else return war
  } else {
    swal(
      'Sessão expirou',
      'Você será redirecionado para a página de login',
      'error'
    ).then(() => {
      setTimeout(() => {
        logout()
      }, 800)
    })
  }
}

const historyDonate = async (userNum) => {
  AuthStr = 'Bearer '.concat(localStorage.getItem('user-data'))
  if (checkIsTokenValid().status == true) {
    const history = await requestIP.get(`/historyDonate/${userNum}`, {
      headers: { Authorization: AuthStr },
    })
    if (history.status === 200) return history
    else return history
  } else {
    swal(
      'Sessão expirou',
      'Você será redirecionado para a página de login',
      'error'
    ).then(() => {
      setTimeout(() => {
        logout()
      }, 800)
    })
  }
}

const getNews = async () => {
  AuthStr = 'Bearer '.concat(localStorage.getItem('user-data'))
  if (checkIsTokenValid().status == true) {
    const news = await requestIP.get('/news', {
      headers: { Authorization: AuthStr },
    })
    if (news.status === 200 && news.code === 1) return news
    else return news
    //
  } else {
    swal(
      'Sessão expirou',
      'Você será redirecionado para a página de login',
      'error'
    ).then(() => {
      setTimeout(() => {
        logout()
      }, 800)
    })
  }
}

const listDonation = async () => {
  AuthStr = 'Bearer '.concat(localStorage.getItem('user-data'))
  if (checkIsTokenValid().status == true) {
    const list = await requestIP.get(`/listDonation`, {
      headers: { Authorization: AuthStr },
    })
    if (list.status === 200) return list
    else return list
  } else {
    swal(
      'Sessão expirou',
      'Você será redirecionado para a página de login',
      'error'
    ).then(() => {
      setTimeout(() => {
        logout()
      }, 800)
    })
  }
}

const rankingGuilds = async () => {
  AuthStr = 'Bearer '.concat(localStorage.getItem('user-data'))
  if (checkIsTokenValid().status == true) {
    const rankGuild = await requestIP.get('/ranking/guilds/', {
      headers: { Authorization: AuthStr },
    })

    if (rankGuild.status === 200) return rankGuild
    return rankGuild
  } else {
    swal(
      'Sessão expirou',
      'Você será redirecionado para a página de login',
      'error'
    ).then(() => {
      setTimeout(() => {
        logout()
      }, 800)
    })
  }
}
const rankingPlayers = async () => {
  AuthStr = 'Bearer '.concat(localStorage.getItem('user-data'))

  if (checkIsTokenValid().status == true) {
    const rank = await requestIP.get('/ranking/character/', {
      headers: { Authorization: AuthStr },
    })

    if (rank.status === 200) return rank
    return rank
  } else {
    swal(
      'Sessão expirou',
      'Você será redirecionado para a página de login',
      'error'
    ).then(() => {
      setTimeout(() => {
        logout()
      }, 800)
    })
  }
}
const charWithPk = async (userNum) => {
  AuthStr = 'Bearer '.concat(localStorage.getItem('user-data'))

  if (checkIsTokenValid().status == true) {
    const pk = await requestIP.get(`/charWithPk/${userNum}`, {
      headers: { Authorization: AuthStr },
    })

    if (pk.status === 200) return pk
    else return pk
  } else {
    swal(
      'Sessão expirou',
      'Você será redirecionado para a página de login',
      'error'
    ).then(() => {
      setTimeout(() => {
        logout()
      }, 800)
    })
  }
}

const resetPk = async (obj) => {
  AuthStr = 'Bearer '.concat(localStorage.getItem('user-data'))
  if (checkIsTokenValid().status == true) {
    let resetPk = await requestIP.post('/resetPk', obj, {
      headers: { Authorization: AuthStr },
    })

    if (resetPk.status === 200) return resetPk
    else return resetPk
  } else {
    swal(
      'Sessão expirou',
      'Você será redirecionado para a página de login',
      'error'
    ).then(() => {
      setTimeout(() => {
        logout()
      }, 800)
    })
  }
}

const requestRecoveryPasswordAccount = async ({ user, email }) => {
  const recovery = await requestIP.get(
    `account/sendEmailToRecoveryPassword/${user}/${email}`
  )
  return recovery.status === 200 ? recovery : { code: 400 }
}

const RecoveryPasswordAccount = async (dados) => {
  const changePassword = await requestIP.put(`account/recoveryPassword/`, dados)
  return changePassword.status === 200 ? changePassword : { code: 400 }
}

const PVPLogs = async () => {
  const pvp = await requestIP.get(`/pvp`)
  return pvp.status === 200 ? pvp : { code: 400 }
}

const PKLogs = async () => {
  const pk = await requestIP.get(`/pk`)
  return pk.status === 200 ? pk : { code: 400 }
}

const dropsItems = async () => {
  const drop = await requestIP.get(`/drop`)
  return drop.status === 200 ? drop : { code: 400 }
}

const getMoney = async () => {
  AuthStr = 'Bearer '.concat(localStorage.getItem('user-data'))
  requestIP.defaults.headers.common['Authorization'] =
    localStorage.getItem('user-data') === null
      ? null
      : localStorage.getItem('user-data')

  const money = await requestIP.get('/getMoney', {
    headers: { Authorization: AuthStr },
  })
  return money?.status === 200
    ? money
    : { code: 404, msg: 'Não foi possivel encontrar uma moeda cadastrada!' }
}

const resendCode = async (obj) => {
  let resend = await requestIP.post('/resendcode', obj)

  return resend
}

export default {
  resendCode,
  getMoney,
  dropsItems,
  PKLogs,
  PVPLogs,
  RecoveryPasswordAccount,
  requestRecoveryPasswordAccount,
  deleteCharacter,
  changeDataPeople,
  insertAndChangeCell,
  activationAccount,
  authLogin,
  checkIsTokenValid,
  shopSpecial,
  shopNormal,
  cashInAccount,
  searchAllCharacter,
  buyItem,
  charDistribute,
  subMenuShop,
  transferChar,
  searchCharForCode,
  searchCharNotGuild,
  exchangeCharInAccount,
  charNotGuildAndAlreadyTransfer,
  logout,
  searchNPoints,
  NPointsInAccount,
  register,
  createPaymentMp,
  searchEventsAndAlzs,
  changeToEmail,
  warzone,
  createTeamWarzone,
  historyDonate,
  listDonation,
  getNews,
  rankingPlayers,
  rankingGuilds,
  charWithPk,
  resetPk,
}
