<template slot="content">
  <div class="container">
    <loading :active.sync="visible" :can-cancel="true"></loading>

    <div class="vip-bBskShop">
      <div class="vip-gBskShop">
        <div class="presentsVip pink">
          <i class="fas fa-chess-knight-alt"></i>
        </div>
        <div class="ContentItem">
          <div class="Item">
            <span>
              Premium 02
            </span>
          </div>
          <div class="valueItem">
            <div class="iconCoin">
              <i class="fas fa-comment-dollar"></i>
            </div>
            <div class="valueCoin">R$ 15,00</div>
          </div>
          <div class="QtItem">
            <button class="CpItem">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from '../../services/servicesAxios'
import { mapGetters } from 'vuex'
import refItemBuy from '../../services/itemBuy'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'itemShop',
  components: {
    Loading,
  },
  data() {
    return {
      listItemShop: {},
      qntItemsBuy: 0,
      visible: false,
    }
  },
  computed: {
    ...mapGetters(['cashInAccountGetter', 'userAccount']),
  },
  methods: {
    buy(itemBuy) {
      if (itemBuy.ItemIdx > 0 || itemBuy.ItemIdx != undefined) {
        //verifica se o cash da conta e suficiente para comprar o item
        if (itemBuy.TotalStock > 0) {
          //Soma Total do Valor
          let totalValueItems = itemBuy.TotalStock * itemBuy.ItemPrice
          if (totalValueItems < this.cashInAccountGetter) {
            this.$swal({
              title: this.$t('message.ItemShop.buyItem[0]'),
              text: this.$t('message.ItemShop.buyItem[1]'),
              icon: 'warning',
              buttons: true,
              dangerMode: true,
            }).then(async response => {
              if (response) {
                const checkBuy = await refItemBuy.buyItem(
                  itemBuy,
                  this.cashInAccountGetter,
                  totalValueItems,
                  this.userAccount.UserNum,
                  this.$loading.show({
                    loader: 'Spinner',
                    color: 'blue',
                  }),
                  this.$t('message.ItemShop.errorBuy')
                )
                if (checkBuy === 1) {
                  const total = this.cashInAccountGetter - totalValueItems
                  itemBuy.TotalStock = 1
                  this.$store.commit('cashInAccountMut', { Cash: total })
                }
              } else {
                this.$swal(this.$t('message.ItemShop.quitItem'))
              }
            })
          } else {
            this.$swal(
              this.$t('swal.typeMessage[0]'),
              this.$t('swal.errorCash'),
              'warning'
            )
          }
        } else {
          this.$swal(
            this.$t('swal.typeMessage[0]'),
            this.$t('swal.errorQntItems'),
            'warning'
          )
        }
      }
    },
  },
  created() {
    axios
      .shopNormal(this.idRouter)
      .then(value => {
        if (value.status === 200) {
          this.listItemShop = value.data.Items
        } else {
          this.$swal(
            this.$t('swal.typeMessage[0]'),
            this.$t('swal.not_foundItems'),
            'warning'
          )
        }
      })
      .catch(err => {
        this.$swal(
          this.$t('swal.typeMessage[0]'),
          this.$t('swal.not_foundItems'),
          'warning'
        )

        throw new Error(`Error Connect in API: ${err}`)
      })
    // if(itemShop.status === 200) this.listItemShop = itemShop.data
  },
}
</script>
<style scoped lang="scss">
@import '../../assets/scss/md/_shop.scss';
</style>
