<template>
  <div>
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-33 md-xsmall-size-100 sm-size-100"
      >
        <div class="gridBskOfGlory"><div class="branchBskGloryCap">1</div></div>
      </div>
      <div
        class="md-layout-item md-medium-size-33 md-xsmall-size-100 sm-size-100"
      >
        <div class="gridBskOfGlory">
          <div class="branchBskGloryProcy">1</div>
        </div>
      </div>
      <div
        class="md-layout-item md-medium-size-33 md-xsmall-size-100 sm-size-100"
      >
        <div class="gridBskOfGlory"><div class="branchBskGlory">1</div></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {}
</script>
<style lang="scss" scoped>
@import '../assets/scss/md/_Community.scss';
</style>
