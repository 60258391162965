import { render, staticRenderFns } from "./ItemShopPremium.vue?vue&type=template&id=c5a18556&scoped=true&slot=content&"
import script from "./ItemShopPremium.vue?vue&type=script&lang=js&"
export * from "./ItemShopPremium.vue?vue&type=script&lang=js&"
import style0 from "./ItemShopPremium.vue?vue&type=style&index=0&id=c5a18556&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5a18556",
  null
  
)

export default component.exports