<template>
  <div
    class="sidebar"
    :data-color="sidebarItemColor"
    :data-image="sidebarBackgroundImage"
    :style="sidebarStyle"
  >
    <div class="logo">
      <div class="logo-img">
        <a href="/">
          <!-- <img :src="imgLogo" alt="" /> -->
        </a>
      </div>
    </div>
    <div class="sidebar-wrapper">
      <slot name="content"></slot>
      <!-- User Info-->
      <div class="sidebarUserInfo">
        <div class="titleIdAcc">ID ACC</div>
        <!-- <div class="titleLvAcc">
          <span class="Um-10">
            <i class="fas fa-star"></i>
          </span>
          <span class="Um-10">
            <i class="fas fa-star"></i>
          </span>
          <span class="Um-5">
            <i class="fas fa-star-half-alt"></i>
          </span>
          <span class="Um-0">
            <i class="far fa-star"></i>
          </span>
          <span class="Um-0">
            <i class="far fa-star"></i>
          </span>
        </div> -->
        <div class="varIdAcc">{{ this.userAccount.ID }}</div>
        <!-- Div.StatusPA Span Class: inativo = padrão , ativo , expirado -->
        <div class="statusPA">
          <span class="ativo">{{ $t('message.SideBar.activePremium') }}</span>
        </div>
        <div class="statusACC" v-if="this.userAccount.ExpireDate > 0">
          <span class="On">ONLINE</span>
        </div>
        <div class="statusACC" v-else><span class="Off">OFFLINE</span></div>
      </div>

      <md-list class="nav">
        <!--By default vue-router adds an active class to each route link. This way the links are colored when clicked-->
        <slot>
          <sidebar-link
            v-for="(link, index) in sidebarLinks"
            :key="link.name + index"
            :to="link.path"
            :link="link"
          >
          </sidebar-link>
        </slot>
      </md-list>
    </div>
  </div>
</template>
<script>
import SidebarLink from './SidebarLink.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    SidebarLink,
  },
  props: {
    sidebarBackgroundImage: {
      type: String,
      //default: require("@/assets/img/sidebar-2.jpg")
    },
    imgLogo: {
      type: String,
      default: require('@/assets/img/Auth/logo-min-Fury.png'),
    },
    sidebarItemColor: {
      type: String,
      default: 'bsk',
      validator: value => {
        let acceptedValues = [
          '',
          'purple',
          'blue',
          'green',
          'orange',
          'red',
          'bsk',
        ]
        return acceptedValues.indexOf(value) !== -1
      },
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    }
  },
  computed: {
    sidebarStyle() {
      return {
        backgroundImage: `url(${this.sidebarBackgroundImage})`,
      }
    },
    ...mapGetters(['userAccount']),
  },
}
</script>
<style scoped>
@media screen and (min-width: 991px) {
  .nav-mobile-menu {
    display: none;
  }
}

.logo .logo-img {
  max-width: 100%;
  width: 8em;
}
</style>
