<template>
  <!-- -->
  <div>
    <loading :active.sync="visible" :can-cancel="false"></loading>
    <div class="full-page-Register">
      <EffectRegister></EffectRegister>
    </div>
    <div class="grid-header">
      <div class="ornament-bsk"></div>
      <div class="logo-bsk"></div>
    </div>
    <div class="grid-cardAuth-bsk">
      <div class="row">
        <h1>{{ $t('message.Register.txtTitle') }}</h1>
        <div class="divisor-BskReg">
          <img
            src="https://lolstatic-a.akamaihd.net/frontpage/apps/prod/signup/pt_BR/c90bd494d0d5a9eecb710c7895776bb332a0e51a/assets/pt_BR/assets/divider.png"
            alt="divide" />
        </div>
        <div class="group-formBsk">
          <form @submit.prevent="register" method="post">
            <div class="group-BskInput">
              <!-- <div class="form-error" v-if="!$v.ID.minLength" role="alert">
                {{ $t('message.Register.txtAlertIDlenght') }}
                {{ $v.ID.$params.minLength.min }}
                {{ $t('message.Register.txtAlertIDlenght2') }}
              </div> -->
              <input type="text" name="ID" v-model.lazy="$v.ID.$model" class="BskInput"
                :class="{ 'input-error': !$v.ID.minLength }" @change="$v.ID.$touch()" :required="$v.ID.required" />
              <label for="ID">{{ $t('message.Register.labelID') }}</label>
              <span class="error-span" v-if="!$v.ID.minLength">
                {{ $t('message.Register.txtAlertIDlenght') }}
                {{ $v.ID.$params.minLength.min }}
                {{ $t('message.Register.txtAlertIDlenght2') }}
              </span>
            </div>
            <div class="group-BskInput">
              <input v-model.lazy="$v.Email.$model" @change="$v.Email.$touch()"
                :class="{ 'input-error': !$v.Email.email }" type="MAIL" name="MAIL" class="BskInput"
                :required="$v.Email.required" />

              <label for="MAIL">{{ $t('message.Register.labelMail') }}</label>

              <span class="error-span" v-if="!$v.Email.email">
                {{ $t('message.Register.txtAlertMailInvalid') }}
              </span>
            </div>
            <div class="group-BskInput">
              <!-- <div class="form-error" v-if="!$v.phone.minLength" role="alert">
                digite um numero de telefone valido para criar a conta! [+## ## # ####-####]
              </div> -->
              <input v-model.lazy="$v.phone.$model" @change="$v.phone.$touch()" v-maska
                :class="{ 'input-error': !$v.phone.minLength }" data-maska="['+# ### ###-##-##','+## ## # ####-####']"
                v-on:minlength="$v.phone.$params.minLength.min" type="tel" name="phone" v-on:maxlength="18"
                class="BskInput" :required="!$v.phone.minLength ? 'required' : false" />

              <label for="phone">Telefone</label>
              <span class="error-span" v-if="!$v.phone.minLength">
                digite um numero de telefone valido para criar a conta! [+## ## # ####-####]
              </span>
            </div>
            <div class="group-BskInput">
              <!-- <div class="form-error" v-if="!$v.password.minLength" role="alert">
                {{ $t('message.Register.txtAlertPasslenght') }}
                {{ $v.password.$params.minLength.min }}
                {{ $t('message.Register.txtAlertPasslenght2') }}
              </div> -->
              <input v-model.lazy="$v.password.$model" @change="$v.password.$touch()"
                :class="{ 'input-error': !$v.password.minLength }" type="password" name="PASS" class="BskInput"
                :required="$v.password.required" />

              <label for="PASS">{{ $t('message.Register.labelPass') }}</label>

              <span class="error-span" v-if="!$v.password.minLength">
                {{ $t('message.Register.txtAlertPasslenght') }}
                {{ $v.password.$params.minLength.min }}
                {{ $t('message.Register.txtAlertPasslenght2') }}
              </span>
            </div>
            <div class="group-BskInput">
              <button>{{ $t('message.Register.btnRegister') }}</button>
              <br />
              <hr class="bsk-hr" :data-content="$t('message.Register.txtLabelOr')" />
              <p>
                {{ $t('message.Register.txtBackToLogin') }}
                <router-link to="/">{{
                  $t('message.Register.txtBackToLoginLink')
                }}</router-link>.
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="grid-footer">
      <div class="br-privating"></div>
      <div class="br-rating"></div>
    </div>
  </div>
</template>
<script>
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators/'
import axios from '../services/servicesAxios'
import 'vue-loading-overlay/dist/vue-loading.css'
import Loading from 'vue-loading-overlay'
import EffectRegister from '../components/EffectRegister.vue'
import { vMaska } from "maska"

export default {
  name: 'RegisterComponents',
  components: { Loading, EffectRegister },
  directives: { maska: vMaska },
  data() {
    return {
      ID: '',
      Email: '',
      password: '',
      phone: '',
      visible: false,
    }
  },
  validations: {
    ID: {
      required,
      minLength: minLength(5),
    },
    password: {
      required,
      minLength: minLength(4),
    },
    Email: {
      required,
      email,
    },
    phone: {
      required,
      minLength: minLength(18),
      maxlength: maxLength(18)
    }
  },
  methods: {
    register() {
      this.visible = true
      if (!this.$v.$invalid) {
        if (this.ID === '' || this.password === '' || this.Email === '' || (this.phone === '' && this.$v.phone)) {
          this.$swal(
            this.$t('swal.typeMessage[1]'),
            this.$t('swal.not_found_dados'),
            'error'
          )
          this.loadingMeth()
        } else {
          axios
            .register({
              id: this.ID,
              pass: this.password,
              email: this.Email,
              phone: this.phone,
            })
            .then((value) => {
              if (value.status === 201) {
                this.loadingMeth()
                this.$swal(
                  this.$t('swal.typeMessage[2]'),
                  this.$t('swal.successAcount'),
                  'success'
                ).then(() => {
                  this.$router.push({
                    name: 'Login',
                  })
                })
              } else
                this.$swal(
                  this.$t('swal.typeMessage[1]'),
                  this.$t('swal.error_register_account'),
                  'error'
                )
              this.loadingMeth()
            })
            .catch((err) => {
              if (err !== undefined) {
                if (err.response.status === 500)
                  this.$swal(
                    this.$t('swal.typeMessage[1]'),
                    err.response.data.mensagem,
                    'error'
                  )
                else if (err.response.status === 503)
                  this.$swal(
                    this.$t('swal.typeMessage[0]'),
                    err.response.data.mensagem,
                    'warning'
                  )

                else {
                  const code = err.response.data?.code
                  this.$swal(
                    code === 2526 ? this.$t('swal.typeMessage[0]') : this.$t('swal.typeMessage[1]'),
                    err.response.data.mensagem,
                    code === 2526 ? 'warning' : 'error'
                  )

                }
              } else {
                this.$swal(
                  this.$t('swal.typeMessage[1]'),
                  this.$t('swal.error_register_account'),
                  'error'
                )
              }
              // this.$swal(
              //     this.$t('swal.typeMessage[0]'),
              //     this.$t('swal.alreadyAccount'),
              //     'warning'
              //   )
            })
            .finally(() => {
              this.loadingMeth()
            })
        }
      } else {
        this.$swal(
          this.$t('swal.typeMessage[1]'),
          this.$t('swal.checkDados'),
          'warning'
        )
        this.loadingMeth()
      }
    },
    loadingMeth() {
      this.visible = false
    },
  },
}
</script>
<style scoped lang="scss">
@import "../assets/scss/register.scss";

.input-error {
  border-bottom: 2px solid #be1e37 !important;
}

.error-span {
  display: flex;
  /* text-align: right; */
  width: 100%;
  font-size: 10px;
  font-weight: bold;
  line-height: 20px;
  color: #be1e37;
  letter-spacing: .025rem;
}
</style>
