<template>
  <div class="areaBsk">
    <loading :active.sync="visible" :can-cancel="false"></loading>

    <div class="reguaTop"></div>
    <div class="bskClient_bg_part1">
      <div class="bskClient_bg_part2">
        <div class="bskClient_bg_part3">
          <div class="md-layout">
            <div class="md-layout-item md-medium-size-60 md-xsmall-size-100 sm-size-100 md-size-70">
              <div class="renderizeCharactere">
                <div class="RendSelectChar">
                  <div v-if="this.SelectedTypeChar === 'GU'" class="GU">
                    {{ SelectedTypeChar }}
                  </div>
                  <div v-else-if="this.SelectedTypeChar === 'GA'" class="GA">
                    {{ SelectedTypeChar }}
                  </div>
                  <div v-else-if="this.SelectedTypeChar === 'DU'" class="DU">
                    {{ SelectedTypeChar }}
                  </div>
                  <div v-else-if="this.SelectedTypeChar === 'EA'" class="EA">
                    {{ SelectedTypeChar }}
                  </div>
                  <div v-else-if="this.SelectedTypeChar === 'AA'" class="AA">
                    {{ SelectedTypeChar }}
                  </div>
                  <div v-else-if="this.SelectedTypeChar === 'MA'" class="MA">
                    {{ SelectedTypeChar }}
                  </div>
                </div>
              </div>
            </div>
            <div class="md-layout-item md-medium-size-40 md-xsmall-size-100 sm-size-100 md-size-30">
              <div class="listCharMenu">
                <ul>
                  <li @click="selectedChar(char, char.x)" v-for="(char, index) in stati1cObj" :class="char.class"
                    :key="index">
                    <div class="lvel">Level {{ char.x.LEV }}</div>
                    <div class="cartNam">{{ char.x.Name }}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ToolbarChart">
        <div class="bugFix-ToolbarChart">
          <div class="md-layout">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 sm-size-100 md-size-30">
              <div class="inforChartBox">
                <div class="CharClass">
                  <div v-if="this.SelectedTypeChar === 'GU'" class="GU"></div>
                  <div v-else-if="this.SelectedTypeChar === 'GA'" class="GA"></div>
                  <div v-else-if="this.SelectedTypeChar === 'DU'" class="DU"></div>
                  <div v-else-if="this.SelectedTypeChar === 'EA'" class="EA"></div>
                  <div v-else-if="this.SelectedTypeChar === 'AA'" class="AA"></div>
                  <div v-else-if="this.SelectedTypeChar === 'MA'" class="MA"></div>
                </div>
                <div class="CharName">
                  {{ this.charactersDel.Name }}
                </div>
                <div class="CharLv">Level {{ this.charactersDel.LEV }}</div>
                <hr />
                <div class="CharPNTs">
                  <div class="pts">
                    STR: <b>{{ this.charactersDel.STR }}</b> , INT:
                    <b>{{ this.charactersDel.INT }}</b> , DEX:
                    <b>{{ this.charactersDel.DEX }}</b>
                  </div>
                </div>
              </div>
            </div>
            <div class="md-layout-item md-small-size-100 md-medium-size-100 md-xsmall-size-100 sm-size-100 md-size-70">
              <div class="deltChartBox">
                <button type="submit" @click.prevent="deleteChar(charactersDel)">
                  {{ $t('message.Characters.delete') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import 'vue-loading-overlay/dist/vue-loading.css'
import Loading from 'vue-loading-overlay'
export default {
  name: 'charactersDel',
  components: { Loading },
  data() {
    return {
      staticObj: [{ code: 1 }, { code: 2 }],
      charactersDel: {},
      renderStaticClass: '',
      visible: false,
    }
  },
  computed: {
    ...mapGetters(['characters', 'userAccount']),
    stati1cObj() {
      return this.characters.map(x => {
        return x.Style === 1
          ? { class: 'GU', x }
          : x.Style === 2
            ? { class: 'DU', x }
            : x.Style === 3
              ? { class: 'MA', x }
              : x.Style === 4
                ? { class: 'AA', x }
                : x.Style === 5
                  ? { class: 'GA', x }
                  : { class: 'EA', x }
      })
    },
    SelectedTypeChar() {
      return this.renderStaticClass.class
    },
    SelectedTypeChar2() {
      return this.renderStaticClass.x
    },
  },
  methods: {
    ...mapActions(['deleteCharacterIdx']),
    selectedChar(index, char) {
      this.charactersDel = char
      return (this.renderStaticClass = index)
    },
    async deleteChar(evt) {
      this.visible = true;
      console.log(this.userAccount)
      if (evt.CharacterIdx != 0 || evt.CharacterIdx != undefined) {
        const returnAction = await this.deleteCharacterIdx({ ...evt, ID_User: this.userAccount.ID })
        console.log(returnAction)
        if (returnAction.code === 1) {
          this.$swal(
            this.$t('swal.typeMessage[2]'),
            returnAction.msg,
            'success'
          ).then(() => {
            this.visible = false
            setTimeout(() => {
              this.$router.go(0)
            }, 200)
          })
        } else if (returnAction.code === -1) {
          this.visible = false
          this.$swal(this.$t('swal.typeMessage[1]'), returnAction.msg, 'error')
        } else if (returnAction.code === -2) {
          this.$swal(
            this.$t('swal.typeMessage[1]'),
            returnAction.msg,
            'error'
          ).then(() => {
            this.visible = false
            this.$router.go(0)
          })
        } else if (returnAction.code === 2526) {
          this.visible = false
          this.$swal(this.$t('swal.typeMessage[0]'), returnAction.msg, 'warning')

        } else {
          this.$swal(
            this.$t('swal.typeMessage[0]'),
            returnAction.msg,
            'warning'
          ).then(() => {
            this.visible = false
            this.$router.go(0)
          })
        }
      }
    },
  },

  mounted() {
    this.charactersDel = this.characters;
  },
}
</script>
<style lang="scss" scoped>
@import '../assets/scss/md/_MyCharacters.scss';
</style>
