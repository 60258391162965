<template slot="content">
  <div class="container">
    <loading :active.sync="visible" :can-cancel="true"></loading>

    <div class="bBskShop" v-for="(shop, index) in listItemShop" :key="index">
      <div class="gBskShop">
        <a class="back">
          <div class="screen" :style="`
          background: url(${shop.ItemImage});
          background-position: center;
          background-size: cover;
          `"></div>
          <!-- <div class="video" style="
                      background: url('http://cabalbsk.com.br/giphy-min.gif') no-repeat;
                      background-position: center;
                      background-size: cover;
                      "></div> -->
          <h4>{{ $t('message.ItemShop.imgItem') }}</h4>
          <div class="bLTD" v-if="shop.ItemDuration != 0 && shop.ItemDuration != 31">
            <span>{{ itemDuration(shop.ItemDuration) }}</span>
          </div>
        </a>
        <div class="ContentItem">
          <div class="Item">
            <span>
              {{ shop.Description }}
            </span>
          </div>
          <div class="valueItem">
            Estoque: {{ shop.TotalStock }}
            <div class="iconCoin">
              <i class="fas fa-comment-dollar"></i>
            </div>
            <div class="valueCoin">
              Valor :
              {{
                shop.itemBuy > 0
                ? shop.itemBuy * shop.ItemPrice
                : shop.itemBuy == 0 || shop.itemBuy === " " || shop.itemBuy === undefined
                  ? shop.ItemPrice
                  : 0
              }}
            </div>
          </div>
          <div class="QtItem">
            <input type="number" name="buy" min="0" v-model.number="shop.itemBuy" max="10" />
            <button class="CpItem" type="submit" @click.prevent="buy(shop, index)">
              <i class="fas fa-box-check"></i>
            </button>
            <span><i class="fal fa-times"></i></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from '../../services/servicesAxios'
import itemDuration from '../../services/static/itemDuration'
import { mapGetters, mapActions } from 'vuex'
import refItemBuy from '../../services/itemBuy'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
export default {
  name: 'itemShop',
  components: {
    Loading,
  },
  data() {
    return {
      idRouter: this.$route.params,
      listItemShop: {},
      visible: false,
    }
  },
  computed: {
    Router() {
      return this.$route.params.id
    },
    ...mapGetters(['cashInAccountGetter', 'userAccount', 'gettersMoneyType', 'gettersCashCassino', 'cashInAccountGetter']),
    itemDuration: () => index => {
      return itemDuration.itemDuration[index]
    },
  },
  methods: {
    ...mapActions(['iteratorHandlerTypeMoney']),
    buy(itemBuy, index) {
      try {
        if ((itemBuy.ItemIdx > 0 || itemBuy.ItemIdx != undefined) && itemBuy.itemBuy > 0) {
          //Permissão de comprar do item
          if (itemBuy.TotalStock > 0) {
            //Soma Total do Valor
            let totalValueItems = itemBuy.itemBuy * itemBuy.ItemPrice
            let totalStock = itemBuy.TotalStock - itemBuy.itemBuy;
            //verifica se o cash da conta e suficiente para comprar o item
            const validCheckOutMoneyType = this.gettersMoneyType.find(value => value.CatName.toLowerCase() === itemBuy.CatName.toLowerCase());

            if (validCheckOutMoneyType.CatName.toLowerCase() === itemBuy.CatName.toLowerCase()) {
              //Antes disso aqui o codigo era assim
              /*totalValueItems < this.cashInAccountGetter;
              * E tbme nao tinha esse if acima
              */
              if (totalValueItems < validCheckOutMoneyType.typeCash.cash) {
                this.$swal({
                  title: this.$t('message.ItemShop.buyItem[0]'),
                  text: this.$t('message.ItemShop.buyItem[1]'),
                  icon: 'warning',
                  buttons: true,
                  dangerMode: true,
                }).then(async response => {
                  if (response) {


                    const checkBuy = await refItemBuy.buyItem(
                      itemBuy,
                      //this.cashInAccountGetter,
                      validCheckOutMoneyType.typeCash.cash,
                      totalValueItems,
                      this.userAccount.UserNum,
                      this.$loading.show({
                        loader: 'Spinner',
                        color: 'blue',
                      }),
                      this.$t('message.ItemShop.errorBuy'),
                      totalStock
                    )
                    if (checkBuy === 1) {
                      const total = validCheckOutMoneyType.typeCash.cash - totalValueItems
                      itemBuy.TotalStock = totalStock;
                      if (itemBuy.TotalStock === 0) {
                        this.listItemShop.splice(index, 1)
                      }
                      if (validCheckOutMoneyType.CatName.toLowerCase() === 'cassino'.toLowerCase()) {
                        this.$store.commit('cashCassionInAccountMut', { cash: total })
                        this.$store.commit('mutationsUpdateCashAndCassino', { cash: total, typeCash: 'cassino' })

                        const newTeste = this.gettersMoneyType.map(element => {
                          if (element.CatName.toLowerCase() === 'cassino') {
                            return { CatID: element.CatID, CatName: element.CatName, typeCash: { typeName: element.CatName.toLowerCase(), cash: element.typeCash.cash } }
                          }
                          return element
                        });
                        this.$store.commit(
                          'moneyTypeMut',
                          { item: newTeste }
                        )
                        return;
                      }

                      this.$store.commit('cashInAccountMut', { Cash: total })
                      this.$store.commit('mutationsUpdateCashAndCassino', { cash: total, typeCash: 'cash' })
                    }
                  } else {
                    this.$swal(this.$t('message.ItemShop.quitItem'))
                  }
                })
              } else {
                this.$swal(
                  this.$t('swal.typeMessage[0]'),
                  this.$t('swal.errorCash'),
                  'warning'
                )
              }
            }

          } else {
            this.$swal(
              this.$t('swal.typeMessage[0]'),
              this.$t('swal.errorQntItems'),
              'warning'
            )
          }
        } else {
          this.$swal('Atenção', 'Você não selecionou nenhuma quantidade de item para ser comprada!', 'warning')
        }
      } catch (error) {
        console.error(error)
        this.$swal(
          this.$t('swal.typeMessage[0]'),
          this.$t('swal.error'),
          'warning'
        )
      }

    },
    handlerTypeMoney() {
      this.iteratorHandlerTypeMoney()
    }
  },
  created() {
    axios
      .shopNormal(this.idRouter)
      .then(value => {
        if (value.status === 200) {
          this.listItemShop = value.data.Items
        } else {
          this.$swal(
            this.$t('swal.typeMessage[0]'),
            this.$t('swal.not_foundItems'),
            'warning'
          )
        }
      })
      .catch(err => {
        if (err.response !== undefined) {
          if (err.response.status == 404) {
            this.$swal(
              this.$t('swal.typeMessage[0]'),
              this.$t('swal.not_foundItems'),
              'warning'
            )
            return
          }
          this.$swal(
            this.$t('swal.typeMessage[0]'),
            err.response.data.msg,
            'warning'
          )
          return
        }



        throw new Error(`Error Connect in API: ${err}`)
      })

    // if(itemShop.status === 200) this.listItemShop = itemShop.data
  },
}
</script>
<style scoped lang="scss">
@import '../../assets/scss/md/_shop.scss';

.iconCoin {
  width: 18px !important;
}
</style>
