export default {
  message: {
    Login: {
      btnEnter: 'Enter',
      idAlertLenght: 'ID must contain at least ',
      idAlertLenghtFinal: 'characters.',
      passAlertLenght: 'The password must contain at least ',
      passAlertLenghtFinal: ' characters.',
      passLast1: 'Problems with the ',
      passLast2: 'password',
      txtNewAccount: "Don't have an account yet?",
      txtNewAccountLink: 'Join Now',
      txtTitle: "IT'S AWESOME",
      txtDesc: 'To having you here!',
      direitos: 'All rights reserved - Apoena',
    },
    RecoveryPass: {
      txtRecPass: "Let's recover your password ",
      txtReqPass: 'Request to recover password!',
      txtRecId: 'Forgot your',
      txtRecId2: 'Login ID',
      btnReqPass: 'Request password change ',
      btnRecPass: 'Recovery Password',
      txtLabelOr: 'OR',
      txtGoBack: 'I just want to go back and do',
      txtGoBackLink: 'Login',
      inputTxtId: 'ID Login',
      inputTxtMail: 'Registered E-Mail',
      inputTxtPassword: 'Password',
    },
    RecoveryID: {
      txtRecId: "Let's retrieve your Login ID",
      txtGoBack: 'I just want to go back and do the',
      txtGoBackLink: 'Login',
      btnRecId: 'RETRIEVE ID',
      txtLabelOr: 'OR',
      inputTxtMail: 'Registered E-Mail',
    },
    Register: {
      txtTitle: 'YOUR JOURNEY WILL BEGIN',
      txtAlertIDlenght: 'The user must have at least',
      txtAlertIDlenght2: 'digits.',
      txtAlertPasslenght: 'The password must have at least',
      txtAlertPasslenght2: 'digits.',
      txtAlertMailExists: 'E-Mail already in our database!',
      txtAlertMailInvalid: 'Invalid email!',
      labelID: 'ID Login',
      inputPlaceholderID: 'User name',
      labelPass: 'PASSWORD',
      inputPlaceholderPass: 'Create a new password',
      labelMail: 'E-MAIL',
      inputPlaceholderMail: 'Please enter a valid E-Mail',
      btnRegister: 'JOIN NOW',
      txtLabelOr: 'OR',
      txtBackToLogin: 'Already have an account?',
      txtBackToLoginLink: 'Login',
    },
    Activation: {
      txtTitle: "Let's Activate Your Login ID ",
      txtAlertRequestCode: 'Code is Required!',
      txtAlertCodeLenght: 'The Code must contain at least',
      txtAlertCodeLenght2: 'digits.',
      txtAlertRequestID: 'Login ID Required!',
      txtAlertIDlenght: 'Login ID must contain at least',
      txtAlertIDlenght2: 'digits.',
      inputPlaceholderRequestCode: 'Activation Code',
      inputPlaceholderRequestID: 'Enter Your Login',
      btnActivation: 'Activate Account',
      txtLabelOr: 'OR',
      txtBackToLogin: 'I just want to go back and',
      txtBackToLoginLink: 'Login',
    },
    SideBar: {
      txtDashboard: 'Dashboard',
      txtAccount: 'Manage Account',
      txtTransfer: 'Transfers',
      txtShop: 'Cabal Store',
      txtDonate: 'Donations',
      txtRanking: 'Rank',
      txtNotes: 'Official Notes',
      activePremium: 'ACTIVE PREMIUM',
    },
    TopNavbar: {
      labelSearch: 'Search',
      txtNotifications: 'Notifications',
    },
    ContentFooter: {
      LinkRules: 'Rules of Conduct',
      LinkPolitics: 'Monetary Policy',
      LinkSupp: 'Support',
      LinkDevs: 'Developers',
    },
    Dashboard: {
      txtPlayerOnTitle: 'CONNECTED PEOPLE',
      txtPropPlayerOn: 'Players connected to the server',
      txtCountPlayerOn: 'PLAYING',
      txtPeriodoPremium: 'PREMIUM PERIOD',
      txtCountPremiumDays: 'Days Remaining',
      txtPropPremium: 'Track your premium period',
      txtEventsTitle: 'ACTIVE EVENTS',
      txtCountEvents: 'Active Events',
      txtPropEvents: 'Details of active events',
      txtAlzs: "ALZ's IN THE ACCOUNT",
      penultimateAlzs: "Alz's last record from your account",
      reportPlayer: 'Report a Player',
      system: 'System',
      character: 'Character',
      chatAndOthers: 'Chat, Friends, Guild Group',
      account: 'account',
      chargeAndOther: ['Charge', 'Item store'],
      topicsRecent: 'RECENT TOPICS',
      mostViewFaqs: "Most viewed FAQ's",
      temporaryBlock: [
        'End of Temporary Block',
        `If your temporary block has expired, please submit a ticket..`,
      ],
      deleteCharacter: [
        'Recover deleted character',
        `There are numerous security measures that prevent players`,
      ],
      items: [
        'Item breakage by mistake or enhancement',
        `We generally do not restore broken items`,
      ],
      purchaseErrorItems: [
        'Wrong purchase from Item Shop',
        `We would like to remind all players that we don't do...`,
      ],
      passwordInventory: [
        'Inventory Sub-Password',
        `Please be advised that to reset your inventory sub-password, simply provide a new password and click the button below...`,
      ],
      missionDelete: [
        'Recover Deleted Quests',
        `To recover deleted missions, follow these steps:`,
      ],
      questionsGames: [
        'Questions about the game',
        `Unfortunately, we are unable to provide information of this nature...`,
      ],
      subPasswords: [
        'Sub-Password',
        `To unlock or reset your sub-password as well as change your Question...`,
      ],
      expired: "session expired because you couldn't find the character's cash",
    },
    Developers: {
      credits: [
        'CREDITS ARE ALWAYS WELL RECEIVED BY THE WHOLE COMMUNITY',
        'Code Credits & Rights',
      ],
      txtCredits: [
        `We studied a lot over the years to make the idea that Apoena came up with, 
                hours of lost sleep resulted in the excellent work you are doing at that moment.`,
        `We're certainly not pioneers in platform logic, so we'll be highlighting nick-names
                from developers who made it possible since the first inspiration as early code that have made
                our systems serve their purposes.`,
      ],
    },
    Donate: {
      financial: ['NAVIGATE FINANCIAL', 'DONATION'],
      donate: 'Make a new donation',
      historyDonate: 'Donation history',
      metaDonate: 'Donation Goal',
      support: 'Support',
      trackUDonate: 'Keep track of your donation!',
      toolsDonate: 'DONATION MONITORING TOOLS',
      filterDonate:
        'In addition to being filtered, these actions are recorded.',
    },
    Transfers: {
      transf: ['WANT TO DOWNLOAD WHAT?', 'TRANSFER'],
      sendCharacter: 'Submit a Character',
      receiveCharacter: 'Receive a character',
      txtTransf: 'Choose, Define, Transfer and Receive!',
      supportAndOthers:
        'SAFELY WE PROVIDE SUPPORT ON YOUR TRANSACTION, FAST AND EASY',
      taxaServer: 'This tool is Server Taxed.',
    },
    Shop: {
      reportCommercial: 'COMMERCIAL REPORT',
      txtDados: 'Some data is not updated in real time',
      ChooseCategoryAndOther: ['CHOOSE A CATEGORY', 'STORE'],
      buyItem: 'Your purchases are made in real time',
      security: 'USE ALL SAFETY TO MAKE YOUR PURCHASES',
      footer: [
        'Our ',
        'store',
        'It has log and backup systems for your safety.',
      ],
      tpoint: 'Not implemented yet.',
    },
    Rules: {
      rules: [
        'BE WILLING TO FOLLOW THE RULES OF CONDUCT AND SERVER TERMS',
        'RULES & TERMS OF USE',
        'YOU CAN',
        'The server may set permission topics!',
        'YOU CANNOT',
        'TERMS OF USE',
        'The server will be able to create a long text defining the Terms of Use and Specification of the rules of conduct!',
      ],
      txtRules: 'We reserve the right to change any line without prior notice!',
    },
    Rank: {
      memoPlayers: 'MEMO OF THE LARGEST NEVARETH ARE LISTED HERE',
      character: 'CHARACTERS',
      tableListPlayers: ['Player Name', 'Class', 'Level', 'Honor', 'Nation'],
      guilds: 'GUILDS',
      tableListGuilds: ['Guild Name', 'Level', 'Leader', 'Points'],
      security: 'We will not affect the position of the members listed here!',
      internalError: 'Internal System Error',
    },
    Politics: {
      politics: [
        'OUR SERVER IS TRANSPARENT, READ OUR MONETARY POLICY AND UNDERSTAND',
        'MONETARY POLICY',
        `The server will be able to create a long text defining the 
                specifications of the Monetary Policies that the server will give!`,
      ],
      txtProps: 'We reserve the right to change any line without prior notice!',
    },
    News: {
      txtProp: 'We will soon support Fan Sites!',
      news: ['FOLLOW OUR SERVER', 'NEWS & UPDATES'],
    },
    Account: {
      ip: 'None at the moment',
      dadosHours: ['Be', 'years', 'days', 'months', 'hours', 'minutes'],
      txtProp: 'Tool',
      managementAccount: 'MANAGE EFFICIENTLY',
      lastLogin: ['Last Login', 'Back'],
      lastIpLogin: 'Last Login IP',
      dados: 'since',
      AccountStatus: ['Account Status', 'Verified', 'Not Verified'],
      timePlaying: ['Time Played', 'Hours', 'Minutes', 'Seconds'],
      txtProps2: 'Some data is not updated in real time',
      toolsBox: ['TOOLBOX', 'TOOLS'],
      dataAccount: 'Account Data',
      mycharacters: 'My characters',
      points: 'Distribute Points',
      mySecurity: 'My security',
      clearPk: 'Clear the PK',
      chooseTools: 'Choose which tool to use.',
      toolsPlan: 'TOOL IN FORE PLAN',
    },
    Footer: {
      footer: 'Free',
    },
    Payment: {
      //["Attention we are Processing Your Payment!","Donate processing done successfully!.","Error Processing Payment! Contact your administrator."]
      payment: [
        'Attention we are Processing Your Payment!',
        'Donate processing done successfully!.',
        'Error Processing Payment! Contact your administrator.',
      ],
    },
    Pk: {
      chooseCharacter: ['Select a Character', 'Choose character'],
      charPk: 'you are penalized',
      clearPk: 'Clear my PK for 250 T-Point',
      cashTpoint: 'Balance available in T-Point',
    },
    ItemShop: {
      imgItem: 'Item Image',
      buyItem: [
        'Are you sure to buy these Items?',
        'Do you really want to buy these items?',
      ],
      errorBuy: 'Error purchasing item, Contact administrator.',
      quitItem: 'You are giving up the Purchase by doing this!',
    },
    AccInfo: {
      rootEmail: 'My root email',
      changedEmail: 'CHANGE E-MAIL',
      securityTwo: 'Two-Step Security',
      datePeople: 'Personal data',
      txtBigger: [
        'Recommended Reading',
        'We value your safety!',
        `It's no use putting CAPTCHA everywhere, malicious people will keep registering accounts and stressing our servers.`,
        `It is important that you provide REAL DATA, as the condition of your account must be respected by us.`,
        `Unverified accounts with enhanced security disabled will be banned with an unpublished time period!`,
        'ABOUT EMAIL',
        'We will not accept temporary emails or emails from strange domains!',
        `If you use temporary email, you have a period of 30 days after the account creation to make the change to a secure email.`,
      ],
    },
    AccSec: {
      security: ['Two-Step Security', 'Two-Step Security is disabled!'],
      accountSec: [
        'How accurate is it to activate my personal number?',
        'It is important, as in addition to enhancing your security, we will be able to inform you of any changes and validations.',
        `For us, it is important to help our digit to identify with formula reinforcement the efficiency of false account interruptions managed by people that go beyond bad intention.`,
        `With ACTIVE Two-Step Security you will be able to create Mule Account which can be managed by your dashboard!`,
      ],
    },
    CategoryShop: {
      txt: 'Premium Services',
    },
    ChangedToEmail: {
      propTxt: 'Change e-mail',
    },
    Characters: {
      delete: 'Delete Character',
    },
    DataPeople: {
      name: 'YOUR FULL NAME',
      changeName: 'Change Name',
      password: 'CURRENT PASSWORD',
      changedPassword: 'Change Password',
      reworkPassword:
        'If you have lost or forgotten your password, Logout and Recover it.',
      subpass: 'CURRENT SUB-PASSWORD',
      changeSubPass: 'Change Sub-Password',
      btn: 'SAVE EDITIONS',
    },
    DistPoints: {
      chooseCharacter: ['Select a Character', 'Choose Character'],
      dist: 'Points to/Distribute',
      points: 'Current Points in',
    },
    EnvChar: {
      chooseChar: [
        'SELECT A CHARACTER',
        'Select the character you chose to send from your list of created characters.',
      ],
      gerarBtn: 'GENERATE CODE',
    },
    HistDonate: {
      apoio: [
        "It looks like you still don't support us.",
        'Support us, of some value for the server to keep up and running!',
      ],
      tableList: [
        'index',
        'Donor Name',
        'Date',
        'Amount',
        'Status',
        'Payment Method',
      ],
      typeMoeda: 'USD',
    },
    SecurityTwo: {
      cell: 'Change Cell Number',
      btn: 'ENABLE BOOST',
      changeCell: 'phone successfully inserted/changed',
    },
    ReportCommercial: {
      balance: 'Balance',
    },
    RecChar: {
      inform: [
        'INPUT THE CODE',
        "Enter the Code of the Character you want to receive, make sure it hasn't expired!",
        'CODE',
      ],
      search: 'SEARCH',
      notGuild: 'Without guild',
      expireCode: 'The code expires on',
      receiveChar: [
        'You will receive: Character from the entire Inventory including the Alzes.',
        "Rank's positions will not be kept.",
        'You will not receive the Bearer/Guardian/War titles earned by the character.',
        'I have not received the weekly war points made until the transfer.',
        'It is mandatory that both characters are Without Guild.',
        'It is mandatory to have 1 FREE Character Slot!',
        'Value',
      ],
      chooseCharReplace: [
        'Select the character that will be replaced when receiving the new character.',
        'Choose a Character',
      ],
      btnFinished: 'END TRANSFER',
    },
    NovoDonate: {
      approvalPayment:
        'Payment Created Successfully, in a few minutes you will open another Tab for payment',
    },
    MetaDonate: {
      meta: [
        'Track our Goals & Objectives',
        `This tool was developed so that you can transparently monitor the Financial 
                Server and understand where your donated money will be allocated!`,
        'GOALS',
      ],
      definedMetas: [
        'The goals are defined by the Management Team, follow them.',
        'GOAL OF THE MONTH',
        'The goal is set to meet server costs, this month the donation goal is',
        'Goal set was completed.',
        'GOAL OF THE MONTH',
        'Set goal is in progress.',
        'Defined goal has not yet made progress.',
        'GOALS',
        'Goals are set so that we can bring UPDATES and also keep the server running with support and maintenance.',
        'It is extremely important that we comply with the goals set monthly so that your stay in our environment is always full of news and attractions.',
        'Here you have access to our goals and are part of our entire progression!',
        'SYSTEM THAT COSTS MONEY',
      ],
      progress: 'PROGRESSION',
      detailsPayment: ['Loaded', 'Monthly Goal', 'Recreational'],
    },
    InfoTransfer: {
      transfer: [
        'The generated code lasts for 24 hours.',
        'Your character, alz and the entire inventory will be transferred.',
        'DX ranks will be removed.',
        'If the character is Carrier or Guardian, he will lose the title.',
        'The weekly score of Guerra (TG) will be reset.',
        'It is mandatory that both characters are Guildless.',
      ],
    },
  },
  swal: {
    error: 'Internal error in the system',
    not_found_cash: 'No cash found in your account!',
    typeMessage: ['Warning', 'Error', 'Success'],
    not_found_char: "You don't have char registered in your account",
    error_character_in_account:
      'Error when searching for characters in your account. Please reload the page and try again.',
    not_found_dados:
      'Error, you failed to enter any data for the record, check again',
    successAcount:
      'Account created successfully! Check your email for account activation',
    alreadyAccount: 'User already registered!',
    alreadyEmail: 'This E-mail is already registered, use another one.',
    error_register_account:
      'Error in the User Registration Process, contact the Administrator.',
    checkDados:
      'Please check your creation details and fill them in correctly!',
    not_found_news: 'There is no news registered at the moment!',
    titleLogin: 'Login successfully',
    txtLogin: "Check out the news in the 'Official Notes' menu ",
    btnLogin: 'Go to panel',
    errorLogin: 'Something went wrong...',
    errorCredentials: 'Please check your credentials and try again',
    tryAgain: 'Try again',
    errorActiveAccount: 'Error activating account',
    activeAccount: 'Success activating account!',
    processingDonate:
      'Your donation is being processed! Please, wait a moment.',
    errorBD: 'Error Connecting to Database',
    notChooseChar: 'No character selected',
    errorCash: 'Insufficient Full Attention Cash To Purchase Item',
    errorQntItems:
      'Attention, you have not placed the amount of Items you would like to buy!',
    not_foundItems: 'There are no items registered in this Category!',
    changedEmail: 'Email Exchanged Successfully',
    errorEmail: [
      'Error Updating Your Email!',
      'refresh the page and try again!',
    ],
    noEmpty: 'fill the field correctly',
    dadosSave: 'Data Saved Successfully',
    dist: 'you entered invalid values in the distribution field.',
    selectChar: 'Error selecting Char!',
    insufficientPoint: 'Insufficient points.',
    errorDistPoint: 'Error Distributing Points!',
    zerorPoints: 'Put some value to be Distributed Other than 0',
    erroTransferChar: 'Error Transferring Char!',
    errorTransChar: 'Error in Transferring and Creating Character Code',
    errorChar: ['Character search error', 'No Characters Found'],
    graveError: 'Serious Error, Relog on Panel to access this function',
    not_foundDonate: "You haven't made a donation to the server yet!",
    erroCell: 'Error updating your phone!',
    errorReceiveChar:
      'A serious error has occurred, contact your administrator.',
    codeExpire: 'Code timed out, you cannot continue with this action',
    cashChar: 'To complete this action, you must have at least 5,000 cash.',
    confirmedChange: [
      'Do you want to Switch Characters?',
      'The character change will be done on top of the Chosen Char',
    ],
    notFoundCode: 'Code not found in database!',
    AttentionCode:
      'To complete this action you need to enter the code that was sent to you by the user who wants to change the sending of the char.',
    notFoundUser: 'Error Unable to identify your user! Please login again',
    apiConnectError: 'Error connecting with system API!',
    emptyDonate: 'The donation list is empty',
  },
  char: {
    nation: 'No Nation',
    class: ['WA/GL', 'BL', 'WI/DM', 'FA/FG', 'FS', 'FB'],
  },
  router: {
    routerName: 'My Account Details',
    routerSecurity: 'Account Security',
    routerChar: 'My characters',
    routerDist: 'Distribute Points',
    routerData: 'Account Data',
    routerPk: 'Reset PK',
    routerDonate: 'Donations',
    routerNewDonate: 'Make a new donation',
    routerHistDonate: 'Personal History of Donations',
    routerMetas: 'Monthly Donation Goals',
    routerTransfer: 'Transfers',
    routerSend: 'Transfer - Send a Character',
    routerReceive: 'Transfer - Receive a Character',
    routerCommunity: 'Community Monitoring',
    routerNotas: 'Official Notes',
    routerSupport: 'Support',
    routerReport: 'Report a Player',
    routerRead: 'Enjoy and have a good read',
    routerExit: 'Exit',
    routerPolitics: 'Monetary policy',
    routerTerm: 'Terms of Conduct',
    routerDev: 'Apoena Developers',
  },
}
