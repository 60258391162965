<template>
  <div class="md-layout">
    <div class="gTransf">
      <div class="bTransf-Title HdrAzEsv">
        {{ $t('message.RecChar.inform[0]') }}
      </div>
      <div class="cTransf">
        <p>
          {{ $t('message.RecChar.inform[1]') }}
        </p>
        <input type="text" :placeholder="$t('message.RecChar.inform[2]')" v-model.trim="code" />

        <button class="searchCode" type="submit" @click.prevent="searchChangeCharAccount($event)">
          <loading :active.sync="visible" :can-cancel="false"></loading>
          <i class="fas fa-search"></i> {{ $t('message.RecChar.search') }}
        </button>
      </div>

      <!-- -->
      <div class="gPersSelect" v-if="selectedChar">
        <div class="md-layout">
          <div class="
              md-layout-item md-medium-size-100 md-xsmall-size-100
              sm-size-100
              md-size-40
            ">
            <div class="gCharacterSelected">
              <div class="classIcon">
                <div :class="this.divClass"></div>
              </div>
              <div class="className">
                {{ this.charFetch.searchCode[0].Name }}
              </div>
              <div class="PersoName">
                {{ this.charFetch.Style }}
              </div>
              <div class="PersoLv">
                Lv. {{ this.charFetch.searchCode[0].LEV }}&nbsp;-
                {{ $t('message.RecChar.notGuild') }}
              </div>
            </div>
          </div>
          <div class="
              md-layout-item md-medium-size-100 md-xsmall-size-100
              sm-size-100
              md-size-60
            ">
            <div class="gCharacterSelected">
              <ul class="fa-ul">
                <li>
                  <span class="fa-li">
                    <i class="fas fa-caret-right"></i>
                  </span>
                  {{ $t('message.RecChar.expireCode') }}:
                  <span class="time">
                    <strong class="fontSize">Time left:
                      <countdown :time="this.charFetch.expireDate.Final -
                        this.charFetch.expireDate.finalDataSys
                        ">
                        <template slot-scope="props">{{ props.hours }}:{{ props.minutes }}:{{
                          props.seconds
                        }}
                          {{ checkHourTransfer(props) }}
                        </template>
                      </countdown>
                    </strong>
                  </span>
                </li>
                <li>
                  <span class="fa-li"><i class="fas fa-caret-right"></i></span>
                  {{ $t('message.RecChar.receiveChar[0]') }}
                </li>
                <li>
                  <span class="fa-li"><i class="fas fa-caret-right"></i></span>
                  {{ $t('message.RecChar.receiveChar[1]') }}
                </li>
                <li>
                  <span class="fa-li"><i class="fas fa-caret-right"></i></span>
                  {{ $t('message.RecChar.receiveChar[2]') }}
                </li>
                <li>
                  <span class="fa-li"><i class="fas fa-caret-right"></i></span>
                  {{ $t('message.RecChar.receiveChar[3]') }}
                </li>
                <li class="alt">
                  <span class="fa-li"><i class="fas fa-caret-right"></i></span>
                  {{ $t('message.RecChar.receiveChar[4]') }}
                </li>
                <li class="alt">
                  <span class="fa-li"><i class="fas fa-caret-right"></i></span>
                  {{ $t('message.RecChar.receiveChar[5]') }}
                </li>
                <li class="alt">
                  <span class="fa-li"><i class="fas fa-caret-right"></i></span>
                  {{ $t('message.RecChar.receiveChar[6]') }}: {{ priceChange }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- -->
      <div class="eTransf" v-if="selectedChar">
        <p>
          {{ $t('message.RecChar.chooseCharReplace[0]') }}
        </p>
        <select name="personagem" id="personagem" @change="selectecChar"
          :placeholder="$t('message.RecChar.chooseCharReplace[1]')" required>
          <option value="" selected disabled>{{
            $t('message.RecChar.chooseCharReplace[1]')
          }}</option>
          <option v-for="(valor, index) in this.charNotGuild" :key="index" :value="valor.CharacterIdx">
            {{ valor.Name }}
          </option>
        </select>
        <button class="activeButton" type="submit" @click.prevent="confirmationClick" v-if="selectedCharChange">
          {{ $t('message.RecChar.btnFinished') }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from '../services/servicesAxios'
import char from '../services/char'
import { mapGetters } from 'vuex'
import 'vue-loading-overlay/dist/vue-loading.css'
import Loading from 'vue-loading-overlay'
import refactor from '../services/refactor'
export default {
  name: 'receiver',
  components: {
    Loading,
  },
  data() {
    return {
      code: '',
      charFetch: {},
      selectedChar: false,
      charSelecionado: {},
      charNotGuild: {},
      selectedCharChange: false,
      btnClicked: false,
      isInvalid: true,
      priceChange: 3000,
      visible: false,
    }
  },
  computed: {
    ...mapGetters(['userAccount', 'cashInAccountGetter']),
    divClass() {
      return char.imgClass(char.style(this.charFetch.searchCode[0].Style))
    },
  },
  methods: {
    exchangeCharInAccount() {
      if (this.btnClicked) {
        if (this.cashInAccountGetter > this.priceChange) {
          if (this.isInvalid) {
            axios
              .exchangeCharInAccount({
                nameCharExchange: this.charFetch.searchCode[0].Name,
                namecharDesti: this.charSelecionado[0].Name,
                nameAccountDestino: this.userAccount.ID,
                code: this.code,
                CashAndPrice: {
                  price: this.priceChange,
                  totalCash: this.cashInAccountGetter,
                  userNum: this.userAccount.UserNum,
                },
              })
              .then(value => {
                if (value.data.code == 2) {
                  this.visible = refactor.changeInVirtualDomDisabledButtonAndActived(
                    'button.activeButton',
                    false
                  )
                  this.$swal(
                    this.$t('swal.typeMessage[1]'),
                    value.data.msg,
                    'error'
                  )
                    .then(() => {
                      //Renderiza a pagina novamente
                      setTimeout(() => {
                        this.$router.go(0)
                      }, 500)
                    })
                    .catch(() => {
                      setTimeout(() => {
                        this.$router.go(0)
                      }, 500)
                    })
                } else if (value.data.code == 3) {
                  this.$swal(
                    this.$t('swal.typeMessage[1]'),
                    value.data.msg,
                    'error'
                  )
                    .then(() => {
                      //Renderiza a pagina novamente
                      setTimeout(() => {
                        this.$router.go(0)
                      }, 500)
                    })
                    .catch(() => {
                      setTimeout(() => {
                        this.$router.go(0)
                      }, 500)
                    })
                } else if (value.data.code == -1) {
                  this.$swal(
                    this.$t('swal.typeMessage[1]'),
                    value.data.msg,
                    'error'
                  )
                    .then(() => {
                      //Renderiza a pagina novamente
                      setTimeout(() => {
                        this.$router.go(0)
                      }, 500)
                    })
                    .catch(() => {
                      setTimeout(() => {
                        this.$router.go(0)
                      }, 500)
                    })
                } else {
                  this.$store.commit('cashInAccountMut', {
                    Cash: this.cashInAccountGetter - this.priceChange,
                  })

                  this.$swal(
                    this.$t('swal.typeMessage[2]'),
                    value.data.msg,
                    'success'
                  )
                    .then(() => {
                      this.visible = refactor.changeInVirtualDomDisabledButtonAndActived(
                        'button.activeButton',
                        false
                      )
                      //Renderiza a pagina novamente
                      setTimeout(() => {
                        this.$router.go(0)
                      }, 500)
                    })
                    .catch(() => {
                      setTimeout(() => {
                        this.$router.go(0)
                      }, 500)
                    })
                }
              })
              .catch(err => {
                this.$swal(
                  this.$t('swal.typeMessage[1]'),
                  this.$t('swal.errorReceiveChar'),
                  'error'
                )
                throw new Error("Can't connect to API: " + err)
              })
          } else {
            this.$swal(
              this.$t('swal.typeMessage[0]'),
              this.$t('swal.codeExpire'),
              'warning'
            )
          }
        } else {
          this.$swal(
            this.$t('swal.typeMessage[1]'),
            this.$t('swal.cashChar'),
            'error'
          ).then(() => {
            this.visible = true
            this.$router.go(0)
          })
        }
      }
    },
    confirmationClick() {
      this.btnClicked = true
      this.visible = refactor.changeInVirtualDomDisabledButtonAndActived(
        'button.activeButton',
        true
      )
      this.$swal({
        title: this.$t('swal.confirmedChange[0]'),
        text: this.$t('swal.confirmedChange[1]'),
        icon: 'warning',
        buttons: true,
        dangerMode: true,
        closeOnClickOutside: false,
      }).then(value => {
        if (value === true) {
          setTimeout(() => {
            this.exchangeCharInAccount()
          }, 300)
        }
      })
    },
    checkHourTransfer(props) {
      if (props.hours == 0 && props.minutes == 0 && props.seconds == 0) {
        this.$swal(
          this.$t('swal.typeMessage[0]'),
          this.$t('swal.codeExpire'),
          'warning'
        )

        this.isInvalid = false
      }
    },
    selectecChar(event) {
      this.charSelecionado = char.selectCharInTransf(event, this.charNotGuild)

      if (this.charSelecionado[0].length != 0) {
        this.charSelecionado.imgClass = char.imgClass(
          this.charSelecionado[0].Style
        )
        this.selectedCharChange = true
      }
    },
    searchChangeCharAccount() {
      document
        .querySelector('button.searchCode')
        .setAttribute('disabled', 'disabled')
      this.visible = true
      if (this.code.length >= 5) {
        axios
          .searchCharForCode(this.code)
          .then(value => {
            if (value.status == 200 && value.data.code == 0) {
              this.charFetch = value.data
              this.selectedChar = true
              this.charFetch.Style = char.nomeClasse(
                this.charFetch.searchCode[0].Style
              )
              this.visible = false
              document
                .querySelector('button.searchCode')
                .removeAttribute('disabled')
              //
            } else if (value.status == undefined) {
              this.$swal(
                this.$t('swal.typeMessage[1]'),
                this.$t('swal.notFoundCode'),
                'error'
              )
              this.code = ''
              document
                .querySelector('button.searchCode')
                .removeAttribute('disabled')
            } else {
              this.code = ''
              this.$swal(
                this.$t('swal.typeMessage[1]'),
                this.$t('swal.notFoundCode'),
                'error'
              )
              this.visible = false
              document
                .querySelector('button.searchCode')
                .removeAttribute('disabled')
            }
          })
          .catch(err => {
            document
              .querySelector('button.searchCode')
              .removeAttribute('disabled')
            if (err.response.status === 404)
              this.$swal(
                this.$t('swal.typeMessage[1]'),
                this.$t('swal.notFoundCode'),
                'error'
              )
          })
      } else {
        this.visible = false

        this.$swal(
          this.$t('swal.typeMessage[1]'),
          this.$t('swal.AttentionCode'),
          'error'
        )
      }
    },
  },
  created() {
    if (this.userAccount.UserNum > 0 || this.userAccount.UserNum != undefined) {
      axios
        .searchCharNotGuild(this.userAccount.UserNum)
        .then(value => {
          this.charNotGuild = value.data.charNotGuild
        })
        .catch(err => {
          throw new Error(`Error In connect API ${err}`)
        })
    } else {
      this.$swal(
        this.$t('swal.typeMessage[1]'),
        this.$t('swal.notFoundUser'),
        'error'
      ).then(() => {
        axios.logout()
      })
    }
  },
}
</script>
<style scoped lang="scss">
@import '@/assets/scss/md/_RecChar.scss';

.fontSize {
  font-size: 1.2em;
}
</style>
