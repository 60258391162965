<template>
  <div class="flex_div">
    <ul>
      <li class="item" v-for="(value, index) in moneyType" :key="index">
        <span>
          Valor {{ value.CatName }}
        </span>
        <span class="valor">
          {{ value.typeCash.cash }}
        </span>
      </li>
    </ul>
    <!-- <div class="divTableCell-Left">
      {{ $t('message.ReportCommercial.balance') }} Cash
    </div>
    <div class="divTableCell-Right">{{ this.cashInAccountGetter }}</div>

    <div class="divTableCell-Left">
      {{ $t('message.ReportCommercial.balance') }} T-Point
    </div>
    <div class="divTableCell-Right">{{ $t('message.Shop.tpoint') }}</div>

    <div class="divTableCell-Left">
      {{ $t('message.ReportCommercial.balance') }} Alzs
    </div>
    <div class="divTableCell-Right">
      {{ parseInt(this.AlzsWarehouse) + parseInt(this.alzsTotal) }}
    </div>
    <div class="divTableCell-Left">
      Saldo Cassino
    </div>
    <div class="divTableCell-Right">
      {{ this.cassino }}
    </div> -->
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ReportCommercial',
  data() {
    return { alzsTotal: 0 }
  },
  computed: {
    ...mapGetters(['cashInAccountGetter', 'AlzsTotalChar', 'AlzsWarehouse', 'gettersCashCassino', 'gettersMoneyType']),
    cassino() {
      return this.gettersCashCassino > 0 ? this.gettersCashCassino : 0
    },
    moneyType() {
      return this.gettersMoneyType
    }
  },
  async mounted() {
    for (let i = 0; i < this.AlzsTotalChar.length; i++) {
      let alz = parseInt(this.AlzsTotalChar[i].Alz)
      this.alzsTotal = parseInt(this.alzsTotal) + parseInt(alz)
    }
  },
}
</script>
<style scoped lang="scss">
@import '../assets/scss/md/_shop.scss';

.flex_div {
  display: flex;
  flex-direction: column;

  ul {
    display: flex;
    gap: 5px;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    padding: 0;

    word-wrap: break-word;
    list-style: none;

    li.item {
      display: flex;
      justify-content: space-between;
      width: 100%;
      border-top: 1px solid #ededed;
      padding: 5px 0;

      span.valor {
        width: 50%;
        color: #1976d2;
        cursor: pointer;
      }
    }
  }
}
</style>
