<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 sm-size-100 md-size-30"
      >
        <!-- - -->
        <bsk-stats-card data-background-color="yellow">
          <template slot="header">
            <i class="fad fa-cash-register"></i>
          </template>

          <template slot="content-title">
            <p class="category">{{ $t('message.Donate.financial[0]') }}</p>
            <h3 class="title">{{ $t('message.Donate.financial[1]') }}</h3>
          </template>

          <template slot="content">
            <div class="divTable">
              <div class="divToolboxCell-Left">
                <router-link
                  :to="{ path: '/painel/donate/novo' }"
                  :key="$router.path"
                >
                  {{ $router.path }}<i class="fas fa-hand-sparkles"></i>
                  {{ $t('message.Donate.donate') }}
                </router-link>
              </div>
              <div class="divToolboxCell-Left">
                <router-link
                  :to="{ path: '/painel/donate/historico' }"
                  :key="$router.path"
                >
                  {{ $router.path }}<i class="fas fa-search-dollar"></i>
                  {{ $t('message.Donate.historyDonate') }}
                </router-link>
              </div>
              <!-- <div class="divToolboxCell-Left">
                <router-link
                  :to="{ path: '/painel/donate/meta' }"
                  :key="$router.path"
                >
                  {{ $router.path }}<i class="fas fa-medal"></i>
                  {{ $t('message.Donate.metaDonate') }}
                </router-link>
              </div> -->
              <!-- <div class="divToolboxCell-Left">
                <router-link :to="{ path: '/' }" :key="$router.path">
                  {{ $router.path }}<i class="fas fa-question-circle"></i>
                  {{ $t('message.Donate.support') }}
                </router-link>
              </div> -->
            </div>
          </template>

          <template slot="footer">
            <footers
              classCss="stats"
              mdIcon="info"
              :textProp="$t('message.Donate.trackUDonate')"
              :check="true"
            ></footers>
          </template>
        </bsk-stats-card>
      </div>
      <!-- -->
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-70"
      >
        <bsk-stats-card data-background-color="yellow">
          <template slot="header">
            <i class="fad fa-smile-beam"></i>
          </template>

          <template slot="content-title">
            <p class="category">
              {{ $t('message.Donate.toolsDonate') }}
            </p>
            <h3 class="title">{{ $route.name }}</h3>
          </template>

          <template slot="content">
            <router-view />
          </template>

          <template slot="footer">
            <footers
              classCss="stats"
              mdIcon="info"
              :textProp="$t('message.Donate.filterDonate')"
              :check="true"
            ></footers>
          </template>
        </bsk-stats-card>
      </div>
      <!-- -->
    </div>
  </div>
</template>

<script>
import { BskStatsCard } from '@/components'
import footers from '../components/Footers/Footer'
export default {
  components: {
    BskStatsCard,
    footers,
  },
  beforeUpdate() {
    document
      .querySelector('.router-link-active')
      .classList.add('nav-item', 'active')
    document.body.scrollTop = document.documentElement.scrollTop = 0
  },
}
</script>
<style scoped lang="scss">
@import '../assets/scss/md/_Donate.scss';
</style>
