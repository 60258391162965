<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 sm-size-100 md-size-100"
    >
      <div
        class="NoHist"
        v-if="history.length === undefined || this.check === false"
      >
        <div class="icon"><i class="fal fa-frown"></i></div>
        <div class="TxtUndef">
          <p>Ops, {{ $t('message.HistDonate.apoio[0]') }}</p>
          <small>{{ $t('message.HistDonate.apoio[1]') }}</small>
        </div>
      </div>
    </div>

    <!-- INÍCIO da CONDIÇÃO EXISTENTE -->
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 sm-size-100 md-size-100"
    >
      <div class="wrapper" v-if="this.check === true">
        <div class="table">
          <div class="row header">
            <div class="cell">
              # {{ $t('message.HistDonate.tableList[0]') }}
            </div>
            <div class="cell">
              {{ $t('message.HistDonate.tableList[1]') }}
            </div>
            <div class="cell">
              {{ $t('message.HistDonate.tableList[2]') }}
            </div>
            <div class="cell">
              {{ $t('message.HistDonate.tableList[3]') }}
            </div>
            <div class="cell">
              {{ $t('message.HistDonate.tableList[4]') }}
            </div>
            <div class="cell">
              {{ $t('message.HistDonate.tableList[5]') }}
            </div>
          </div>

          <div class="row" v-for="(hist, index) in history" :key="index">
            <div class="cell" data-title="#">{{ hist.id }}</div>
            <div class="cell" data-title="Nome">
              {{ hist.NameAccount }}
            </div>
            <div class="cell" data-title="Data">
              {{
                new Date(hist.created_at + ' ')
                  .toLocaleString('pt-br')
                  .split(' ')[0]
              }}
            </div>
            <div class="cell" data-title="Valor">
              {{ $t('message.HistDonate.typeMoeda') }}{{ hist.valueDonation }}
            </div>
            <div class="cell" data-title="Status">
              <span v-if="hist.status === 'approved'" class="DonAprovado"
                ><i class="far fa-check-circle"></i
              ></span>
              <span
                v-else-if="hist.status === 'disapproved'"
                class="DonReprovado"
                ><i class="far fa-times"></i
              ></span>
              <span v-else class="DonPendente"
                ><i class="fad fa-spinner"></i
              ></span>
            </div>
            <div class="cell" data-title="Metodo">
              Mercado Pago
            </div>
          </div>
          <!-- Reply row -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'history',
  data() {
    return { history: {}, check: false }
  },
  computed: {
    ...mapGetters(['userAccount', 'historyDonateGetter']),
  },
  methods: {
    ...mapActions(['historyDonateAction']),
  },
  async mounted() {
    const hist = await this.historyDonateAction(this.userAccount.ID)
    if (hist === 1) {
      this.history = this.historyDonateGetter
      this.check = true
    } else {
      this.check = false
      this.$swal(
        this.$t('swal.typeMessage[0]'),
        this.$t('swal.not_foundDonate'),
        'warning'
      )
    }
  },
}
</script>
<style scoped lang="scss">
@import '../assets/scss/md/_HistDonate.scss';
</style>
