<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 sm-size-100 md-size-30"
      >
        <!-- - -->
        <bsk-stats-card data-background-color="pink">
          <template slot="header">
            <i class="fab fa-redhat "></i>
          </template>

          <template slot="content-title">
            <p class="category">TÓPICOS POR CATEGORIA</p>
            <h3 class="title">CATEGORIA</h3>
          </template>

          <template slot="content">
            <div class="divTable">
              <div class="divToolboxCell-Left">
                <router-link
                  :to="{ path: '/painel/faqs/cat/1' }"
                  :key="$router.path"
                >
                  {{ $router.path
                  }}<i class="fas fa-skull-crossbones"></i> Denuncie um Jogador
                </router-link>
              </div>
              <div class="divToolboxCell-Left">
                <router-link to="" :key="$router.path">
                  {{ $router.path
                  }}<i class="fas fa-exclamation-triangle"></i> Sistema
                </router-link>
              </div>
              <div class="divToolboxCell-Left">
                <router-link to="" :key="$router.path">
                  {{ $router.path }}<i class="fas fa-user-alien"></i> Personagem
                </router-link>
              </div>
              <div class="divToolboxCell-Left">
                <router-link to="" :key="$router.path">
                  {{ $router.path }}<i class="fas fa-headset"></i> Chat, Amigos,
                  Grupo ou Guilda
                </router-link>
              </div>
              <div class="divToolboxCell-Left">
                <router-link to="" :key="$router.path">
                  {{ $router.path }}<i class="fas fa-question-circle"></i> Conta
                </router-link>
              </div>
              <div class="divToolboxCell-Left">
                <router-link to="" :key="$router.path">
                  {{ $router.path
                  }}<i class="fas fa-search-dollar"></i> Cobrança ou Loja de
                  Itens
                </router-link>
              </div>
              <div class="divToolboxCell-Left">
                <router-link to="" :key="$router.path">
                  {{ $router.path }}<i class="fas fa-bug"></i> Bug Bounty
                  Program
                </router-link>
              </div>
            </div>
          </template>

          <template slot="footer">
            <footers
              classCss="stats"
              mdIcon="info"
              textProp="Reporte Um jogador!"
            ></footers>
          </template>
        </bsk-stats-card>
      </div>
      <!-- -->
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-70"
      >
        <bsk-stats-card data-background-color="yellow">
          <template slot="header">
            <i class="fad fa-info-circle"></i>
          </template>

          <template slot="content-title">
            <p class="category">
              ANTES DE ABRIR UM NOVO TICKET, LEIA AS PERGUNTAS & RESPOSTAS
            </p>
            <h3 class="title">{{ $route.name }}</h3>
          </template>

          <template slot="content">
            <router-view />
          </template>

          <template slot="footer">
            <div class="stats">
              <md-tooltip md-direction="bottom">
                <span
                  >Para agilizar a fila de Suporte, nós oferecemos um acervo
                  sempre atualizado. <br />
                  Uma biblioteca das melhores e com embasamento original.</span
                >
              </md-tooltip>
              <i class="fas fa-question-circle"></i> Entenda essa seção!
            </div>
          </template>
        </bsk-stats-card>
      </div>
      <!-- -->
    </div>
  </div>
</template>

<script>
import { BskStatsCard } from '@/components'
import footers from '../components/Footers/Footer'
export default {
  components: {
    BskStatsCard,
    footers,
  },
  beforeUpdate() {
    document
      .querySelector('.router-link-active')
      .classList.add('nav-item', 'active')
    document.body.scrollTop = document.documentElement.scrollTop = 0
  },
}
</script>
<style scoped lang="scss">
@import '../assets/scss/md/_Faqs.scss';
</style>
