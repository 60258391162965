// =========================================================
// * Morket Panel from Private Server R.1
// =========================================================
// * Coded by Tiago, Joel, Pablo
// ---------------------------------------------------------
import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App'
import Loading from 'vue-loading-overlay'
import VueCountdown from '@chenfengyuan/vue-countdown'

// router setup
import routes from './routes/routes'
import servicesAxios from './services/servicesAxios'
import store from './store/Vuex'
import Vuelidate from 'vuelidate'
import VueSwal from 'vue-swal'
//Translation
import i18n from './locale/'
// Plugins
import GlobalComponents from './globalComponents'
import GlobalDirectives from './globalDirectives'
import Notifications from './components/NotificationPlugin'

// MaterialDashboard plugin
import MaterialDashboard from './material-dashboard'

// configure router
let router = new VueRouter({
  routes, // short for routes: routes
  linkExactActiveClass: 'nav-item active',
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!localStorage.getItem('user-data')) {
      next({
        path: '/',
      })
    } else {
      next()
    }
  } else if (localStorage.getItem('user-data')) {
    next({
      path: '/painel',
    }) // make sure to always call next()!
  } else {
    next()
  }
})

Vue.use(VueRouter)
Vue.use(MaterialDashboard)
Vue.use(GlobalComponents)
Vue.use(GlobalDirectives)
Vue.use(Notifications)
Vue.use(VueSwal)
Vue.use(Vuelidate)
Vue.use(Loading)
Vue.component(VueCountdown.name, VueCountdown)

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: (h) => h(App),
  i18n,
  servicesAxios,
  store,
  router,
  data: {},
})
