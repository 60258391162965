<template>
  <div>
    <input
      :type="proInput1"
      :id="propIdInput1"
      class="BskInput"
      :placeholder="propInput1PlaceHolder"
      v-model.lazy="this.userAccount.Email"
      disabled
      v-if="boole"
    />
    <input
      :type="proInput1"
      :id="propIdInput1"
      class="BskInput"
      :placeholder="propInput1PlaceHolder"
      v-model.lazy="valorInput"
      v-else
      @change.prevent="send()"
    />{{ check }}
    <div class="iconBsk"><i class="far fa-at"></i></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'tagInputs',
  props: {
    proInput1: {
      type: String,
      required: true,
    },
    propIdInput1: {
      type: String,
      required: true,
    },
    propInput1PlaceHolder: {
      type: String,
      required: true,
    },
    boole: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return { valorInput: '' }
  },
  computed: {
    ...mapGetters(['checkValueInput', 'userAccount', 'checkInputEmpty']),
    check() {
      return this.checkValue()
    },
  },
  methods: {
    send() {
      this.$emit('send-value-Input', { value: this.valorInput })
    },
    checkValue() {
      if (this.checkValueInput) {
        this.valorInput = ''
        setTimeout(() => {
          this.$store.commit('checkInputEmpty', false)
        }, 500)
        return
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/md/_AccInfo.scss';
</style>
