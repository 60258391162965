import { render, staticRenderFns } from "./ItemShop.vue?vue&type=template&id=03fcbd62&scoped=true&slot=content&"
import script from "./ItemShop.vue?vue&type=script&lang=js&"
export * from "./ItemShop.vue?vue&type=script&lang=js&"
import style0 from "./ItemShop.vue?vue&type=style&index=0&id=03fcbd62&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03fcbd62",
  null
  
)

export default component.exports