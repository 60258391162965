export default {
  itemDuration: {
    1: '1 H.',
    2: '2 H.',
    3: '3 H.',
    4: '4 H.',
    5: '5 H.',
    6: '6 H.',
    7: '7 H.',
    8: '8 H.',
    9: '1 D.',
    10: '3 D.',
    11: '5 D.',
    12: '7 D.',
    13: '10 D.',
    14: '14 D.',
    15: '15 D.',
    16: '20 D.',
    17: '30 D.',
    18: '45 D.',
    19: '60 D.',
    20: '90 D.',
    21: '100 D.',
    22: '120 D.',
    23: '180 D.',
    24: '270 D.',
    25: '365 D.',
    26: '1 D.',
    27: '???',
    28: '???',
    29: '???',
    30: '???',
    31: 'Permanent',
  },
}
