<template>
  <div class="md-layout">
    <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 sm-size-100 md-size-100">
      <div class="row fix-padding-1-0">
        <label class="labelPerson">{{
            $t('message.DistPoints.chooseCharacter[0]')
        }}</label>
      </div>
    </div>
    <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 sm-size-100 md-size-100">
      <div class="row fix-padding-1-0">
        <select name="personagem" id="personagem" placeholder="Escolha um Personagem" v-on:change="selectedChar($event)"
          required>
          <option value="" selected disabled>{{
              $t('message.DistPoints.chooseCharacter[1]')
          }}</option>
          <option v-for="char in this.charactersAccoun" :key="char.CharacterIdx" :value="char.CharacterIdx">
            {{ char.Name }}
          </option>
        </select>
      </div>
    </div>
    <div class="md-layout" v-if="charSelected.Name">
      <div class="md-layout-item md-medium-size-30 md-xsmall-size-100 sm-size-100 md-size-30">
        <div class="ChartCard">
          <div class="iconClass">
            <div :class="iconClass.class"></div>
            <!-- DU, MA, EA, AA, GU, GA -->
          </div>
          <div class="nameChr">
            {{ charSelected.Name }}
          </div>
          <div class="classNameTag">{{ iconClass.name }} Lv. {{ charSelected.LEV }}</div>
          <div class="infoPNT">
            {{ $t('message.DistPoints.dist') }}: <b>{{ charSelected.PNT }}</b>
          </div>
        </div>
      </div>
      <div class="md-layout-item md-medium-size-70 md-xsmall-size-100 sm-size-100 md-size-70">
        <div class="ChartCard">
          <div class="md-layout chartContent">
            <div class="md-layout-item md-xsmall-size-100 sm-size-100 md-medium-size-33 md-size-33 chartCardTxt">
              STR <br />
              <input type="number" name="STR" v-model.number="$v.pointSTR.$model" min="0" :max="charSelected.PNT" id=""
                @change="checkInputValue($v.pointSTR.$model)" />
            </div>
            <div class="md-layout-item md-xsmall-size-100 sm-size-100 md-medium-size-33 md-size-33 chartCardTxt">
              INT <br />
              <input type="number" name="INT" v-model.number="$v.pointINT.$model" min="0" :max="charSelected.PNT"
                id="" />
            </div>
            <div class="md-layout-item md-xsmall-size-100 sm-size-100 md-medium-size-33 md-size-33 chartCardTxt">
              DEX <br />
              <input type="number" name="DEX" v-model.number="$v.pointDEX.$model" min="0" :max="charSelected.PNT"
                id="" />
            </div>
          </div>
          <br />
          <div class="md-layout chartContent">
            <div class="md-layout-item md-xsmall-size-100 sm-size-100 md-medium-size-33 md-size-33 chartCardTxt">
              <small>{{ $t('message.DistPoints.points') }} <b>STR</b></small><br />{{ charSelected.STR }}
            </div>
            <div class="md-layout-item md-xsmall-size-100 sm-size-100 md-medium-size-33 md-size-33 chartCardTxt">
              <small>{{ $t('message.DistPoints.points') }} <b>INT</b></small><br />{{ charSelected.INT }}
            </div>
            <div class="md-layout-item md-xsmall-size-100 sm-size-100 md-medium-size-33 md-size-33 chartCardTxt">
              <small>{{ $t('message.DistPoints.points') }} <b>DEX</b></small><br />{{ charSelected.DEX }}
            </div>
          </div>
          <div class="md-layout chartContent">
            <div class="md-layout-item md-xsmall-size-100 sm-size-100 md-medium-size-100 md-size-100 chartCardBtn">
              <button class="DomClass" type="submit" @click.prevent="saveThePoints">
                <i class="far fa-save"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { required, minLength, numeric } from 'vuelidate/lib/validators/'
import refactor from '../services/refactor'
export default {
  name: 'distpts',
  validations: {
    pointSTR: {
      required,
      minLength: minLength(5),
      numeric,
    },
    pointINT: {
      required,
      minLength: minLength(5),
      numeric,
    },
    pointDEX: {
      required,
      minLength: minLength(5),
      numeric,
    },
  },
  data() {
    return {
      charactersAccoun: {},
      charList: [],
      charSelected: [],
      charSelected2: [],

      selected: 0,
      points: 0,
      pointSTR: 0,
      pointINT: 0,
      pointDEX: 0,
      uniqueChar: [],
      oldValue: {},
      ptSTR: 0,
      ptINT: 0,
      ptDEX: 0,
    }
  },
  methods: {
    checkInputValue(index) {
      if (refactor.checkInputValue(index) === false) {
        this.pointSTR = 0
        this.pointINT = 0
        this.pointDEX = 0
        this.$swal(
          this.$t('swal.typeMessage[0]'),
          this.$t('swal.dist'),
          'warning'
        ).then(() => {
          document.querySelector('button.DomClass').removeAttribute('disabled')
        })
        document
          .querySelector('button.DomClass')
          .setAttribute('disabled', 'disabled')
      }
    },
    ...mapActions(['distptsCharactersActions']),
    selectedChar(event) {
      this.charactersAccoun.filter(char => {
        if (char.CharacterIdx == event.target.value) {
          //ISSO NAO PODE ACONTECER
          this.uniqueChar = this.charactersByIdx(event.target.value).PNT

          this.charSelected = char
          this.charSelected2 = char

          this.ptSTR = char.STR
          this.ptINT = char.INT
          this.ptDEX = char.DEX

          return char.PNT
        } else {
          if (event.target.value < 0) {
            this.$swal('Oops', this.$t('swal.selectChar'), 'error')
          }
        }
      })
    },
    async saveThePoints() {
      /* eslint-disable no-unused-vars */
      document
        .querySelector('button.DomClass')
        .setAttribute('disabled', 'disabled')
      if (this.pointSTR != 0 || this.pointINT != 0 || this.pointDEX != 0) {
        let total = this.pointSTR + this.pointINT + this.pointDEX
        this.uniqueChar = this.charactersByIdx(
          this.charSelected.CharacterIdx
        ).PNT

        if (total > this.uniqueChar) {
          this.$swal(
            this.$t('swal.typeMessage[1]'),
            this.$t('swal.insufficientPoint'),
            'error'
          ).then(value => {
            this.pointSTR = 0
            this.pointINT = 0
            this.pointDEX = 0
            document
              .querySelector('button.DomClass')
              .removeAttribute('disabled')
          })
        } else {
          const dispts = await this.distptsCharactersActions({
            Char: this.charSelected,
            PNT: this.uniqueChar,
            STR: this.pointSTR,
            INT: this.pointINT,
            DEX: this.pointDEX,
            ptSTR: this.ptSTR,
            ptINT: this.ptINT,
            ptDEX: this.ptDEX,
            CharacterIdx: this.charSelected.CharacterIdx,
          })

          if (dispts.code > 0)
            this.$swal(this.$t('swal.typeMessage[2]'), dispts.msg, 'success')
              .then(() => {
                this.pointSTR = 0
                this.pointINT = 0
                this.pointDEX = 0
              })
              .then(() => {
                document
                  .querySelector('button.DomClass')
                  .removeAttribute('disabled')
              })
          else if (dispts.code > -1)
            this.$swal(
              this.$t('swal.typeMessage[1]'),
              dispts.msg,
              'error'
            ).then(() => {
              document
                .querySelector('button.DomClass')
                .removeAttribute('disabled')
            })
          else
            this.$swal(
              this.$t('swal.typeMessage[1]'),
              this.$t('swal.errorDistPoint'),
              'error'
            ).then(() => {
              document
                .querySelector('button.DomClass')
                .removeAttribute('disabled')
            })
        }
      } else {
        this.$swal(
          this.$t('swal.typeMessage[1]'),
          this.$t('swal.zerorPoints'),
          'error'
        ).then(() => {
          document.querySelector('button.DomClass').removeAttribute('disabled')
        })
      }
    },
  },
  computed: {
    ...mapGetters(['characters', 'charactersByIdx']),
    iconClass() {
      if (this.charSelected.Style === 1) return { class: 'GU', name: 'Guerreiro' };
      else if (this.charSelected.Style === 2) return { class: 'DU', name: 'Duelista' };
      else if (this.charSelected.Style === 3) return { class: 'MA', name: 'Mago' };
      else if (this.charSelected.Style === 4) return {
        class: 'AA', name: 'Arqueiro Arcano'
      };
      else if (this.charSelected.Style === 5) return {
        class: 'GA', name: 'Guardião Arcano'
      }; else return {
        class: 'EA', name: 'Espadachim'
      }
    }
  },

  created() {
    this.charactersAccoun = this.characters
  },
}
</script>
<style lang="scss" scoped>
@import '../assets/scss/md/_DistPts.scss';
</style>
