<template>
  <md-toolbar md-elevation="0" class="md-transparent">
    <div class="md-toolbar-row">
      <div class="md-toolbar-section-start">
        <h3 class="md-title">{{ $route.name }}</h3>
      </div>
      <div class="md-toolbar-section-end">
        <md-button class="md-just-icon md-simple md-toolbar-toggle" :class="{ toggled: $sidebar.showSidebar }"
          @click="toggleSidebar">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </md-button>

        <div class="md-collapse">
          <!-- <div class="md-autocomplete">
            <md-autocomplete
              class="search"
              v-model="selectedEmployee"
              :md-options="employees"
            >
              <label>{{ $t('message.TopNavbar.labelSearch') }}</label>
            </md-autocomplete>
          </div> -->
          <md-list>
            <!-- <li class="md-list-item">
              <a
                href="#/notifications"
                class="md-list-item-router md-list-item-container md-button-clean dropdown"
              >
                <div class="md-list-item-content">
                  <drop-down>
                    <md-button
                      slot="title"
                      class="md-button md-just-icon md-simple"
                      data-toggle="dropdown"
                    >
                      <md-icon>notifications</md-icon>
                      <span class="notification">1</span>
                      <p class="hidden-lg hidden-md">{{ $t('message.TopNavbar.txtNotifications') }}</p>
                    </md-button>
                    <ul class="dropdown-menu dropdown-menu-right">
                      <li><a href="#">Notificação do [GM]</a></li>
                    </ul>
                  </drop-down>
                </div>
              </a>
            </li> -->

            <md-list-item href="#/" @click="logout">
              <i class="fas fa-sign-out fa-2x"></i>
              <p class="hidden-lg hidden-md">Sair</p>
            </md-list-item>
          </md-list>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
export default {
  data() {
    return {
      selectedEmployee: null,
      employees: [''],
    }
  },
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
    },
    logout() {
      localStorage.removeItem('user-data')
      localStorage.removeItem('vuex')
      localStorage.removeItem('_secure__ls__metadata')
    },
  },
}
</script>

<style lang="css"></style>
