<template>
    <!--  BSK ANIMATION  -->
    <div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
        <div class="circle-container">
            <div class="circle"></div>
        </div>
    </div>
    <!-- / BSK ANIMATION -->
</template>

<script>
export default {
    name: "EffectRegister"
}
</script>

<style>

</style>
